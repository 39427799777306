import React, { useRef } from "react";
import { Row, Button, Col, Form } from "react-bootstrap";
import TabContainer from "../../components/TabContainer/TabContainer";
import TabMainRow from "../../components/TabMainRow/TabMainRow";
import DataTitle from "../../components/DataTitle/DataTitle";
import TextEditor from "../../components/TextEditor/TextEditor";
import TextInput from "../../components/TextInput/TextInput";
import classes from "./SEOTab.module.css";
import generateUrlValidation from "../../utilities/GenerateUrlValidation";
import TitleContainer from "../../components/TitleContainer/TitleContainer";

const SEOTab = (props) => {
  const {
    processForm,
    metaDescription,
    setMetaDescription,
    metaTitle,
    setMetaTitle,
    error,
    reWrite,
    setSpecialCharactersURL,
    regExpURL,
    setReWrite,
    setHasChanged,
    specialCharactersURL,
    specialCharactersName,
    name,
  } = props;

  const metaTitleRef = useRef(null);
  const metaDescriptionRef = useRef(null);

  return (
    <TabContainer>
      <TabMainRow>
        <Col md={8}>
          <DataTitle>Reescribir URL:</DataTitle>
          <TextInput
            controlId="editUrlRewrite"
            value={reWrite}
            onChange={(event) => {
              setSpecialCharactersURL(regExpURL.test(event.target.value));

              if (event.target.value !== reWrite) {
                setReWrite(
                  event.target.value.toLowerCase().replaceAll(" ", "-")
                );
                setHasChanged(true);
              }
            }}
            error={(reWrite.length <= 0 || !specialCharactersURL) && error}
            errorMessage={
              reWrite.length <= 0
                ? "No se puede dejar este campo vacío"
                : "No está permitido usar caracteres especiales"
            }
          />
        </Col>
        <Col md={2} className={[classes.datasContainer, classes.centeredV]}>
          <Button
            className={classes.centeredButton}
            variant="success"
            onClick={() => {
              // * Set reWrite with the name in the first tab / Generate friendly URL with translation of some special characters
              const generatedUrl = generateUrlValidation({
                specialCharactersName: specialCharactersName,
                name: name,
                reWrite: reWrite,
                setSpecialCharactersURL: setSpecialCharactersURL,
              });

              if (generatedUrl) {
                setReWrite(generatedUrl);
              }

              setHasChanged(true);
            }}
          >
            Regenerar URL
          </Button>
        </Col>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="seo.metatitle">
            <Form.Label>Meta Title</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="textarea"
              rows="10"
              cols="50"
              value={metaTitle}
              ref={metaTitleRef}
              isInvalid={
                (metaTitle.length <= 0 || metaTitle.length > 70) && error
              }
              disabled={processForm}
              onChange={(event) => {
                setMetaTitle(event.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Necesitas introducir un meta título de entre 1 y 70 caracteres, a
              partir de esta cifra no se guaradará el contenido escrito (haz
              click en WORDS para saber el número de caracteres actuales)
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="seo.metatitle">
            <Form.Label>Meta Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="textarea"
              rows="10"
              cols="50"
              value={metaDescription}
              ref={metaDescriptionRef}
              isInvalid={
                (metaDescription.length <= 0 || metaDescription.length > 300) &&
                error
              }
              disabled={processForm}
              onChange={(event) => {
                setMetaDescription(event.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Necesitas introducir una meta descripción de entre 1 y 300
              caracteres, a partir de esta cifra no se guaradará el contenido
              escrito (haz click en WORDS para saber el número de caracteres
              actuales)
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={12}>
          {/* PREVIEW */}
          <TitleContainer>
            <h3>Previsualización Metas</h3>
          </TitleContainer>
          <div id={classes.searchresultsarea}>
            <div class={classes.searchresult}>
              <a>
                https://www.updirecto.es/
                {!specialCharactersURL
                  ? null
                  : reWrite.toLowerCase().replaceAll(" ", "-")}
              </a>
              <button>▼</button>
              <h2>{metaTitle.substring(0, 71)}</h2>
              <p>{metaDescription.substring(0, 300)}</p>
            </div>
          </div>
          {/* END OF PREVIEW */}
        </Col>
      </TabMainRow>
    </TabContainer>
  );
};

export default SEOTab;
