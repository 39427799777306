import { Editor } from "@tinymce/tinymce-react";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import classes from "./TextEditor.module.css";

const TextEditor = (props) => (
  <div className={props.error ? classes.inputError : undefined}>
    <Editor
      value={props.value}
      init={{
        entity_encoding: "raw",
        selector: "textarea",
        forced_root_block: "",
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          // "image imagetools", COMENTADO PORQUE EL IMAGETOOLS TE DEJA CROPEAR GIRAR Y ESO CREA UNA NUEVA IMAGEN CON BLOB QUE ES UNPROBLEMA YA QUE NO SE ALMACENA EN NINGUN SITIO
          "image",
          "code",
        ],
        toolbar:
          "undo redo | formatselect fontselect fontsizeselect forecolor | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          table bullist numlist outdent indent | removeformat | help | \
                          image media | code",
        image_dimensions: false,
        font_formats:
          "System Font; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
      }}
      initialValue={props.initialValue}
      onEditorChange={props.onEditorChange}
      onBlur={props.onBlur}
      onKeyUp={props.onKeyUp}
      disabled={props.disabled}
      onInit={props.onInit}
      onChange={props.onChange}
    />
    {props.error ? (
      <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
    ) : undefined}
  </div>
);

export default TextEditor;
