import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import apiArticles from "../../../../../../api/articles";

import { PROCESSING, LOADING } from "../../../../../../utilities/processStates";

import Article from "../types/Article";

const useAddArticles = (
  handleAfterClickSave: (items: Article[]) => any,
  handleClose: () => void
) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  // Form states
  const [searchQuery, setSearchQuery] = useState("");
  const [filterParam, setFilterParam] = useState("name");

  // Process states
  const [addArticlesProcessState, setAddArticlesProcessState] =
    useState<string>();

  // Pagination states
  const [totalPagesNumber, setTotalPagesNumber] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Check if initial render
  const isFirstRender = useRef(true);

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    (async () => {
      setAddArticlesProcessState(LOADING);

      try {
        const response = await apiArticles.getOrphans(accesToken, {
          page: 1,
          filtername: "all",
          value: undefined,
          active: undefined,
        });

        setTotalPagesNumber(Math.ceil(response.data.count / 20));
        setArticles(response.data.results);
      } catch (error) {
        console.log(error);
      }

      isFirstRender.current = false;
      setAddArticlesProcessState(undefined);
    })();
  }, []);

  // Search when finish typing
  useEffect(() => {
    if (isFirstRender.current) return;

    const timerToSearch = setTimeout(async () => {
      setAddArticlesProcessState(PROCESSING);

      // If no search value search all

      let filterName = filterParam;
      let value: string | undefined = searchQuery;
      if (searchQuery.length === 0) {
        filterName = "all";
        value = undefined;
      } 

      try {
        const response = await apiArticles.getOrphans(accesToken, {
          page: 1,
          filtername: filterName,
          value,
          active: undefined,
        });

        setCurrentPage(0);
        setTotalPagesNumber(Math.ceil(response.data.count / 20));
        setArticles(response.data.results);
      } catch (error) {
        console.log(error);
      }

      setAddArticlesProcessState(undefined);
    }, 750);

    return () => clearTimeout(timerToSearch);
  }, [searchQuery, filterParam]);

  const handleClickArticle = (clickedArticle: Article) => {
    setSelectedArticles((prevSelectedArticles) => {
      const selectedArticlesIds = selectedArticles.map((art) => art.id);

      // Unselect article if already selected
      if (selectedArticlesIds.includes(clickedArticle.id)) {
        return prevSelectedArticles.filter(
          (art) => art.id !== clickedArticle.id
        );
      }

      // Select article if not yet selected
      return [...prevSelectedArticles, clickedArticle];
    });
  };

  const handleClickSaveFinal = async () => {
    setAddArticlesProcessState(PROCESSING);

    try {
      await handleAfterClickSave(selectedArticles);
    } catch (error) {}

    setAddArticlesProcessState(undefined);
    handleClose();
  };

  // Handle use form
  const handleTypeSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleChangeFilterParam = (param: string) => {
    setFilterParam(param);
  };

  // Pagination events
  const handleClickPage = async (clickedPage: number) => {
    setCurrentPage(clickedPage);

    let value: string | undefined = searchQuery;
    if (filterParam === "all") {
      value = undefined;
    }

    try {
      const response = await apiArticles.getOrphans(accesToken, {
        page: clickedPage + 1,
        filtername: filterParam,
        value,
        active: undefined,
      });

      setTotalPagesNumber(Math.ceil(response.data.count / 20));
      setArticles(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  // Return articles with the selected field
  const selectedArticlesIds = selectedArticles.map((art) => art.id);
  const articlesWithAllInfo = articles.map((art) => {
    if (selectedArticlesIds.includes(art.id)) {
      return {
        ...art,
        selected: true,
      };
    }

    return {
      ...art,
      selected: false,
    };
  });

  const isLoadingArticles = addArticlesProcessState === LOADING;
  const isProcessingAddArticles = addArticlesProcessState === PROCESSING;
  const isArticlesSelected = selectedArticles.length > 0;
  const noArticles = !(articles.length > 0);

  return {
    articles: articlesWithAllInfo,
    selectedArticles,
    handleClickArticle,
    isLoadingArticles,
    isProcessingAddArticles,
    handleClickSaveFinal,
    isArticlesSelected,
    searchQuery,
    handleTypeSearch,
    filterParam,
    handleChangeFilterParam,
    totalPagesNumber,
    currentPage,
    handleClickPage,
    noArticles,
  };
};

export default useAddArticles;
