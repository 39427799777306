import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import apiCategories from "../../../api/categories";
import apiAttributes from "../../../api/attributes";
import apiProducts from "../../../api/products";
import apiFeatures from "../../../api/features";
import swal from "@sweetalert/with-react";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import {
  Spinner,
  Container,
  Col,
  Alert,
  Tabs,
  Badge,
  Tab,
  Modal,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { Prompt } from "react-router-dom";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import SaveButton from "../../../components/SaveButton/SaveButton";
import MainTitle from "../../../components/MainTitle/MainTitle";
import GeneralSpinner from "../../../components/Spinner/Spinner";
import DraggableSortableFilter from "../../../components/DraggableSortableFilter/DraggableSortableFilter";
import DataItem from "../../../components/DataItem/DataItem";
import TextInput from "../../../components/TextInput/TextInput";
import AdministerCopies from "../../AdministerCopies/AdministerCopies";
import TitleContainer from "../../../components/TitleContainer/TitleContainer";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import ProblemsTable from "../../../components/ProblemsTable/ProblemsTable";
import AvailableFilters from "../../../components/AvailableFilters/AvailableFilters";
import classes from "./Category.module.css";
import ProductsTable from "../../../components/ProductsTable/ProductsTable";
import displayNotification from "../../../utilities/displayNotification";
import handleDragAndDrop from "../../../utilities/handleDragAndDrop";
import includeExcludeFromFilter from "../../../utilities/includeExcludeFromFilter";
import checkSync from "../../../utilities/checkSync";
import SEOTab from "../../SEOTab/SEOTab";
import Product from "../Product/Product/Product";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextEditor from "../../../components/TextEditor/TextEditor";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import solveErrorMessage from "../../../utilities/notifications/solveErrorMessage";

import CategorySubcategories from "./CategorySubcategories/CategorySubcategories";
import PhotosTab from "./Photos/Photos";

import useProducts from "./hooks/use-products";
import useCategories from "./hooks/use-categories";
import useFilters from "./hooks/use-filters";

const states = {
  LOADING: "LOADING",
  SUCCEDDED: "SUCCEDDED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
};

const EditCategory = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  let categoryId;
  if (props.match) {
    categoryId = props.match.params.id;
  } else {
    categoryId = props.id;
  }

  // * Regular expressions
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);

  // * SEO
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [reWrite, setReWrite] = useState(states.LOADING);
  // !!!!  estados para mandar a la url de prestashop

  const [prestashopURL, setPrestashopURL] = useState(states.LOADING);
  // !!!!
  const [id, setId] = useState();
  const [idERP, setIdERP] = useState();
  const [idPrestashop, setIdPrestashop] = useState();
  const [fatherCategory, setFatherCategory] = useState({});
  const [name, setName] = useState(states.LOADING);
  const [originalName, setOriginalName] = useState("");
  const [active, setActive] = useState();
  const [features, setFeatures] = useState(states.LOADING);

  const [key, setKey] = useState(0);
  const [description, setDescription] = useState("");

  const { products, setProducts, handleOnDragEndProduct } = useProducts(id);
  const {
    categories,
    setCategories,
    processDbCategories,
    handleDropCategory,
    handleUpdateEditedCategory,
    handleClickSyncCategory,
    // TODO
    handleCategoriesPrestashopSync,
    syncProccess,
  } = useCategories(id);
  const [productsFirst, setProductsFirst] = useState(false);

  const productsRef = useRef();
  productsRef.current = products;

  const {
    filterFeatures,
    setFilterFeatures,
    availableFilterFeatures,
    setAvailableFilterFeatures,
    filterAttributes,
    setFilterAttributes,
    availableFilterAttributes,
    setAvailableFilterAttributes,
    loadFiltersFromDBCategory,
    loadFilters,
    saveFilters,
  } = useFilters(id);

  const [problems, setProblems] = useState(states.LOADING);

  const [processProducts, setProcessProducts] = useState(false);
  const [formProcessPhase, setFormProcessPhase] = useState();
  const [showFormError, setShowErrorForm] = useState(true);
  const [processingErrors, setProcessingErros] = useState(false);
  const [syncingUnlinkedLinkedProducts, setSyncingUnlinkedProducts] =
    useState(false);
  const [syncingAllProducts, setSyncingAllProducts] = useState(false);

  const [correctSync, setCorrectSync] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [mustSync, setMustSync] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [duplicateName, setDuplicateName] = useState();

  const [showChooseFatherCategory, setShowChooseFatherCategory] = useState();
  const [showLinkProducts, setShowLinkProducts] = useState();
  const [showSolveProblem, setShowSolveProblem] = useState();
  const [showAddFilterFeatures, setShowAddFilterFeatures] = useState();
  const [showAddFilterAttribute, setShowAddFilterAttribute] = useState();
  const [showProduct, setShowProduct] = useState();

  const [hasSaved, setHasSaved] = useState();

  const [exitPath, setExitPath] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitPath]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => {
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await saveCategory();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await saveCategory();
                await syncCategory();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });

        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  const getProblems = async () => {
    try {
      const { data } = await apiCategories.getProblems(accesToken, {
        categoryId,
      });

      const processedProblems = data.feature_values.map((problem) => ({
        feature: {
          id: problem.feature_id,
          name: problem.feature_name,
        },
        product: {
          id: problem.product_id,
          name: problem.product_name,
        },
      }));

      setProblems(processedProblems);
    } catch (e) {}
  };

  useEffect(() => {
    apiCategories
      .filter(accesToken, {
        filtername: "id",
        value: categoryId,
      })
      .then((response) => {
        const category = response.data[0];

        const formattedFeatures = category.feature_data.map((feature) => ({
          id: feature.features__id,
          name: feature.features__name,
        }));
        setFeatures(formattedFeatures);

        loadFiltersFromDBCategory(category);
        // !!!!!!! dario montar estados para mandar a la url de prestashop
        setPrestashopURL(category.category_url);
        // !!!
        setId(category.id);
        setIdERP(category.id_erp);
        setIdPrestashop(category.prestashop_id);
        setFatherCategory({
          id: category.father__id,
          name: category.father__name,
        });
        setName(category.name);
        setReWrite(category.url_rewrite ?? "");

        setOriginalName(category.name);
        setActive(category.active);
        setMetaTitle(category.meta_title ?? "");
        setMetaDescription(category.meta_description ?? "");
        setDescription(category.description ?? "");

        const processedProducts = category.products.map((product) => ({
          id: product.id,
          reference: product.ref,
          name: product.name,
          active: product.active,
          url: `https://www.updirecto.es/${product.url_rewrite}`,
        }));
        setProducts(processedProducts);

        setFormProcessPhase(states.SUCCEDDED);

        processDbCategories(category.subcategories);

        if (category.products.length > category.subcategories.length) {
          setProductsFirst(true);
        } else {
          setProductsFirst(false);
        }

        (async () => {
          let updatedProducts = [];

          for (const product of processedProducts) {
            try {
              const isSynced = await checkSync.checkProductSync(accesToken, {
                productId: product.id,
              });

              updatedProducts.push({
                ...product,
                sync: isSynced,
              });
            } catch (e) {
              updatedProducts.push({
                ...product,
                sync: false,
              });
            }
          }

          setProducts(updatedProducts);
        })();
      })
      .catch((e) => {
        console.log(e);
      });

    getProblems();

    (async () => {
      try {
        const firstCheckSync = await apiCategories.checkSync(accesToken, {
          categoryId,
        });

        if (firstCheckSync.data.category[0].synchronizing) {
          setIsSyncing(true);
        }

        const checkResponse = await checkSync.checkCategorySync(accesToken, {
          categoryId,
        });

        if (checkResponse) {
          setMustSync(false);
          setCorrectSync(true);
        } else {
          setMustSync(true);
        }

        setSyncError(false);
        setIsSyncing(false);
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setName, setReWrite, setActive, accesToken, categoryId]);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (
        hasChanged &&
        id &&
        name.length > 0 &&
        description.length > 0 &&
        description.length < 50000 &&
        fatherCategory.id &&
        metaTitle.length > 0 &&
        metaDescription.length > 0 &&
        reWrite.length > 0 &&
        metaTitle.length < 70 &&
        metaDescription.length < 300 &&
        specialCharactersName &&
        specialCharactersURL
      ) {
        await saveCategory();
      }
    }, 10000);

    return () => clearTimeout(saveTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasChanged,
    id,
    name,
    description,
    active,
    reWrite,
    fatherCategory.id,
    metaTitle,
    metaDescription,
    filterFeatures,
    filterAttributes,
    specialCharactersName,
    specialCharactersURL,
  ]);

  if (idPrestashop === 1) {
    return (
      <Alert variant="danger" className="mt-2">
        Prestashop no permite editar esta categoría
      </Alert>
    );
  }

  // SYNC PRODUCTS

  const setProductSync = async ({ id, sync }) => {
    setProducts((oldProducts) => {
      const updatedProducts = oldProducts.map((product) => {
        if (product.id === id) {
          return {
            ...product,
            sync,
          };
        }

        return product;
      });

      return updatedProducts;
    });
  };

  const syncItem = async (id) => {
    setProductSync({ id, sync: undefined });

    try {
      await apiProducts.sync(accesToken, {
        productId: id,
      });

      await checkSync.checkProductSync(accesToken, {
        productId: id,
      });

      setProductSync({ id, sync: true });
    } catch (error) {
      setProductSync({ id, sync: false });
    }
  };

  const syncAllProducts = async () => {
    try {
      setProcessProducts(true);

      await Promise.allSettled(products.map((product) => syncItem(product.id)));

      setProcessProducts(false);
    } catch (e) {}
  };

  const clickUnklink = async () => {
    setProcessProducts(true);

    const productsToUnlink = products.filter((product) => product.remove);

    const productIdsToUnlink = productsToUnlink.map((item) => item.id);

    try {
      await apiProducts.removeCategories(accesToken, {
        productIds: productIdsToUnlink,
        categoryIds: [id],
      });

      const updatedProducts = products.filter((product) => !product.remove);

      // syncLinkedProducts(productsToUnlink, setSyncingUnlinkedProducts);
      setProducts(updatedProducts);
      setProcessProducts(false);

      (async () => {
        for (const productId of productIdsToUnlink) {
          try {
            await apiProducts.sync(accesToken, {
              productId,
            });

            await checkSync.checkProductSync(accesToken, {
              productId,
            });
          } catch (e) {
            displayNotification({
              title: `Ha habido un error en la sync del producto con id ${productId}`,
              message:
                "Revise el error y contacte con asistencia si este persiste",
              type: "danger",
            });
          }
        }
      })();
    } catch (e) {
      if (e.response && e.response.status === 409) {
        swal({
          title: "No se ha podido desvincular algunos productos",
          text: `Solo se pueden desvincular productos que no tengan la categoría ${name} como principal`,
          icon: "error",
          button: "Entendido",
        });
      }

      setProcessProducts(false);
    }
  };

  const clickUnlinkSingle = async (productId) => {
    setProcessProducts(true);

    try {
      await apiProducts.removeCategories(accesToken, {
        productIds: [productId],
        categoryIds: [id],
      });

      const updatedProducts = products.filter((item) => item.id !== productId);
      setProducts(updatedProducts);

      setProcessProducts(false);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        swal({
          title: "No se ha podido desvincular el producto",
          text: `Solo se pueden desvincular productos que no tengan la categoria ${name} como principal`,
          icon: "error",
          button: "Entendido",
        });
      }

      setProcessProducts(false);
    }
  };

  // const syncLinkedProducts = async (productsToSync, setSyncState) => {
  //   setSyncState(true);
  //   for (const key in productsToSync) {
  //     try {
  //       await apiProducts.sync(accesToken, {
  //         productId: productsToSync[key].id,
  //       });

  //       await checkSync.checkProductSync(accesToken, {
  //         productId: productsToSync[key].id,
  //       });
  //     } catch (e) {
  //       displayNotification({
  //         title: "Algun producto vinculado no se ha sincronizado correctamente",
  //         message: `Vaya a la categoría con ID:${id} y repita la sincronización de productos`,
  //         type: "danger",
  //       });
  //       setSyncState(false);
  //     }
  //   }
  //   setSyncState(false);
  // };

  const notificationErrorMessage = ({ title, key }) => {
    solveErrorMessage({
      title,
      onClickSolve: () => {
        setKey(key);
        if (window.screen.width < 500) {
          document.getElementById(`accordion-card-${key}`).scrollIntoView();
        }
      },
    });
  };

  const saveCategory = async () => {
    try {
      let hasErrors = false;

      if (name.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "No se puede guardar una categoría sin nombre",
          key: 0,
        });
      }
      if (!fatherCategory.id) {
        hasErrors = true;

        notificationErrorMessage({
          title: "No se puede guardar una categoría sin su categoría padre",
          key: 0,
        });
      }
      if (!specialCharactersName && name.length > 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "El nombre no puede poseer caracteres especiales",
          key: 0,
        });
      }

      if (duplicateName) {
        hasErrors = true;

        notificationErrorMessage({
          title: "El nombre esta duplicado, introduzca uno que no exista ya",
          key: 0,
        });
      }
      if (description.length <= 0 || description.length > 50000) {
        hasErrors = true;

        notificationErrorMessage({
          title:
            "La descripción es necesaria y debe tener menos de 50000 caracteres",
          key: 0,
        });
      }

      if (metaTitle.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "El metatitle es necesario",
          key: 4,
        });
      }

      if (metaDescription.length <= 0 || metaDescription.length > 300) {
        hasErrors = true;

        notificationErrorMessage({
          title:
            "La metadescription es necesaria y no puede tener más de 300 caracteres",
          key: 4,
        });
      }
      if (reWrite.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "No se puede guardar una categoría sin URL",
          key: 4,
        });
      }
      if (!specialCharactersURL && reWrite.length > 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "La URL no puede poseer caracteres especiales",
          key: 4,
        });
      }

      if (hasErrors) {
        throw new Error();
      }

      setIsSaving(true);

      const params = {
        id,
        active,
        url_rewrite: reWrite,
        description,
        meta_title: metaTitle.substring(0, 71),
        meta_description: metaDescription.substring(0, 300),
      };

      if (originalName !== name) {
        params.name = name;
      }

      if (fatherCategory.id) {
        params.father = fatherCategory.id;
      }

      await apiCategories.edit(accesToken, params);

      await saveFilters();

      await getProblems();

      setOriginalName(name);
      setHasSaved(true);
      setHasChanged(false);
      setMustSync(true);
      setIsSaving(false);
      // displayNotification({
      //   title: "La categoría se ha guardado correctamene",
      //   message: `La categoría con ID ${id} se ha editado de forma correcta`,
      //   type: "success",
      // });

      return true;
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setIsSaving(false);
        setShowErrorForm(true);
        setDuplicateName(true);
        setIsSaving(false);

        // TODO I put this logic here to show the error when first trying to save instead at the second time
        notificationErrorMessage({
          title: "El nombre está duplicado, introduzca uno que no exista ya",
          key: 0,
        });
      } else {
        setShowErrorForm(true);
      }
      setFormProcessPhase(states.ERROR);
      return false;
    }
  };

  const syncCategory = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await saveCategory();

      if (!hasSavedSuccesFully) {
        setIsSyncing(false);
        return;
      }

      await apiCategories.sync(accesToken, { categoryId: id });

      await checkSync.checkCategorySync(accesToken, {
        categoryId: id,
        setIsSyncing,
      });

      setIsSyncing(false);
      setMustSync(false);
      setSyncError(false);
      setCorrectSync(true);
    } catch (e) {
      
      displayNotification({
        title: "Ha habido un error en la sync",
        message:
          "Ha ocurrido un error en la sincronización, prueba otra vez dentro de unos minutos, si el problema persiste ponte en contacto con el servicio técnico",
        type: "danger",
      });

      setSyncError(true);
      setIsSyncing(false);
      setCorrectSync(false);
    }
  };

  // useEffect(() => {
  //   if (!id) return;

  //   const Anchors = document.getElementsByTagName("a");

  //   for (let i = 0; i < Anchors.length; i++) {
  //     console.log("ADD LISTENER", handleClickLinks);
  //     Anchors[i].addEventListener("click", handleClickLinks, true);
  //   }

  //   window.addEventListener("beforeunload", handleUnload);
  // }, [id]);

  const handleCheckErrors = async () => {
    setProcessingErros(true);

    try {
      await apiCategories.checkErrors(accesToken, { categoryId: id });

      const { data } = await apiCategories.getProblems(accesToken, {
        categoryId: id,
      });

      const processedProblems = data.feature_values.map((problem) => ({
        feature: {
          id: problem.feature_id,
          name: problem.feature_name,
        },
        product: {
          id: problem.product_id,
          name: problem.product_name,
        },
      }));

      setProblems(processedProblems);
      setProcessingErros(false);
    } catch (e) {}
  };

  const selectUnselectToRemove = (id) => {
    const updatedProducts = products.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          remove: !product.remove,
        };
      }

      return {
        ...product,
      };
    });

    setProducts(updatedProducts);
  };

  const clickSelectAll = () => {
    const allAreSelected = !products.find((product) => !product.remove);

    if (allAreSelected) {
      const updatedProducts = products.map((product) => {
        return {
          ...product,
          remove: false,
        };
      });

      setProducts(updatedProducts);
    } else {
      const updatedProducts = products.map((product) => {
        return {
          ...product,
          remove: true,
        };
      });

      setProducts(updatedProducts);
    }
  };

  let content;

  const disabled = formProcessPhase === states.PROCESSING;

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateName) {
    nameErrorMessage = "No puede haber 2 categorías con el mismo nombre";
  }

  // Action on change tab
  const changeTab = (k) => {
    setKey(k);
  };

  // TABS HEADERS AND CONTENT

  const mainInfoTitle = (
    <>
      {formProcessPhase === states.ERROR &&
      (name.length <= 0 ||
        !fatherCategory.id ||
        description.length <= 0 ||
        description.length > 50000 ||
        !specialCharactersName) ? (
        <TabErrorIcon />
      ) : undefined}
      Categoría
    </>
  );

  const mainInfoContent = (
    <TabContainer>
      <TabMainRow>
        <DataItem md={4}>
          <b>ID:</b> {id}
        </DataItem>
        <DataItem md={4}>
          <b>ID PS:</b> {idPrestashop}
        </DataItem>
        <DataItem md={4}>
          <CheckSphere
            check={active}
            x={!active}
            onClick={() => {
              setHasChanged(true);
              setActive(!active);
            }}
            disabled={disabled}
          >
            Activo
          </CheckSphere>
        </DataItem>
        <Col md={12}>
          <DataTitle>Nombre:</DataTitle>
          <TextInput
            controlId="editCategory"
            value={name}
            onChange={(event) => {
              if (event.target.value !== name) {
                setHasChanged(true);
                setDuplicateName(false);
                setSpecialCharactersName(regExpURL2.test(event.target.value)); //devuelve false si coincide con alguno de los caracteres de la regex
                setName(event.target.value);
              }
            }}
            error={name.length <= 0 || duplicateName || !specialCharactersName}
            errorMessage={nameErrorMessage}
          />
        </Col>
        <Container fluid>
          <DataTitle className={classes.title}>Descripción:</DataTitle>
          <TextEditor
            // initialValue={description}
            // onKeyUp={(value) => {
            //   // if (value.target.textContent !== description) {
            //     if (value.target.innerHTML !== description) {
            //     console.log(
            //       "🚀 ~ file: Category.js ~ line 828 ~ EditCategory ~ value",
            //       value.target
            //     );

            //     setDescription(value.target.innerHTML);
            //     console.log("🚀 --------~ file: Category.js ~ line 907 ~ EditCategory ~ setDescription", description)

            //     setHasChanged(true);
            //   }
            // }}
            // onKeyUp={() => setHasChanged(true)}
            value={description}
            onEditorChange={(content) => {
              console.log(
                "🚀 ~ file: Category.js ~ line 928 ~ EditCategory ~ content",
                content
              );

              setDescription(content);
              setHasChanged(true);
            }}
            disabled={disabled}
            // disabled={saveProductProcessPhase === PROCESSING}
            error={description.length <= 0 || description.length > 50000}
            errorMessage="Necesita introducir una descripción para guardar el producto, de menos de 50.000 caracteres"
          />
        </Container>
        <Col md={12}>
          <DataTitle>Categoría padre:</DataTitle>
          <OptionsButton
            variant={!fatherCategory.id ? "danger" : "primary"}
            pencil={() => {
              setShowChooseFatherCategory(true);
            }}
            click={() => {
              setShowChooseFatherCategory(true);
            }}
            disabled={disabled}
          >
            {fatherCategory.name}
          </OptionsButton>
          {/* Father category button error message*/}
          {!fatherCategory.id ? (
            <span className={classes.errorMessage}>
              "Necesitas elegir una categoría padre"
            </span>
          ) : null}{" "}
        </Col>

        {/* <DataItem md={12}> */}
        {/* <h5>
            <b>Caracteristicas:</b> Muchas, un piñón
          </h5> */}
        {/* </DataItem> */}
      </TabMainRow>
    </TabContainer>
  );

  const totalNumberBadge = (items) => {
    return (
      <Badge
        className="ml-2"
        pill
        variant={items.length === 0 ? "danger" : "primary"}
      >
        {items.length === 0 ? 0 : items.length}
      </Badge>
    );
  };

  const productsNumberBadge = (
    <div>
      Productos
      {totalNumberBadge(products)}
    </div>
  );

  const categoriesNumberBadge = (
    <div>
      Categorías
      {totalNumberBadge(categories)}
    </div>
  );

  const productsTabTitle = (
    <>
      {productsFirst ? (
        <>
          {productsNumberBadge}
          <div className="mt-1"></div>
          {categoriesNumberBadge}
        </>
      ) : (
        <>
          {categoriesNumberBadge}
          <div className="mt-1"></div>
          {productsNumberBadge}
        </>
      )}
    </>
  );

  let allAreSelected;
  if (Array.isArray(products)) {
    allAreSelected = !products.find((product) => !product.remove);
  }

  let productsTabContent = (
    <TabContainer>
      <TabMainRow>
        <Col md={12} className={classes.marginBottom}>
          <Spinner
            className="mt-3"
            as="svg"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  if (products !== states.LOADING) {
    const productsContent = (
      <>
        <TitleContainer>
          <h3>Productos:</h3>
          <div>
            <OptionsButton
              variant="primary"
              arrowRepeat={() => {}}
              click={syncAllProducts}
              disabled={processProducts}
              loading={processProducts}
            >
              {products.find((product) => product.sync === undefined)
                ? "Sincronizando productos vinculados"
                : "Sincronizar productos vinculados"}
            </OptionsButton>
            <OptionsButton
              variant="success"
              plus={() => {}}
              click={() => setShowLinkProducts(true)}
              disabled={processProducts}
            >
              Vincular productos
            </OptionsButton>
            {products.find((product) => product.remove) ? (
              <OptionsButton
                variant="danger"
                x={() => {}}
                click={clickUnklink}
                disabled={processProducts || disabled}
                loading={processProducts}
              >
                Desvincular
              </OptionsButton>
            ) : undefined}
            {Array.isArray(products) ? (
              <OptionsButton
                variant="primary"
                full={!allAreSelected ?? clickSelectAll}
                fullExit={allAreSelected ?? clickSelectAll}
                click={clickSelectAll}
                disabled={processProducts}
              >
                {allAreSelected ? "Deseleccionar todo" : "Seleccionar todo"}
              </OptionsButton>
            ) : undefined}
          </div>
        </TitleContainer>
        <ProductsTable
          products={products}
          disabled={processProducts || disabled}
          procesing={processProducts}
          selectUnselectToRemove={selectUnselectToRemove}
          x={clickUnlinkSingle}
          onEdit={(id) => {
            saveFilters();
            setShowProduct(id);
          }}
          onClickSync={syncItem}
          handleDragEnd={handleOnDragEndProduct}
        />
      </>
    );

    const categoriesContent = (
      <CategorySubcategories
        categories={categories}
        handleDropCategory={handleDropCategory}
        handleUpdateEditedCategory={handleUpdateEditedCategory}
        handleClickSyncCategory={handleClickSyncCategory}
        // TODO
        handleCategoriesPrestashopSync={handleCategoriesPrestashopSync}
        syncProccess={syncProccess}
      />
    );

    productsTabContent = (
      <TabContainer>
        <TabMainRow>
          <Col md={12} className={classes.marginBottom}>
            {productsFirst ? (
              <>
                {productsContent}
                <div className="pt-2"></div>
                {categoriesContent}
              </>
            ) : (
              <>
                {categoriesContent}
                <div className="pt-2"></div>
                {productsContent}
              </>
            )}
          </Col>
        </TabMainRow>
      </TabContainer>
    );
  }

  const problemsTabTitle = (
    <>
      Problemas
      <Badge className="ml-2" pill variant="danger">
        {problems.length === 0 ? null : problems.length}
      </Badge>
    </>
  );

  const problemsTabContent = (
    <TabContainer>
      <TabMainRow>
        <Col md={12} className={classes.marginBottom}>
          <TitleContainer>
            <h3>Productos sin valores de característica</h3>
            <OptionsButton
              variant="primary"
              arrowRepeat={handleCheckErrors}
              click={handleCheckErrors}
              disabled={processingErrors || disabled}
              loading={processingErrors}
            >
              Comprobar errores
            </OptionsButton>
          </TitleContainer>
          {problems === states.LOADING ? (
            <Spinner
              className="mt-3"
              as="svg"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <ProblemsTable
              items={problems}
              onClickAddValueAndSolve={(problem) => {
                setShowSolveProblem({
                  productId: problem.product.id,
                  featureId: problem.feature.id,
                });
              }}
            />
          )}
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const filtersTabTitle = <>Filtros</>;
  const filtersTabContent = (
    <TabContainer>
      <TabMainRow>
        {features === states.LOADING ||
        availableFilterFeatures === states.LOADING ? (
          <Spinner
            className="mt-3"
            as="svg"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            <Col md={12} className={"p-0 m-0"}>
              <TitleContainer>
                <div className="d-flex">
                  <h3 className="m-0">Filtros</h3>

                  <Button
                    className="ml-3"
                    onClick={() => {
                      // window.open(
                      //   `https://www.updirecto.es/${reWrite}`,
                      //   "_blank"
                      // );

                      // !!!! dario boton para llevar a prestashop
                      window.open(prestashopURL);
                    }}
                  >
                    Ver en prestashop
                  </Button>
                </div>
                <OptionsButton
                  variant="success"
                  plus={() => {}}
                  click={() => setShowAddFilterFeatures(true)}
                  disabled={formProcessPhase === states.PROCESSING}
                >
                  Añadir característica
                </OptionsButton>
              </TitleContainer>
            </Col>
            <Col md={6} className={"pt-0 mt-0"}>
              <h5 className="mb-3">Características de productos</h5>
              <div
                className={
                  formProcessPhase === states.PROCESSING ? classes.disabled : ""
                }
              >
                <AvailableFilters
                  items={availableFilterFeatures}
                  onClickItem={(id) => {
                    setHasChanged(true);
                    includeExcludeFromFilter(id, {
                      availableFilters: availableFilterFeatures,
                      setAvailableFilters: setAvailableFilterFeatures,
                      filters: filterFeatures,
                      setFilters: setFilterFeatures,
                    });
                  }}
                  disabled={formProcessPhase === states.PROCESSING}
                />
              </div>
            </Col>
            <Col md={6} className={"pt-0 mt-0 pl-3"}>
              <h5 className="mb-3"> Características en filtro</h5>
              {filterFeatures.length > 0 ? (
                <DraggableSortableFilter
                  items={filterFeatures}
                  droppableId="featuresFilter"
                  disabled={formProcessPhase === states.PROCESSING}
                  handleDranEnd={(result) => {
                    setHasChanged(true);
                    handleDragAndDrop(
                      filterFeatures,
                      setFilterFeatures
                    )(result);
                  }}
                  onClickRemove={(id) => {
                    setHasChanged(true);
                    includeExcludeFromFilter(id, {
                      availableFilters: availableFilterFeatures,
                      setAvailableFilters: setAvailableFilterFeatures,
                      filters: filterFeatures,
                      setFilters: setFilterFeatures,
                    });
                  }}
                />
              ) : (
                <Alert variant="info" className={classes.noItemsAlert}>
                  No hay filtros asociados a esta categoría
                </Alert>
              )}
            </Col>
            <Col md={6} className={"pt-0 mt-0"}>
              <h5 className="mb-3">Atributos</h5>
              <AvailableFilters
                items={availableFilterAttributes}
                onClickItem={(id) => {
                  setHasChanged(true);
                  includeExcludeFromFilter(id, {
                    availableFilters: availableFilterAttributes,
                    setAvailableFilters: setAvailableFilterAttributes,
                    filters: filterAttributes,
                    setFilters: setFilterAttributes,
                  });
                }}
                disabled={formProcessPhase === states.PROCESSING}
              />
            </Col>
            <Col md={6} className={"pt-0 mt-0 mb-5 pl-3"}>
              <h5 className="mb-3"> Atributos en filtro</h5>
              {filterAttributes.length > 0 ? (
                <DraggableSortableFilter
                  items={filterAttributes}
                  droppableId="attributesFiltees"
                  disabled={formProcessPhase === states.PROCESSING}
                  handleDranEnd={(result) => {
                    setHasChanged(true);
                    handleDragAndDrop(
                      filterAttributes,
                      setFilterAttributes
                    )(result);
                  }}
                  onClickRemove={(id) => {
                    setHasChanged(true);
                    includeExcludeFromFilter(id, {
                      availableFilters: availableFilterAttributes,
                      setAvailableFilters: setAvailableFilterAttributes,
                      filters: filterAttributes,
                      setFilters: setFilterAttributes,
                    });
                  }}
                />
              ) : (
                <Alert variant="info" className={classes.noItemsAlert}>
                  No hay filtros asociados a esta categoría
                </Alert>
              )}
            </Col>
          </>
        )}
      </TabMainRow>
    </TabContainer>
  );

  const seoTabTitle = (
    <>
      {formProcessPhase === states.ERROR &&
      (reWrite.length <= 0 ||
        !specialCharactersURL ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length > 300) ? (
        <TabErrorIcon />
      ) : undefined}
      SEO
    </>
  );

  const seoTabContent = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={setHasChanged}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
        setHasChanged(true);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
        setHasChanged(true);
      }}
      error={formProcessPhase === states.ERROR}
    />
  );

  if (
    !id ||
    name === states.LOADING ||
    description === states.LOADING ||
    reWrite === states.LOADING
  ) {
    content = <GeneralSpinner />;
  } else {
    content = (
      <>
        <Prompt
          when={hasChanged && !exitPath}
          message={({ pathname }) => {
            saveBeforeExitAlert(true).then(async (value) => {
              switch (value) {
                case "save":
                  try {
                    await saveCategory();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "saveSync":
                  try {
                    await saveCategory();
                    await syncCategory();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "exit":
                  setExitPath(pathname);
                  swal.close();
                  break;

                default:
                  break;
              }
            });

            return false;
          }}
        />
        <Accordion
          defaultActiveKey="0"
          activeKey={String(key)}
          onSelect={(k) => setKey(k)}
          className={classes.hideAcordionOnPC}
        >
          <Card id="accordion-card-0">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {mainInfoTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{mainInfoContent}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-1">
            <Accordion.Toggle as={Card.Header} eventKey="1">
              {productsTabTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{productsTabContent}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-2">
            <Accordion.Toggle as={Card.Header} eventKey="2">
              {problemsTabTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{problemsTabContent}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-3">
            <Accordion.Toggle as={Card.Header} eventKey="3">
              {filtersTabTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>{filtersTabContent}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-4">
            <Accordion.Toggle as={Card.Header} eventKey="4">
              {seoTabTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>{seoTabContent}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="hideOnMobile">
          <Tabs
            className={`${classes.tabs} ${classes.hideTabsWhenMobile}`}
            id="productPhases"
            activeKey={key}
            onSelect={changeTab}
          >
            {/* Datos iniciales de producto */}

            <Tab eventKey={0} title={mainInfoTitle}>
              {mainInfoContent}
            </Tab>

            {/* Datos de productos */}
            <Tab eventKey={1} title={productsTabTitle}>
              {productsTabContent}
            </Tab>

            {/* Datos de filtros */}
            <Tab eventKey={3} title={filtersTabTitle}>
              {filtersTabContent}
            </Tab>

            {/* Datos de SEO */}
            <Tab eventKey={4} title={seoTabTitle}>
              {seoTabContent}
            </Tab>

            {/* Datos de SEO */}
            <Tab eventKey={5} title="Fotos">
              <PhotosTab categoryId={categoryId} idPrestashop={idPrestashop} />
            </Tab>

            {/* Datos de problemas */}
            <Tab eventKey={2} title={problemsTabTitle}>
              {problemsTabContent}
            </Tab>

            {/* {//FIXME @Xavier, para cuando máximo le entregue endpoints} */}
            {/* <Col md={6}>
                  <h5>Atributos del filtro:</h5>
                  {filterAttributes.length > 0 ? (
                    <div className={classes.filtersContainer}>
                      <DraggableSortableFilter
                        items={filterAttributes}
                        droppableId="attributesFilter"
                        disabled={formProcessPhase === states.PROCESSING}
                        handleDranEnd={(result) =>
                          handleDragAndDrop(
                            filterAttributes,
                            setFilterAttributes
                          )(result)
                        }
                      />
                    </div>
                  ) : (
                    <Alert variant="info" className={classes.noItemsAlert}>
                      No hay filtros asociados a esta categoría
                    </Alert>
                  )}
                </Col> */}
          </Tabs>
        </div>
        {showChooseFatherCategory
          ? (() => {
              return (
                <AdministerCopies
                  addButtonDescription="Añadir nueva categoría"
                  buttonDescription="hide"
                  title="Editar categoría principal del producto"
                  addTableTitle="Categoría a añadir"
                  copiesTableTitle="Categoría original"
                  createItem={async (name) => {
                    const responseCreateCategory = await apiCategories.add(
                      accesToken,
                      { name }
                    );

                    (async () => {
                      try {
                        await apiCategories.sync(accesToken, {
                          categoryId: id,
                        });

                        await checkSync.checkCategorySync(accesToken, {
                          categoryId: id,
                        });

                        displayNotification({
                          title: "Se ha sincronizado correctamente",
                          message: "La sync de la categoría ha sido exitosa",
                          type: "success",
                        });
                      } catch (error) {
                        displayNotification({
                          title: "Ha habido un error en la sync",
                          message: "La sync de la categoría ha fallado",
                          type: "danger",
                        });
                      }
                    })();

                    return responseCreateCategory;
                  }}
                  loadItems={() =>
                    apiCategories.listAll(accesToken).then(({ data }) => {
                      const items = data.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }));

                      return { data: items };
                    })
                  }
                  applySelection={(category) => {
                    setHasChanged(true);
                    setFatherCategory(category);
                    setShowChooseFatherCategory(false);
                  }}
                  selectedItems={[fatherCategory.id, id]}
                  close={() => {
                    setShowChooseFatherCategory(false);
                  }}
                />
              );
            })()
          : undefined}
        {showLinkProducts
          ? (() => {
              let selectedItems = [];
              if (products !== states.LOADING) {
                selectedItems = products.map((product) => product.id);
              }

              return (
                <AdministerCopies
                  addButtonDescription="hide"
                  buttonDescription="hide"
                  title="Productos a vincular"
                  loadItems={() => {
                    saveFilters();

                    return apiProducts.listAll(accesToken).then(({ data }) => {
                      const items = data.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }));

                      return { data: items };
                    });
                  }}
                  onSave={async (items) => {
                    try {
                      await apiProducts.addCategoriesToMultipleProducts(
                        accesToken,
                        {
                          productIds: items.map((item) => item.id),
                          categoryIds: [Number(categoryId)],
                        }
                      );

                      const linkedProducts = [];

                      for (const key in items) {
                        const response = await apiProducts.filter(accesToken, {
                          filtername: "id",
                          value: items[key].id,
                        });

                        const product = response.data[0];

                        linkedProducts.push({
                          id: product.id,
                          name: product.name,
                          active: product.active,
                        });

                        setProducts([...products, ...linkedProducts]);
                      }

                      (async () => {
                        for (const item of items) {
                          await syncItem(item.id);
                        }
                      })();

                      await loadFilters();
                    } catch (e) {}
                  }}
                  onSaved={async () => {}}
                  applySelection={() => {}}
                  selectedItems={selectedItems}
                  close={() => {
                    setShowLinkProducts(false);
                  }}
                  multiSelect
                />
              );
            })()
          : undefined}
        {showSolveProblem
          ? (() => {
              const productId = showSolveProblem.productId;
              const featureId = showSolveProblem.featureId;

              return (
                <AdministerCopies
                  multiSelect
                  addButtonDescription="hide"
                  buttonDescription="hide"
                  title="Valores de característica"
                  loadItems={() => {
                    return apiFeatures
                      .filterValues(accesToken, {
                        filtername: "featureId",
                        value: featureId,
                      })
                      .then(({ data }) => {
                        const items = data.map((item) => ({
                          id: item.id,
                          name: item.value,
                        }));

                        return {
                          data: items.filter((item) => item.name !== "NONE"),
                        };
                      });
                  }}
                  applySelection={() => {}}
                  onSaved={async () => {}}
                  onSave={async (valuesToAdd) => {
                    const updatedProblems = problems.filter(
                      (problem) =>
                        !(
                          problem.feature.id === featureId &&
                          problem.product.id === productId
                        )
                    );

                    const problemToSolve = problems.find(
                      (problem) =>
                        problem.feature.id === featureId &&
                        problem.product.id === productId
                    );

                    try {
                      for (const val of valuesToAdd) {
                        await apiProducts.addFeatureValue(accesToken, {
                          productId: problemToSolve.product.id,
                          valueId: val.id,
                        });
                      }

                      setProblems(updatedProblems);

                      setShowSolveProblem(false);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  createItem={async (value) => {
                    const createdResponse = await apiFeatures.addValue(
                      accesToken,
                      {
                        featureId,
                        value,
                      }
                    );

                    (async () => {
                      try {
                        await apiFeatures.sync(accesToken, {
                          featureId,
                        });

                        await checkSync.checkFeatureSync(accesToken, {
                          featureId,
                        });

                        displayNotification({
                          title: "Se ha sincronizado correctamente",
                          message: "La sync de la categoría ha sido exitosa",
                          type: "success",
                        });
                      } catch (error) {
                        displayNotification({
                          title: "Ha habido un error en la sync",
                          message: "La sync de la categoría ha fallado",
                          type: "danger",
                        });
                      }
                    })();

                    return createdResponse;
                  }}
                  selectedItems={[]}
                  close={() => {
                    setShowSolveProblem(false);
                  }}
                />
              );
            })()
          : undefined}
        {showAddFilterFeatures
          ? (() => {
              const selectedItems = [
                ...availableFilterFeatures.map((item) => item.id),
                ...filterFeatures.map((item) => item.id),
              ];

              return (
                <AdministerCopies
                  addButtonDescription="hide"
                  buttonDescription="hide"
                  title="Características"
                  loadItems={() => {
                    return apiFeatures.all(accesToken).then(({ data }) => {
                      const items = data.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }));

                      return { data: items };
                    });
                  }}
                  onSave={async (filters) => {
                    setHasChanged(true);
                    setAvailableFilterFeatures([
                      ...availableFilterFeatures,
                      ...filters.map((item) => ({
                        id: item.id,
                        name: item.name,
                      })),
                    ]);
                  }}
                  onSaved={async () => {}}
                  applySelection={async () => {}}
                  selectedItems={selectedItems}
                  close={() => {
                    setShowAddFilterFeatures(false);
                  }}
                  createItem={async (name) => {
                    const createdResponse = await apiFeatures.add(accesToken, {
                      name,
                    });

                    const featureId = createdResponse.data.id;

                    (async () => {
                      try {
                        await apiFeatures.sync(accesToken, {
                          featureId,
                        });

                        await checkSync.checkFeatureSync(accesToken, {
                          featureId,
                        });

                        displayNotification({
                          title: "Se ha sincronizado correctamente",
                          message: "La sync de la categoría ha sido exitosa",
                          type: "success",
                        });
                      } catch (error) {
                        displayNotification({
                          title: "Ha habido un error en la sync",
                          message: "La sync de la categoría ha fallado",
                          type: "danger",
                        });
                      }
                    })();

                    return createdResponse;
                  }}
                  multiSelect
                />
              );
            })()
          : undefined}
        {showAddFilterAttribute
          ? (() => {
              const selectedItems = [
                ...availableFilterAttributes.map((item) => item.id),
                ...filterAttributes.map((item) => item.id),
              ];

              return (
                <AdministerCopies
                  addButtonDescription="hide"
                  buttonDescription="hide"
                  title="Atributos"
                  loadItems={() => {
                    return apiAttributes
                      .getAttributes(accesToken)
                      .then(({ data }) => {
                        const items = data.map((item) => ({
                          id: item.id,
                          name: item.name,
                        }));

                        return { data: items };
                      });
                  }}
                  onSave={async (filters) => {
                    setHasChanged(true);
                    setAvailableFilterAttributes([
                      ...availableFilterAttributes,
                      ...filters.map((item) => ({
                        id: item.id,
                        name: item.name,
                      })),
                    ]);
                  }}
                  onSaved={async () => {}}
                  applySelection={async () => {}}
                  selectedItems={selectedItems}
                  close={() => {
                    setShowAddFilterAttribute(false);
                  }}
                  multiSelect
                />
              );
            })()
          : undefined}
        {showProduct ? (
          <Product
            modal
            id={showProduct}
            beforeClose={({ id, name, sync, characteristics, active }) => {
              const updatedProducts = products.map((product) => {
                if (product.id === id) {
                  return {
                    ...product,
                    name,
                    sync,
                    active,
                  };
                }

                return product;
              });

              const availableFilterFeaturesIds = availableFilterFeatures.map(
                (feat) => feat.id
              );
              const filterFeaturesIds = filterFeatures.map((flt) => flt.id);

              const characteristicsToadd = characteristics.filter((char) => {
                return (
                  !availableFilterFeaturesIds.includes(char.id) &&
                  !filterFeaturesIds.includes(char.id)
                );
              });

              const processedCharacteristicsToAdd = characteristicsToadd.map(
                (char) => ({
                  id: char.id,
                  name: char.name,
                })
              );

              setAvailableFilterFeatures((prev) => [
                ...prev,
                ...processedCharacteristicsToAdd,
              ]);

              setProducts(updatedProducts);
            }}
            onHide={() => {
              (async () => {
                const response = await apiCategories.filter(accesToken, {
                  filtername: "id",
                  value: categoryId,
                });

                const category = response.data[0];

                loadFiltersFromDBCategory(category);
              })();
              setShowProduct(false);
            }}
          />
        ) : undefined}
      </>
    );
  }

  if (props.modal) {
    const modalClass =
      !showChooseFatherCategory &&
      !showLinkProducts &&
      !showSolveProblem &&
      !showAddFilterFeatures &&
      !showAddFilterAttribute
        ? ""
        : "d-none";

    return (
      <Modal
        show={true}
        className={`bigModal ${modalClass} ${classes.modifyModal}`}
        size="lg"
        onHide={() => {
          if (hasSaved) props.beforeClose({ id, name, active });

          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editar categoría: {name !== states.LOADING ? name : undefined}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <SaveButton
            modal={true}
            processingSync={isSyncing}
            mustSync={mustSync}
            syncError={syncError}
            onClickSync={syncCategory}
            mustSave={hasChanged}
            onClickSave={saveCategory}
            correctSync={correctSync}
            processingSave={isSaving || processProducts}
            error={formProcessPhase === states.ERROR && showFormError === true}
            onClose={() => setShowErrorForm(false)}
            errorMessage="El formulario tiene campos incorrectos, reviselos y vuelva a guardar"
          />
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <SaveButton
        hidden={
          showChooseFatherCategory ||
          showLinkProducts ||
          showSolveProblem ||
          showAddFilterFeatures ||
          showAddFilterAttribute ||
          showProduct
        }
        processingSync={isSyncing}
        mustSync={mustSync}
        syncError={syncError}
        onClickSync={syncCategory}
        mustSave={hasChanged}
        onClickSave={saveCategory}
        correctSync={correctSync}
        processingSave={isSaving || processProducts}
        error={formProcessPhase === states.ERROR && showFormError === true}
        onClose={() => setShowErrorForm(false)}
        errorMessage="El formulario tiene campos incorrectos, reviselos y vuelva a guardar"
      />

      {name !== states.LOADING ? (
        <MainTitle>
          <h1>Categoría: {name}</h1>
        </MainTitle>
      ) : undefined}

      {content}
    </>
  );
};

export default EditCategory;
