import { useState } from "react";

const photosGroupToShow = 6;

interface Photo {
  id: string;
  src: string;
  cover: false;
}

const usePhotos = () => {
  const [photos, setPhotos] = useState("LOADING");
  const [numberOfPhotosToDisplay, setNumberOfPhotosToDisplay] =
    useState(photosGroupToShow);
  const [originalCoverPhotoId, setOriginalCoverPhotoId] = useState();
  const [selectedPhotoToCrop, setSelectedPhotoCrop] = useState<Photo>();

  let numberOfPhotos = 0;
  if (Array.isArray(photos)) {
    numberOfPhotos = photos.length;
  }

  let photosToDisplay = photos.slice(0, numberOfPhotosToDisplay);

  const remainingPhotos = numberOfPhotos - numberOfPhotosToDisplay;

  const handleClickMorePhotos = () => {
    setNumberOfPhotosToDisplay((prev) => prev + photosGroupToShow);
  };

  const showMorePhotosButton = numberOfPhotos > numberOfPhotosToDisplay;

  const handleClickCrop = (photo: Photo) => {
    setSelectedPhotoCrop(photo);
  };

  const handleCloseCropPhotoModal = () => {
    setSelectedPhotoCrop(undefined);
  };

  const showPhotoModalToCrop = selectedPhotoToCrop !== undefined;

  const photosNoCover =
    photos.length > 0 &&
    Array.isArray(photos) &&
    photos.findIndex((photo) => photo.cover) === -1;

  return {
    photos,
    setPhotos,
    numberOfPhotos,
    photosToDisplay,
    remainingPhotos,
    handleClickMorePhotos,
    showMorePhotosButton,
    originalCoverPhotoId,
    setOriginalCoverPhotoId,
    handleClickCrop,
    selectedPhotoToCrop,
    showPhotoModalToCrop,
    handleCloseCropPhotoModal,
    photosNoCover,
  };
};

export default usePhotos;
