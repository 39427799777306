import React, { useState } from "react";
import { Route } from "react-router-dom";
import apiCategories from "../../../api/categories";
import apiLiterals from "../../../api/literals";
import apiBrands from "../../../api/brands";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import TextEditor from "../../../components/TextEditor/TextEditor";
import DataTitle from "../../../components/DataTitle/DataTitle";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import AddButton from "../../../components/AddButton/AddButton";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import AdministerCopies from "../../AdministerCopies/AdministerCopies";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import { Col } from "react-bootstrap";
import Category from "../../Edit/Category/Category";
import Brand from "../../Edit/Brand/Brand";
import displayNotification from "../../../utilities/displayNotification";
import checkSync from "../../../utilities/checkSync";
import classes from "./FirstCreateProductPhase.module.css";
import Article from "../../Edit/Article/Article";
import Literals from "../../Edit/Literals/Literals";
import { QuestionCircle } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";

import AdministerCertificates from "../../Edit/Product/Product/AdministerCertificates/AdministerCertificates";

const LOADING = "LOADING";
const PROCESSING = "PROCESSING";
const ERROR = "ERROR";

const FirstCreateProduct = (props) => {
  const {
    id,
    accesToken,
    processForm,
    description,
    setDescription,
    shortDescription,
    setShortDescription,
    active,
    setActive,
    visiblePrice,
    setVisiblePrice,
    underRequest,
    setUnderRequest,
    mainCategory,
    setMainCategory,
    categories,
    setCategories,
    brand,
    setBrand,
    editBrand,
    error,
    singular,
    plural,
    literalId,
    setSingular,
    setPlural,
    setLiteralId,
    literalMessageError,
    setLiteralMessageError,
    process,
    noSyncUnactive,
    handleEditCategory,
    handleClickShowPrice,
    handleClickUnderRequest,
    validation,
    errorMessages,
    handleCreateCategory,
    handleOpenCreateBrand,
  } = props;
  // * Literals
  const [showLiterals, setShowLiterals] = useState();
  const [literalMessage, setLiteralMessage] = useState(false);
  const [showChangeLiterals, setShowChangeLiterals] = useState();

  const [showFatherCategory, setShowFatherCategory] = useState();
  const [showCategoryInfo, setShowCategoryInfo] = useState();
  const [showBrand, setShowBrand] = useState();
  const [showChangeBrand, setShowChangeBrand] = useState();

  const [hasChanged, setHasChanged] = useState(false);

  const editCategory = (categoryId) => {
    setShowFatherCategory(categoryId);
  };

  let activeErrorMessage =
    "Es necesario especificar si el producto está activo o no";
  if (noSyncUnactive) {
    activeErrorMessage = "No se puede sincronizar un producto no activo";
  }

  return (
    <>
      <TabContainer>
        <TabMainRow>
          <Col md={4}>
            <ReactTooltip delayShow={1000} place="right" type="info" />
            <CheckSphere
              check={active === true}
              x={active === false}
              onClick={() => {
                if (active === undefined) {
                  setActive(true);
                } else {
                  setActive(!active);
                }
              }}
              error={(error && active === undefined) || noSyncUnactive}
              errorMessage={activeErrorMessage}
              disabled={processForm}
            >
              Activo
            </CheckSphere>
          </Col>
          <Col md={4}>
            <CheckSphere
              check={visiblePrice === true}
              x={visiblePrice === false}
              onClick={handleClickShowPrice}
              disabled={processForm}
            >
              Ver precio
            </CheckSphere>
          </Col>
          <Col md={4}>
            <CheckSphere
              check={underRequest === true}
              x={underRequest === false}
              onClick={handleClickUnderRequest}
              disabled={processForm}
              error={error && !validation.showPriceUnderRequest}
              errorMessage={errorMessages.showPriceUnderRequest}
            >
              Bajo pedido
            </CheckSphere>
          </Col>

          <Col md={12}>
            <DataTitle>Descripción</DataTitle>
            <TextEditor
              value={description}
              onEditorChange={(value) => setDescription(value)}
              error={error && (!description || description.length > 50000)}
              errorMessage={
                "Necesitas introducir una descripción válida para poder pasar a la siguiente fase (de menos de 50000 caracteres)"
              }
              disabled={processForm}
            />
          </Col>
          <Col md={12}>
            <DataTitle>Descripción Corta</DataTitle>
            <TextEditor
              value={shortDescription}
              onEditorChange={(value) => setShortDescription(value)}
              error={
                (error && !shortDescription) || shortDescription.length > 400
              }
              errorMessage={
                "Necesitas introducir una descripción corta válida para poder pasar a la siguiente fase"
              }
              disabled={processForm}
            />
          </Col>
            {/* //! Le pongo la clase .hide para que no se vea en el frontend */}
          <Col className={classes.hide} md={12}>
            {/* LITERALS*/}
            <DataTitle>
              Literals:
              <QuestionCircle
                className="ml-2 mb-1"
                data-tip="Define el singular y el plurar de la unidad de venta"
              />
            </DataTitle>
            <OptionsButton
              variant={singular === "" || plural === "" ? "danger" : "primary"}
              chevronExpand={() => {
                // * CHANGE LITERAL MODAL
                setShowChangeLiterals(true);
              }}
              click={() => {}}
              noX
              pencil={() => {
                // * EDIT LITERAL MODAL
                setShowLiterals(literalId);
              }}
              disabled={processForm}
            >
              {singular} / {plural}
            </OptionsButton>
            {/* ERROR MESSAGE IN LITERALS BUTTON */}
            {literalMessageError === true ? (
              <span className={classes.errorMessage}>
                "Necesitas elegir el tipo de unidad (singular y plural) para la
                venta"
              </span>
            ) : null}
          </Col>
          {/* // !----------------------------------------------------------------------------------- */}
          {/* BRAND */}
          <Col md={12}>
            <DataTitle>Marca:</DataTitle>
            {brand ? (
              <OptionsButton
                chevronExpand={() => {
                  setShowChangeBrand(true);
                }}
                click={() => {}}
                noX
                pencil={() => {
                  setShowBrand(brand.id);
                }}
                disabled={processForm}
              >
                {brand.name}
              </OptionsButton>
            ) : (
              <>
                <OptionsButton
                  variant="success"
                  plus={() => {}}
                  click={() => {
                    props.history.push("/create/product/chooseBrand");
                  }}
                  disabled={processForm}
                >
                  Elegir marca
                </OptionsButton>
                <OptionsButton
                  variant="primary"
                  plus={() => {}}
                  click={handleOpenCreateBrand}
                  disabled={processForm}
                >
                  Crear marca
                </OptionsButton>
                {error && (!brand || !brand.id) ? (
                  <InputErrorMessage>
                    Necesitas especificar una marca
                  </InputErrorMessage>
                ) : undefined}
              </>
            )}
          </Col>
          {/* CATEGORY */}
          <Col md={12}>
            <DataTitle>Categoría principal:</DataTitle>
            {!mainCategory.id ? (
              <>
                {error && !mainCategory ? (
                  <InputErrorMessage>
                    Necesitas especificar una categoría principal
                  </InputErrorMessage>
                ) : undefined}
              </>
            ) : (
              <OptionsButton
                chevronExpand={() => {
                  props.history.push("/create/product/mainCategory");
                }}
                pencil={() => {
                  editCategory(mainCategory.id);
                }}
                click={() => {}}
                disabled={processForm}
              >
                {mainCategory.name}
              </OptionsButton>
            )}
            {mainCategory && mainCategory.id ? undefined : (
              <OptionsButton
                variant="success"
                plus={() => {}}
                click={() => {
                  props.history.push("/create/product/mainCategory");
                }}
                disabled={processForm}
              >
                Añadir categoría principal
              </OptionsButton>
            )}
            <OptionsButton
              plus={props.handleOpenCreateCategory}
              click={props.handleOpenCreateCategory}
              disabled={processForm}
            >
              Crear categoría
            </OptionsButton>
          </Col>
          <Col md={12}>
            <DataTitle>Categorías:</DataTitle>
            {!categories || categories.length <= 0 ? (
              <OptionsButton
                variant="success"
                plus={() => {}}
                click={() => {
                  props.history.push("/create/product/categories");
                }}
                disabled={processForm}
              >
                Añadir categorías
              </OptionsButton>
            ) : (
              <>
                {categories.map((item, index) => (
                  <OptionsButton
                    key={item.id}
                    pencil={() => {
                      setShowCategoryInfo(item.id);
                    }}
                    x={() => {
                      const updatedCategories = categories.filter(
                        (category) => category.id !== item.id
                      );

                      setCategories(updatedCategories);
                    }}
                    disabled={processForm}
                    noX={mainCategory.id && mainCategory.id === item.id}
                  >
                    {item.name}
                  </OptionsButton>
                ))}
                <AddButton
                  onClick={() =>
                    props.history.push("/create/product/categories")
                  }
                  disabled={processForm}
                />
              </>
            )}
          </Col>
        </TabMainRow>
      </TabContainer>
      <Route
        path="/create/product/mainCategory"
        component={() => {
          let selectedItems = [];
          if (mainCategory) {
            selectedItems = [mainCategory.id];
          }

          return (
            <AdministerCopies
              addButtonDescription="Añadir nuevo valor de categoría"
              buttonDescription="Unificar categorías"
              title="Añadir categoría"
              addTableTitle="Categorías a añadir"
              copiesTableTitle="Categoría original"
              // createItem={handleCreateCategory}
              loadItems={() =>
                apiCategories.listAll(accesToken).then(({ data }) => {
                  const items = data.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));

                  return { data: items };
                })
              }
              applySelection={(item) => {
                setMainCategory(item);
                setCategories([...categories, item]);

                props.history.push(`/create/product/`);
              }}
              selectedItems={() => {}}
              close={() => {
                props.history.push(`/create/product/`);
              }}
              selectedItems={selectedItems}
              onSave={async () => {}}
              onSaved={async () => {}}
            />
          );
        }}
      />
      <Route
        path="/create/product/categories"
        component={() => {
          let selectedItems = [];
          if (categories.length > 0) {
            selectedItems = categories.map((category) => category.id);
          }

          return (
            <AdministerCopies
              addButtonDescription="Añadir nuevo valor de categoría"
              buttonDescription="Unificar categorías"
              title="Añadir categorías"
              addTableTitle="Categorías a añadir"
              copiesTableTitle="Categoría original"
              // createItem={handleCreateCategory}
              loadItems={() =>
                apiCategories.listAll(accesToken).then(({ data }) => {
                  const items = data.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));

                  return { data: items };
                })
              }
              applySelection={(item) => {}}
              close={() => {
                props.history.push(`/create/product/`);
              }}
              selectedItems={selectedItems}
              onSave={async (items) => {
                setCategories([...categories, ...items]);
              }}
              onSaved={async () => {}}
              multiSelect
            />
          );
        }}
      />
      <Route
        path="/create/product/chooseBrand"
        component={(props) => {
          const selectedItems = [];

          if (brand) {
            selectedItems.push(brand.id);
          }

          return (
            <AdministerCopies
              addButtonDescription="hide"
              buttonDescription="hide"
              title="Cambiar la marca"
              copiesTableTitle="Marcas a juntar"
              close={() => {
                props.history.push(`/create/product`);
              }}
              loadItems={() => apiBrands.getAll(accesToken)}
              applySelection={editBrand}
              selectedItems={selectedItems}
              unifyItems={() => {}}
            />
          );
        }}
      />
      {showFatherCategory
        ? (() => {
            const id = showFatherCategory;

            return (
              <Category
                modal
                id={id}
                onHide={() => setShowFatherCategory(false)}
                beforeClose={({ id, name }) => {
                  handleEditCategory({ id, name });
                }}
              />
            );
          })()
        : undefined}
      {/* enseñar modal de categoría */}
      {showCategoryInfo
        ? (() => {
            const id = showCategoryInfo;

            return (
              <Category
                modal
                id={id}
                onHide={() => setShowCategoryInfo(false)}
                beforeClose={({ id, name }) => {
                  handleEditCategory({ id, name });
                }}
              />
            );
          })()
        : undefined}
      {showBrand ? (
        <Brand
          modal
          id={showBrand}
          close={() => setShowBrand(false)}
          beforeClose={(brandname) => {
            setBrand({ ...brand, name: brandname });
          }}
        />
      ) : undefined}
      {showChangeBrand
        ? (() => {
            return (
              <AdministerCopies
                addButtonDescription="Añadir marca"
                buttonDescription="hide"
                title="Cambiar valor de marca"
                addTableTitle="Marcas a añadir"
                copiesTableTitle="Marca original"
                loadItems={() => apiBrands.getAll(accesToken)}
                onSave={() => {}}
                selectedItems={[]}
                close={() => {
                  setShowChangeBrand(false);
                }}
                createItem={async (name) => {
                  const response = apiBrands.add(accesToken, { name });

                  const brandId = response.data.id;
                  (async () => {
                    try {
                      await apiBrands.sync(accesToken, {
                        brandId,
                      });

                      await checkSync.checkBrandSync(accesToken, {
                        brandId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync de la marca ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync de la marca ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return {
                    data: {
                      id: response.data.id,
                      name: name,
                    },
                  };
                }}
                applySelection={(brandToAdd) => {
                  setBrand({
                    id: brandToAdd.id,
                    name: brandToAdd.name,
                  });
                  setShowChangeBrand(false);
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {/*//! Dejo comentado para que no salga el modal de literals, por si acaso */}
      {/* {showChangeLiterals
        ? (() => {
            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Cambiar valor de literals"
                addTableTitle="Literals a añadir"
                copiesTableTitle="Literals original"
                loadItems={async () => {
                  const response = await apiLiterals.getAll(accesToken);
                  return {
                    data: response.data.map((item) => ({
                      id: item.id,
                      name: `${item.singular} / ${item.plural}`,
                    })),
                  };
                }}
                onSave={() => {}}
                selectedItems={[]}
                close={() => {
                  setShowChangeLiterals(false);
                  setLiteralMessageError(false);
                }}
                applySelection={async (literalsToAdd) => {
                  await apiLiterals
                    .filter(accesToken, {
                      filtername: "id",
                      id: literalsToAdd.id,
                    })
                    .then(({ data }) => {
                      setSingular(data[0].singular);
                      setPlural(data[0].plural);
                      setLiteralId(data[0].id);
                    });

                  setShowChangeLiterals(false);
                  setLiteralMessageError(false);

                  setHasChanged(true);
                }}
                literalsMessage={literalMessage}
                literalTooltip={true}
                // *CREATE ANOTHER LITERAL INSIDE OF THE CHANGELITERAL MODAL
                createItem={async (name) => {
                  if (name.indexOf("//") === -1) {
                    setLiteralMessage(true);
                    displayNotification({
                      title: `Falta la DOBLE barra divisora
                      Introduce los literals en el siguiente formato: XXXXXXXXX // YYYYYYY `,
                      message: `Para una correcta creación introduce los literals en el siguiente formato:  XXXXXXXXX // YYYYY`,

                      type: "danger",
                    });
                    return;
                  } else {
                    // const dividedData = name.split("//");
                    // const newSingular = dividedData[0];
                    // const newPlural = dividedData[1];

                    // * SPLIT THE INPUT WITH //, REMOVE BLANK SPACES WITH TRIM() & AFIRST LETTER UPPERCASE WITH TOUPPERCASE
                    const dividedData = name.split("//");
                    const preSingular = dividedData[0].trim();
                    const newSingular =
                      preSingular[0].toUpperCase() + preSingular.slice(1);
                    const prePlural = dividedData[1].trim();
                    const newPlural =
                      prePlural[0].toUpperCase() + prePlural.slice(1);

                    const response = await apiLiterals.newLiteral(
                      accesToken,
                      newSingular,
                      newPlural
                    );

                    return {
                      data: {
                        id: response.data.id,
                        name: name,
                      },
                    };
                  }
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined} */}
      {/* //!---------------------------------------------------------------- */}
      {/*//! Dejo comentado para que no salga el modal de literals, por si acaso */}
      {/* Editing literals modal here*/}
      {/* {showLiterals ? (
        <Literals
          modal
          id={showLiterals}
          close={() => setShowLiterals(false)}
          beforeClose={({ singular, plural }) => {
            setHasChanged(true);
            // setLiteralMessageError(false);
            setSingular(singular);
            setPlural(plural);
          }}
        />
      ) : undefined} */}
      {/* //!----------------------------------------------------------------------- */}
    </>
  );
};

export default FirstCreateProduct;
