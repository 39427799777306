import React from "react";

import { Button } from "react-bootstrap";
import {
  FilePdf,
  PencilSquare,
  X,
  Circle,
  CheckCircleFill,
} from "react-bootstrap-icons";

import classes from "./CertificatesList.module.scss";

const CertificatesList: React.FC<{
  certificates: any[];
  selectAvailable?: boolean;
  handleCliclSelectCertificate?: (index: number) => void;
  handleClickRemove?: (index: number) => void;
  handleClickPdf: (index: number) => void;
  disalowRemove?: boolean;
  handleClickEdit: (id: number) => void;
  disabled?: boolean;
}> = ({
  certificates,
  selectAvailable,
  handleCliclSelectCertificate,
  handleClickRemove,
  handleClickPdf,
  disalowRemove,
  handleClickEdit,
  disabled,
}) => {
  const filesListClasses = [classes.filesList];

  let handleClickSelect: any = () => {};

  if (selectAvailable) {
    filesListClasses.push(classes.selectAvailable);
    handleClickSelect = handleCliclSelectCertificate;
  }

  if (disabled) {
    filesListClasses.push(classes.disabled);
  }

  let handleClickX: (val: number) => void = () => {};

  if (handleClickRemove) {
    handleClickX = handleClickRemove;
  }

  const randomQuery = Math.floor(Math.random() * 100);

  return (
    <ul className={filesListClasses.join(" ")}>
      {certificates.map(({ id, name, description, selected }, key) => {
        let rowClasses = "";

        if (selected) {
          rowClasses = classes.selected;
        }

        return (
          <li className={rowClasses}>
            <button onClick={() => handleClickSelect(id)} disabled={disabled}>
              {selected ? (
                <CheckCircleFill className={classes.chckedIcon} />
              ) : (
                <Circle />
              )}
            </button>
            <div>{id}</div>
            <div>
              <img
                src={`https://www.updirecto.es/img/certificados/${name}.jpg?dumb=${randomQuery}`}
                height={20}
                width={20}
                alt="img"
              />
            </div>
            <div>{name}</div>
            <div>{description}</div>
            <div>
              <Button
                variant="light"
                className={classes.certificateOptionButton}
                onClick={() => handleClickEdit(id)}
                disabled={disabled}
              >
                <PencilSquare className={classes.editIcon} />
              </Button>
              <Button
                variant="light"
                className={classes.certificateOptionButton}
                onClick={() => handleClickPdf(id)}
                disabled={disabled}
              >
                <FilePdf className={classes.pdfIcon} />
              </Button>
              {!disalowRemove && (
                <Button
                  variant="light"
                  className={classes.certificateOptionButton}
                  onClick={() => handleClickX(key)}
                  disabled={false}
                >
                  <X />
                </Button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CertificatesList;
