import { useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../utilities/processStates";

import apiAttributes from "../../../../api/attributes";
import apiSync from "../../../../api/sync";

const useSyncAttributes = () => {
  const [attributes, setAttributes] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [syncAttributesProcessState, setSyncAttributesProcessState] =
    useState<String>();

  const accesToken = useSelector((state: any) => state.user.access);

  const processDbResponse = (dataAttributes: any) => {
    setAttributes(dataAttributes.results);
    setTotal(dataAttributes.count);
  };

  const handleClickPage = async (value: number) => {
    try {
      const attributesFromPageResponse = await apiSync.getSyncErrors(
        accesToken,
        {
          filtername: "attributes",
          syncFail: false,
          needSync: false,
          synchronizing: false,
          noId: false,
          page: value + 1,
        }
      );

      setAttributes(attributesFromPageResponse.data.results);
      setActivePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSyncAll = async () => {
    let pageToSync = 0;
    let nextPageExists = true;

    setSyncAttributesProcessState(PROCESSING);

    while (nextPageExists) {
      const attributeResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "attributes",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: pageToSync + 1,
      });

      const attrbutesToSync = attributeResponse.data.results;

      nextPageExists = !!attributeResponse.data.links.next;

      await Promise.allSettled(
        attrbutesToSync.map((val: any) => {
          return (async () => {
            try {
              await apiAttributes.sync(accesToken, {
                attributeId: val.id,
              });
            } catch (error) {
              console.log(error);
            }
          })();
        })
      );

      pageToSync += 1;
    }

    setSyncAttributesProcessState(undefined);
  };

  const totalPages = Math.ceil(total / 20);

  const isProcessingAttributes = syncAttributesProcessState === PROCESSING;

  return {
    attributes,
    setAttributes,
    processAttributesDbResponse: processDbResponse,
    totalAttributes: total,
    setTotalAttributes: setTotal,
    activeAttributesPage: activePage,
    handleClickAttributePage: handleClickPage,
    totalAttributePages: totalPages,
    handleClickSyncAllAttributes: handleClickSyncAll,
    isProcessingAttributes,
  };
};

export default useSyncAttributes;
