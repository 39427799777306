import React from "react";

import { Modal, Button, Spinner } from "react-bootstrap";
import ReactCrop from "react-image-crop";

import useCropImage from "./hooks/use-cropImageModal";

const CropImageModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  photoSrc: string;
  productId: number;
  handleFinish: () => void;
}> = ({ show, handleClose, photoSrc, productId, handleFinish }) => {
  const {
    crop,
    setCrop,
    handleClickSave,
    isProcessing,
    onLoad,
    previewCanvasRef,
  } = useCropImage(productId, handleClose, handleFinish);

  const handleCloseFunction = isProcessing ? () => {} : handleClose;

  return (
    <Modal show={show} onHide={handleCloseFunction} className="mediumModal">
      <Modal.Header closeButton>
        <Modal.Title>Recortar imagen de producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactCrop
          src={photoSrc}
          crop={crop}
          onChange={(newCrop: any) => setCrop(newCrop)}
          disabled={isProcessing}
          onImageLoaded={onLoad}
        />
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            display: "none",
            width: Math.round(crop?.width ?? 0),
            height: Math.round(crop?.height ?? 0),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseFunction}
          disabled={isProcessing}
        >
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={handleClickSave}
          disabled={isProcessing}
        >
          {isProcessing ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Guardar cambios"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropImageModal;
