import { useState } from "react";
import { useSelector } from "react-redux";

import apiCategories from "../../../../api/categories";
import apiProducts from "../../../../api/products";

interface Filter {
  id: number;
  name: string;
}

const useFilters = (id: number) => {
  const [filterFeatures, setFilterFeatures] = useState([]);
  const [availableFilterFeatures, setAvailableFilterFeatures] =
    useState("LOADING");
  const [filterAttributes, setFilterAttributes] = useState([]);
  const [availableFilterAttributes, setAvailableFilterAttributes] =
    useState("LOADING");

  const accesToken = useSelector((state: any) => state.user.access);

  const loadFiltersFromDBCategory = (category: any) => {
    const updatedAvailableFilterFeatures =
      category.product_features_not_in_filters.map((filter: any) => ({
        id: filter.id,
        name: filter.name,
      }));
    setAvailableFilterFeatures(updatedAvailableFilterFeatures);

    const processedFilterFeatures = category.featureFilters.map(
      (item: any) => ({
        id: item.feature_id,
        name: item.feature__name,
      })
    );
    setFilterFeatures(processedFilterFeatures);

    const processedAttributeFilters = category.attributeFilters.map(
      (item: any) => ({
        id: item.attribute_id,
        name: item.attribute__name,
      })
    );
    setFilterAttributes(processedAttributeFilters);

    const processedAvailableFiltersAttributes =
      category.attributes_not_in_filters.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
    setAvailableFilterAttributes(processedAvailableFiltersAttributes);
  };

  const loadFilters = async () => {
    try {
      const response = await apiCategories.filter(accesToken, {
        filtername: "id",
        value: id,
      });

      const category = response.data[0];

      loadFiltersFromDBCategory(category);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFilters = async () => {
    await apiCategories.changeFilterOrder(accesToken, {
      category: id,
      filtername: "features",
      valuesList: filterFeatures.map((item: Filter) => item.id),
    });

    await apiCategories.changeFilterOrder(accesToken, {
      category: id,
      filtername: "attributes",
      valuesList: filterAttributes.map((item: Filter) => item.id),
    });

    await apiProducts.checkProductFeatureCategory(accesToken, {
      categoryId: id,
    });
  };

  return {
    filterFeatures,
    setFilterFeatures,
    availableFilterFeatures,
    setAvailableFilterFeatures,
    filterAttributes,
    setFilterAttributes,
    availableFilterAttributes,
    setAvailableFilterAttributes,
    loadFiltersFromDBCategory,
    loadFilters,
    saveFilters,
  };
};

export default useFilters;
