import displayNotification from "./displayNotification";

const generateUrlValidation = (props) => {
  const chars = {
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    ä: "a",
    å: "a",
    æ: "ae",
    è: "e",
    é: "e",
    ê: "e",
    ë: "e",
    ì: "i",
    í: "i",
    î: "i",
    ï: "i",
    ð: "o",
    ò: "o",
    ó: "o",
    ô: "o",
    õ: "o",
    ö: "o",
    ù: "u",
    ú: "u",
    û: "u",
    ü: "u",
    ç: "c",
    ñ: "n",
    ý: "y",
    ÿ: "y",
    ø: "0",
    ž: "z",
    š: "s",
    ß: "ss",
    þ: "th",
    "/": "-",
  };

  if (!props.specialCharactersName) {
    displayNotification({
      title: "Caracteres especiales en el nombre",
      message: (
        <div className="mb-3">
          <p>Modifique el campo nombre para que no haya errores</p>
        </div>
      ),
      type: "danger",
    });

    return false;
  } else {
    props.setSpecialCharactersURL(true);
    return props.name
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll(/[àáâãäåæèéêëìíîïðòóôõöùúûüñçýÿøžšßþ\/]/g, (m) => chars[m]);
  }
};
export default generateUrlValidation;
