import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { Modal, Alert, Button } from "react-bootstrap";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextInput from "../../../components/TextInput/TextInput";
import ButtonSpinner from "../../../components/ButtonSpinner/ButtonSpinner";
import classes from "./EditAttributeValue.module.css";

import displayNotification from "../../../utilities/displayNotification";

const LOADING = "LOADING";
// const ERROR = "ERROR";
const PROCESSING = "PROCESSING";

const EditAttributeValue = (props) => {
  const [name, setName] = useState(LOADING);
  const [type, setType] = useState(LOADING);
  const [psId, setPsId] = useState(LOADING);
  const [formProcessPhase, setFormProcessPhase] = useState();

  const colorPickerRef = useRef(null);
  const [color, setColor] = useState("#ececec");
  const [showColor, setShowColor] = useState(false);

  const [correctSync, setCorrectSync] = useState(false);
  const [mustSync, setMustSync] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const [errors, setErrors] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [exitPath, setExitPath] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
  }, [exitPath]);

  useEffect(() => {
    (async () => {
      const data = await props.onLoad();

      setName(data.value);
      setType(data.attribute__attribute_type);
      setColor(data.color ?? color);
      setPsId(data.prestashop_id);

      try {
        const syncStatusResponse = await props.loadSyncCheck();
        if (syncStatusResponse.synchronizing) {
          setIsSyncing(true);
        }

        if (data.attribute__attribute_type !== "info") {
          const checkResponse = await props.onCheckSync();

          if (checkResponse) {
            setMustSync(false);
            setCorrectSync(true);
          } else {
            setMustSync(true);
          }
        }

        setIsSyncing(false);
        setSyncError(false);
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => {
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await onClickSave();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await onClickSave();
                await onClickSync();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });

        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  }, [hasChanged]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColor(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickerRef]);

  let content = <Spinner />;

  let nameInputErrorMessage =
    "Debe especificar un nombre para poder cambiar el valor";

  if (duplicateNameError) {
    nameInputErrorMessage =
      "Este nombre de valor ya existe, no puede haber 2 iguales";
  }

  if (name !== LOADING) {
    content = (
      <>
        {!props.modal ? <h2 className="mt-4">{name}</h2> : undefined}
        <Alert variant="warning">
          Cambiar este valor afectará todos los items vinculados
        </Alert>
        <span className={classes.prestashopId}>
          <b>PS ID:</b> {psId}
        </span>
        <DataTitle>Nombre:</DataTitle>
        <TextInput
          defaultValue={name}
          onKeyUp={(event) => {
            setName(event.target.value);
            setHasChanged(true);
            setDuplicateNameError(false);
            setSaveError(false);
          }}
          error={name.length <= 0 || duplicateNameError}
          errorMessage={nameInputErrorMessage}
          disabled={formProcessPhase === PROCESSING}
        />
        {type === "color" ? (
          <div className={classes.colorPicker}>
            <div
              onClick={() => setShowColor(!showColor)}
              style={{ backgroundColor: color.length > 0 ? color : "#dedede" }}
            ></div>
            <span onClick={() => setShowColor(!showColor)}>
              {color.length > 0 ? color : "#dedede"}
            </span>
          </div>
        ) : undefined}
        {showColor ? (
          <div style={{ position: "absolute" }} ref={colorPickerRef}>
            <SketchPicker color={color} onChange={({ hex }) => setColor(hex)} />
          </div>
        ) : undefined}
      </>
    );
  }

  const onClickSync = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await onClickSave();
      if (!hasSavedSuccesFully) {
        setIsSyncing(false);
        return;
      }

      if (type !== "info") {
        await props.onSync();
        await props.onCheckSync(setIsSyncing);
      }

      setMustSync(false);
      setIsSyncing(false);
    } catch (e) {
      setSyncError(true);
      setIsSyncing(false);
    }
  };

  const onClickSave = async () => {
    try {
      if (name.length <= 0) {
        setErrors(true);
        setShowErrorMessage(true);
        throw new Error();
      }

      setIsSaving(true);
      setErrors(false);

      await props.onSave({ value: name, color });

      setHasChanged(false);
      setHasSaved(true);
      setMustSync(true);
      setIsSaving(false);
      return true;
    } catch (error) {
      const errorStatus = error.response.status;

      if (errorStatus === 409) {
        setDuplicateNameError(true);

        displayNotification({
          title: "Nombre del valor de atributo duplicado",
          message:
            "El nombre del valor de atributo especificado ya existe, cambielo si desea guardarlo",
          type: "danger",
        });
      }

      setShowErrorMessage(true);
      setIsSaving(false);
      setHasChanged(false);
      setErrors(true);
      setSaveError(true);
      return false;
    }
  };

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (name && hasChanged) {
        setIsSaving(true);

        onClickSave();
      }
    }, 5000);

    return () => clearTimeout(saveTimer);
  }, [hasChanged, name]);

  let buttonContent = "Guardar y sincronizar";
  let buttonVariant = "success";

  if (type === "info") {
    buttonContent = "Guardar";
  }

  if (isSaving || isSyncing) {
    buttonContent = <ButtonSpinner />;
  } else if (saveError || syncError) {
    buttonContent = "Ha ocurrido un error, intentelo de nuevo";
    buttonVariant = "danger";
  }

  if (hasChanged) {
    buttonVariant = "danger";
  }

  const SaveAndSyncButton = () => (
    <Button
      variant={buttonVariant}
      onClick={onClickSync}
      disabled={isSaving || isSyncing}
    >
      {buttonContent}
    </Button>
  );

  if (props.modal) {
    return (
      <Modal
        className={classes.modifyModal}
        show={true}
        size="lg"
        onHide={() => {
          if (hasSaved) props.beforeClose(name);
          props.close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title}: {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <SaveAndSyncButton />
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <>
        <Prompt
          when={hasChanged && !exitPath}
          message={({ pathname }) => {
            saveBeforeExitAlert(true).then(async (value) => {
              switch (value) {
                case "save":
                  try {
                    await onClickSave();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "saveSync":
                  try {
                    await onClickSave();
                    await onClickSync();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "exit":
                  setExitPath(pathname);
                  swal.close();
                  break;

                default:
                  break;
              }
            });

            return false;
          }}
        />
        {content}
        <div className="d-flex justify-content-end mt-3">
          <SaveAndSyncButton />
        </div>
      </>
    );
  }
};

export default EditAttributeValue;
