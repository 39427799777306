import { Form, Row, Col } from "react-bootstrap";

const SearchRow: React.FC<{
  searchQuery: string;
  handleTypeSearchQuery: (val: string) => void;
  filterParam: string;
  handleChangeFilterParam: (val: string) => void;
  disableTyping: boolean;
}> = ({
  searchQuery,
  handleTypeSearchQuery,
  filterParam,
  handleChangeFilterParam,
  disableTyping,
}) => {
  return (
    <Form className="mb-2">
      <Row>
        <Col>
          <Form.Control
            placeholder="Busqueda"
            value={searchQuery}
            onChange={(event) => {
              handleTypeSearchQuery(event.target.value);
            }}
            disabled={disableTyping}
          />
        </Col>
        <Col>
          <Form.Control
            as="select"
            value={filterParam}
            onChange={(event) => {
              handleChangeFilterParam(event.target.value);
            }}
          >
            <option value="name">Descripcion</option>
            <option value="ref">Referencia</option>
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchRow;
