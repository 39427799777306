import { Table, Button } from "react-bootstrap";

import { Plus, X } from "react-bootstrap-icons";

import Article from "../types/Article";

import classes from "./ArticlesList.module.scss";

const ArticlesList: React.FC<{
  articles: Article[];
  handleClickArticle: (clickedArticle: Article) => void;
  disabled: boolean;
}> = ({ articles, handleClickArticle, disabled }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Referencia</th>
          <th>Descripcion</th>
          <th>Opciones</th>
        </tr>
      </thead>
      <tbody>
        {articles.map((article) => {
          let rowClass;
          if (article.selected) {
            rowClass = "table-success";
          }

          const rowClasses = [rowClass];
          if (disabled) {
            rowClasses.push(classes.disabled);
          }

          return (
            <tr key={article.id} className={rowClasses.join(" ")}>
              <td>{article.id}</td>
              <td>{article.ref}</td>
              <td>{article.description_erp}</td>
              <td>
                <Button
                  variant="success"
                  className={classes.addButton}
                  onClick={() => handleClickArticle(article)}
                  disabled={disabled}
                >
                  {article.selected ? <X /> : <Plus />}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ArticlesList;
