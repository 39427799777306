import React, { useState } from "react";
import { useSelector } from "react-redux";
import ItemsListTable from "../../../components/ItemListTable/ItemListTable";
import Spinner from "../../../components/Spinner/Spinner";
import { Card, Form, Col, Button, FormCheck, Accordion } from "react-bootstrap";
import classes from "./SyncList.module.css";
import handleApiErrors from "../../../utilities/handleApiErrors";
import apiSync from "../../../api/sync";

import useSyncAttributes from "./hooks/use-syncAttributes";
import useSyncAttributeValues from "./hooks/use-syncAttributeValues";
import useSyncArticles from "./hooks/use-syncArticles";
import useSyncProducts from "./hooks/use-syncProducts";
import useSyncCategories from "./hooks/use-syncCategories";
import useBrands from "./hooks/use-brands";

import AttributesValueList from "../AttributesValueList/AttributesValueList";

const LOADING = "LOADING";

const SyncList = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // STATES
  const [features, setFeatures] = useState([]);
  const [featureValues, setFeatureValues] = useState([]);

  const [searchField, setSearchField] = useState("all");
  const [syncFailState, setSyncFailState] = useState(false);
  const [needSyncState, setNeedSyncState] = useState(false);
  const [synchronizingState, setSynchronizing] = useState(false);
  const [noIdState, setNoIdState] = useState(false);

  const [activeList, setActiveList] = useState("");

  const {
    attributeValues,
    attributeValuesPagesNumber,
    totalAttributeValuesCount,
    setAttributeValues,
    handleClickSyncAll,
    isProcessingAttributeValues,
    activeAttributeValuePage,
    handleClickAttributeValuePage,
    processAttributeValuesDatabaseResponse,
  } = useSyncAttributeValues();

  const {
    articles,
    setArticles,
    processDatabaseArticles,
    totalArticles,
    setTotalArticles,
    activeArticlesPage,
    handleClickArticlesPage,
    totalArticlePages,
  } = useSyncArticles();

  const {
    products,
    setProducts,
    totalProducts,
    setTotalProducts,
    activeProductPage,
    handleClickProductPage,
    totalProductPages,
  } = useSyncProducts();

  const {
    categories,
    setCategories,
    totalCategories,
    setTotalCategories,
    activeCategoriesPage,
    handleClickCategoryPage,
    totalCategoryPages,
  } = useSyncCategories();

  const {
    attributes,
    setAttributes,
    totalAttributes,
    processAttributesDbResponse,
    activeAttributesPage,
    handleClickAttributePage,
    totalAttributePages,
    handleClickSyncAllAttributes,
    isProcessingAttributes,
  } = useSyncAttributes();

  const {
    brands,
    setBrands,
    totalBrandsCount,
    processBrandsDbResponse,
    handleClickBrandsPage,
    totalBrandPagesNumber,
    activeBrandsPage,
  } = useBrands();

  // CLICKS
  const clickCategory = (id) => {
    props.history.push(`/category/${id}`);
  };

  const clickProduct = (id) => {
    props.history.push(`/product/${id}`);
  };

  const clickArticle = (id) => {
    props.history.push(`/article/${id}`);
  };
  const clickAttribute = (id) => {
    props.history.push(`/attribute/${id}`);
  };

  const clickAttributeValue = (id) => {
    props.history.push(`/attribute-value/${id}`);
  };

  const clickFeature = (id) => {
    props.history.push(`/feature/${id}`);
  };
  const clickFeatureValue = (id) => {
    props.history.push(`/feature-value/${id}`);
  };
  const clickBrand = (id) => {
    props.history.push(`/brand/${id}`);
  };

  //   CLICK FUNCTION
  const onSearchErrors = async () => {
    setCategories(LOADING);
    setProducts(LOADING);
    setArticles(LOADING);
    setAttributes(LOADING);
    setAttributeValues(LOADING);
    setFeatures(LOADING);
    setFeatureValues(LOADING);
    setBrands(LOADING);

    try {
      setActiveList("");

      const { data } = await apiSync.getSyncErrors(accesToken, {
        filtername: searchField,
        syncFail: syncFailState,
        needSync: needSyncState,
        synchronizing: synchronizingState,
        noId: noIdState,
      });

      if (searchField === "all") {
        setCategories(data.categories.results);
        setTotalCategories(data.categories.count);

        setProducts(data.products.results);
        setTotalProducts(data.products.count);

        processDatabaseArticles(data.articles.results);
        setTotalArticles(data.articles.count);

        processAttributesDbResponse(data.attributes);

        //Change key from api call
        processAttributeValuesDatabaseResponse(
          data.attributeValues.results,
          data.attributeValues.count
        );
        setFeatures(data.features.results);

        //Change key from api call
        const updatedFeatureValues = data.featureValues.results.map(
          (featureValue) => ({
            ...featureValue,
            name: featureValue.value,
          })
        );
        setFeatureValues(updatedFeatureValues);

        processBrandsDbResponse(data.brands.results, data.brands.count);
      } else {
        // TODO Make this less long (maybe do while or another loop)
        switch (searchField) {
          case "categories":
            setCategories(data.results);
            setTotalCategories(data.count);

            setProducts([]);
            setArticles([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatures([]);
            setFeatureValues([]);
            setBrands([]);

            break;
          case "products":
            setProducts(data.results);
            setTotalProducts(data.count);

            setCategories([]);
            setArticles([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatures([]);
            setFeatureValues([]);
            setBrands([]);
            break;
          case "articles":
            processDatabaseArticles(data.results);
            setTotalArticles(data.count);

            setCategories([]);
            setProducts([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatures([]);
            setFeatureValues([]);
            setBrands([]);
            break;
          case "attributes":
            processAttributesDbResponse(data);

            setProducts([]);
            setCategories([]);
            setArticles([]);
            setAttributeValues([]);
            setFeatures([]);
            setFeatureValues([]);
            setBrands([]);
            break;
          case "attributeValues":
            processAttributeValuesDatabaseResponse(data.results, data.count);

            setProducts([]);
            setCategories([]);
            setArticles([]);
            setAttributes([]);
            setFeatures([]);
            setFeatureValues([]);
            setBrands([]);
            break;
          case "features":
            setFeatures(data.results);

            setProducts([]);
            setCategories([]);
            setArticles([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatureValues([]);
            setBrands([]);
            break;
          case "featureValues":
            const updatedFeatureValues = data.results.map((featureValue) => ({
              ...featureValue,
              name: featureValue.value,
            }));
            setFeatureValues(updatedFeatureValues);

            setProducts([]);
            setCategories([]);
            setArticles([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatures([]);
            setBrands([]);
            break;
          case "brands":
            setBrands(data.results);
            processBrandsDbResponse(data.results, data.count);

            setProducts([]);
            setCategories([]);
            setArticles([]);
            setAttributes([]);
            setAttributeValues([]);
            setFeatures([]);
            setFeatureValues([]);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      handleApiErrors(error);
    }
  };

  let categoriesContent;
  if (categories === LOADING) {
    categoriesContent = <Spinner />;
  } else if (categories.length > 0) {
    categoriesContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          accordionKey={0}
          clickFoldUnfold={() => {
            if (activeList === 0) {
              setActiveList(undefined);
            } else {
              setActiveList(0);
            }
          }}
          title="Categorías"
          items={categories}
          topCount={totalCategories}
          clickItem={clickCategory}
          activepage={activeCategoriesPage}
          totalPagesNumber={totalCategoryPages}
          handleClickPage={handleClickCategoryPage}
        />
      </div>
    );
  }

  let productsContent;
  if (products === LOADING) {
    productsContent = <Spinner />;
  } else if (products.length > 0) {
    productsContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          accordionKey={1}
          clickFoldUnfold={() => {
            if (activeList === 1) {
              setActiveList(undefined);
            } else {
              setActiveList(1);
            }
          }}
          title="Productos"
          items={products}
          topCount={totalProducts}
          clickItem={clickProduct}
          activepage={activeProductPage}
          totalPagesNumber={totalProductPages}
          handleClickPage={handleClickProductPage}
        />
      </div>
    );
  }

  let articlesContent;
  if (articles === LOADING) {
    articlesContent = <Spinner />;
  } else if (articles.length > 0) {
    articlesContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          accordionKey={2}
          clickFoldUnfold={() => {
            if (activeList === 2) {
              setActiveList(undefined);
            } else {
              setActiveList(2);
            }
          }}
          title="Artículos"
          items={articles}
          topCount={totalArticles}
          clickItem={clickArticle}
          articleColumn={true}
          activepage={activeArticlesPage}
          totalPagesNumber={totalArticlePages}
          handleClickPage={handleClickArticlesPage}
        />
      </div>
    );
  }

  let attributesContent;
  if (attributes === LOADING) {
    attributesContent = <Spinner />;
  } else if (attributes.length > 0) {
    attributesContent = (
      <>
        <div className={classes.listContainer}>
          <ItemsListTable
            activeKey={activeList}
            accordionKey={3}
            clickFoldUnfold={() => {
              if (activeList === 3) {
                setActiveList(undefined);
              } else {
                setActiveList(3);
              }
            }}
            title="Atributos"
            items={attributes}
            topCount={totalAttributes}
            clickItem={clickAttribute}
            activepage={activeAttributeValuePage}
            handleClickPage={handleClickAttributePage}
            handleClickSyncAll={handleClickSyncAllAttributes}
            disabled={isProcessingAttributes}
            totalPagesNumber={totalAttributePages}
            activepage={activeAttributesPage}
            handleClickPage={handleClickAttributePage}
          />
        </div>
      </>
    );
  }

  let attributeValuesContent;
  if (attributeValues === LOADING) {
    attributeValuesContent = <Spinner />;
  } else if (attributeValues.length > 0) {
    attributeValuesContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          accordionKey={4}
          clickFoldUnfold={() => {
            if (activeList === 4) {
              setActiveList(undefined);
            } else {
              setActiveList(4);
            }
          }}
          title="Valores de atributo"
          items={attributeValues}
          topCount={totalAttributeValuesCount}
          clickItem={clickAttributeValue}
          clickItem2={clickAttribute}
          attValueColumn={true}
          totalPagesNumber={attributeValuesPagesNumber}
          activepage={activeAttributeValuePage}
          handleClickPage={handleClickAttributeValuePage}
          handleClickSyncAll={handleClickSyncAll}
          disabled={isProcessingAttributeValues}
        />
      </div>
    );
  }

  let featuresContent;
  if (features === LOADING) {
    featuresContent = <Spinner />;
  } else if (features.length > 0) {
    featuresContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          hide={features.length === 0 ? "hide" : null}
          accordionKey={5}
          clickFoldUnfold={() => {
            if (activeList === 5) {
              setActiveList(undefined);
            } else {
              setActiveList(5);
            }
          }}
          title="Características"
          items={features}
          topCount={features.length}
          clickItem={clickFeature}
        />
      </div>
    );
  }

  let featuresValueContent;
  if (featureValues === LOADING) {
    featuresValueContent = <Spinner />;
  } else if (featureValues.length > 0) {
    featuresValueContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          hide={featureValues.length === 0 ? "hide" : null}
          accordionKey={6}
          clickFoldUnfold={() => {
            if (activeList === 6) {
              setActiveList(undefined);
            } else {
              setActiveList(6);
            }
          }}
          title="Valores de característica"
          items={featureValues}
          topCount={featureValues.length}
          clickItem={clickFeatureValue}
        />
      </div>
    );
  }

  let brandsContent;
  if (brands === LOADING) {
    brandsContent = <Spinner />;
  } else if (brands.length > 0) {
    brandsContent = (
      <div className={classes.listContainer}>
        <ItemsListTable
          activeKey={activeList}
          hide={brands.length === 0 ? "hide" : null}
          accordionKey={7}
          clickFoldUnfold={() => {
            if (activeList === 7) {
              setActiveList(undefined);
            } else {
              setActiveList(7);
            }
          }}
          title="Marcas"
          items={brands}
          topCount={totalBrandsCount}
          clickItem={clickBrand}
          totalPagesNumber={totalBrandPagesNumber}
          handleClickPage={handleClickBrandsPage}
          activepage={activeBrandsPage}
        />
      </div>
    );
  }

  return (
    <>
      <Card className="p-3 mt-2">
        <Form
          className={classes.searchForm}
          onSubmit={(e) => {
            e.preventDefault();
            onSearchErrors();
          }}
          disabled={isProcessingAttributeValues}
        >
          <Form.Row className="mb-3">
            <Col md={9} className={classes.searchOptions}>
              <Form.Control
                as="select"
                custom
                value={searchField}
                onChange={(event) => {
                  const searchOption = event.target.value;
                  setSearchField(searchOption);
                }}
                disabled={isProcessingAttributeValues}
              >
                <option value="all">TODAS LAS OPCIONES</option>
                <option value="categories">CATEGORÍAS</option>
                <option value="products">PRODUCTOS</option>
                <option value="articles">ARTÍCULOS</option>
                <option value="attributes">ATRIBUTOS</option>
                <option value="attributeValues">VALORES DE ATRIBUTO</option>
                <option value="features">CARACTERÍSTICAS</option>
                <option value="featureValues">VALORES DE CARACTERÍSTICA</option>
                <option value="brands">MARCAS</option>
              </Form.Control>
            </Col>
            <Col md={3} className={classes.searchOptions}>
              <Button
                variant="primary"
                type="submit"
                block
                disabled={isProcessingAttributeValues}
              >
                Buscar
              </Button>
            </Col>
          </Form.Row>
          <div className={classes.searchOptions}>
            <Form.Group controlId="sync-fail">
              <FormCheck
                // className="ml-5"
                inline
                label="Fallo sync"
                onChange={(event) => {
                  setSyncFailState(event.target.checked);
                }}
                disabled={isProcessingAttributeValues}
              />
            </Form.Group>
            <Form.Group controlId="sync-neccesary">
              <FormCheck
                // className="ml-5"
                inline
                label="Necesario sync"
                onChange={(event) => {
                  setNeedSyncState(event.target.checked);
                }}
                disabled={isProcessingAttributeValues}
              />
            </Form.Group>
            <Form.Group controlId="sync-syncronizing">
              <FormCheck
                // className="ml-5"
                inline
                label="Sincronizando"
                onChange={(event) => {
                  setSynchronizing(event.target.checked);
                }}
                disabled={isProcessingAttributeValues}
              />
            </Form.Group>
            <Form.Group controlId="sync-no-prestashop-id">
              <FormCheck
                // className="ml-5"
                inline
                label="No Prestashop id"
                onChange={(event) => {
                  setNoIdState(event.target.checked);
                }}
                disabled={isProcessingAttributeValues}
              />
            </Form.Group>
          </div>
        </Form>
        {/*------------------ RESULTS -------------------------*/}
        <Accordion activeKey={String(activeList)}>
          {categoriesContent}
          {productsContent}
          {articlesContent}
          {attributesContent}
          {attributeValuesContent}
          {featuresContent}
          {featuresValueContent}
          {brandsContent}
        </Accordion>
      </Card>
    </>
  );
};

export default SyncList;
