import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// BOOTSTRAP
import {
  Alert,
  Button,
  Container,
  Form,
  FormCheck,
  Table,
  Row,
  Col,
} from "react-bootstrap";
// STYLES
import classes from "./OrphansList.module.css";
// API
import apiArticles from "../../../api/articles";
// UTILITIES
import handleApiErrors from "../../../utilities/handleApiErrors";
import useInfiniteScroll from "../../../utilities/useInfiniteScroll";

// COMPONENTS
import Spinner from "../../../components/Spinner/Spinner";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import PaginationItem from "../../../components/PaginationItem/PaginationItem";
import TextInput from "../../../components/TextInput/TextInput";
import UpgradedPagination from "../../../components/UpgradedPagination/UpgradedPagination";

import stringSimilarity from "string-similarity";
import { ArrowUpSquareFill, PencilSquare } from "react-bootstrap-icons";

import useOrphans from "./hooks/use-orphans";

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};
const resultsNumber = 20;
// TEST ARRAY

const OrphansList = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // INPUT
  const [inputValue, setInputValue] = useState("");
  // ARRAYS
  const {
    allOrphans,
    setAllOrphans,
    allOrphansUntouched,
    setAllOrphansUntouched,
  } = useOrphans();

  const [inactiveList, setInactiveList] = useState(false);
  const [activeList, setActiveList] = useState(false);

  // PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  // INFINITE SCROLL
  const [results, setResults] = useState(20);
  // const [loadMore, setLoadMore] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  const [activePage, setActivePage] = useState(0);
  const [totalPagesNumber, setTotalPagesNumber] = useState(0);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilter, setSearchFilter] = useState("all");

  // BRING DATA FROM API
  useEffect(() => {
    let activeSearch;

    switch (activeFilter) {
      case "act":
        activeSearch = true;
        break;

      case "inact":
        activeSearch = false;
        break;

      default:
        activeSearch = undefined;
        break;
    }

    let filterName = "all";
    let value = undefined;

    if (searchQuery.length > 0) {
      filterName = searchFilter;
      value = searchQuery;
    }

    apiArticles
      .getOrphans(accesToken, {
        page: 1,
        filtername: filterName,
        active: activeSearch,
        value,
      })
      .then(({ data }) => {
        setTotalPagesNumber(Math.ceil(data.count / 20));
        setActivePage(0);

        const items = data.results.map((item) => ({
          id: item.id,
          ref: item.ref,
          name: item.description ?? "",
          active: item.active_sales,
          descriptionErp: item.description_erp ?? "",
        }));

        setAllOrphans(items);
        setAllOrphansUntouched(items);

        setProcess(states.LOADED);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, [activeFilter]);

  useEffect(() => {
    let activeSearch;

    switch (activeFilter) {
      case "act":
        activeSearch = true;
        break;

      case "inact":
        activeSearch = false;
        break;

      default:
        activeSearch = undefined;
        break;
    }

    let filterName = "all";
    let value = undefined;

    if (searchQuery.length > 0) {
      filterName = searchFilter;
      value = searchQuery;
    }

    apiArticles
      .getOrphans(accesToken, {
        page: activePage + 1,
        filtername: filterName,
        active: activeSearch,
        value,
      })
      .then(({ data }) => {
        setTotalPagesNumber(Math.ceil(data.count / 20));

        const items = data.results.map((item) => ({
          id: item.id,
          ref: item.ref,
          name: item.description ?? "",
          active: item.active_sales,
          descriptionErp: item.description_erp ?? "",
        }));

        setAllOrphans(items);
        setAllOrphansUntouched(items);

        setProcess(states.LOADED);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, [activePage]);

  useEffect(() => {
    const timerToSearch = setTimeout(async () => {
      let activeSearch;

      switch (activeFilter) {
        case "act":
          activeSearch = true;
          break;

        case "inact":
          activeSearch = false;
          break;

        default:
          activeSearch = undefined;
          break;
      }

      let filterName = "all";
      let value = undefined;

      if (searchQuery.length > 0) {
        filterName = searchFilter;
        value = searchQuery;
      }

      try {
        const result = await apiArticles.getOrphans(accesToken, {
          page: 1,
          filtername: filterName,
          active: activeSearch,
          value,
        });

        setTotalPagesNumber(Math.ceil(result.data.count / 20));
        setActivePage(0);

        const items = result.data.results.map((item) => ({
          id: item.id,
          ref: item.ref,
          name: item.description ?? "",
          active: item.active_sales,
          descriptionErp: item.description_erp ?? "",
        }));

        setAllOrphans(items);
        setAllOrphansUntouched(items);
      } catch (error) {}
    }, 750);

    return () => clearTimeout(timerToSearch);
  }, [searchQuery, searchFilter]);

  const handleClickPage = (clickedPage) => {
    setActivePage(clickedPage);
  };

  // * FUNCTION FOR THE SCROLL INFINITE
  function fetchMoreListItems() {
    setTimeout(() => {
      if (results + 20 > allOrphans.length) {
        setResults(allOrphans.length);
      } else {
        setResults(results + 20);
      }
      setIsFetching(false);
    }, 2000);
  }

  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };

  // * LOGIC FROM SIMILARITY ALGORITHMN
  useEffect(() => {
    const queryedItems = allOrphansUntouched.map((item, index) => {
      const nameScore = stringSimilarity.compareTwoStrings(
        inputValue.toLocaleLowerCase(),
        item.name.toLocaleLowerCase()
      );

      const refScore = stringSimilarity.compareTwoStrings(
        inputValue.toLocaleLowerCase(),
        item.ref.toLocaleLowerCase()
      );

      let score = nameScore > refScore ? nameScore : refScore;

      const scoredItem = {
        ...item,
        score,
      };
      return scoredItem;
    });

    // TODO prueba a hacer otro mapeo y borrar del array los resultados chungos mediante un array nuevo

    const arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (product.name.toLocaleLowerCase().includes(inputValue)) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });
    // When there are 3 letters or more then it shows the flitered/ordered array, if not the general array
    inputValue.length > 2
      ? setAllOrphans([
          ...exactItems,
          ...similarItems.filter((item) => item.score > 0.2),
        ])
      : setAllOrphans(allOrphansUntouched);
  }, [inputValue]);

  // EDIT ARTICLE
  const editArticle = (pId) => {
    props.history.push(`/article/${pId}`);
  };

  const bodyContent1 = allOrphans.map((item, index) => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.ref}</td>
        <td>{item.name}</td>
        <td>
          <CheckSphere check={item.active} x={!item.active} />
        </td>
      </tr>
    );
  });

  // TODO pruebas para el responsive /habria que cambiar el results por resultsnumber depenediendo del responsive na mas
  //  * LOGIC FOR RESPONSIVE, IF MATCH WITH THE MOBILE SCREEN THE RESULTS PER PAGE ADD 20 RESULTS AVERY TIME SCROLL TO THE END OF THE VIEWPORT
  let breakPointResponsive = window.matchMedia("(max-width: 65em)");
  let bodyContent;
  function responsiveBreakMatch(breakPointResponsive) {
    // If media query matches, the type of results change to the state

    bodyContent =
      splitArrays(
        allOrphans,
        breakPointResponsive.matches ? results : resultsNumber
      )[pageNumber] === undefined
        ? null
        : splitArrays(
            allOrphans,
            breakPointResponsive.matches ? results : resultsNumber
          )[pageNumber].map((item, index) => {
            return (
              <>
                {/* <ReactTooltip place="left" type="info" /> */}

                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.ref}</td>
                  <td>{item.name === "" ? item.descriptionErp : item.name}</td>
                  <td>
                    <CheckSphere check={item.active} x={!item.active} />
                  </td>
                  <td>
                    <Button
                      variant="light"
                      onClick={() => editArticle(item.id)}
                    >
                      <PencilSquare />
                    </Button>
                  </td>
                </tr>
                {breakPointResponsive.matches && allOrphans.length > 20 ? (
                  results <= 20 ? null : (
                    <div className={classes.arrowContainer}>
                      <ArrowUpSquareFill
                        // data-tip="Volver al inicio"
                        className={classes.arrowUp}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )
                ) : null}
              </>
            );
          });
  }
  responsiveBreakMatch(breakPointResponsive);

  return (
    <>
      {/* TITLE */}
      <Container className={classes.title}>
        <h1>Lista de artículos huérfanos</h1>
        <div className={classes.buttons}>
          <Container>
            <Row>
              <Col>
                <TextInput
                  placeholder="Introduce el nombre del artículo"
                  value={searchQuery}
                  onChange={(event) => {
                    setSearchQuery(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  as="select"
                  value={searchFilter}
                  onChange={(event) => {
                    setSearchFilter(event.target.value);
                  }}
                >
                  <option value="name">Descripcion</option>
                  <option value="ref">Referencia</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="select"
                  value={activeFilter}
                  onChange={(event) => {
                    setActiveFilter(event.target.value);
                  }}
                >
                  <option value="all">Todos (Activos / Inactivos)</option>
                  <option value="act">Activos</option>
                  <option value="inact">Inactivos</option>
                </Form.Control>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* TABLE */}
      <Container>
        {process === "LOADING" ? (
          <Spinner
            className="mt-5"
            as="svg"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {/* // PAGINATION */}
            {allOrphans.length >= 10 ? (
              <PaginationItem
                arrayWhole={allOrphans}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF PAGINATION */}

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>REF</th>
                  <th>NOMBRE</th>
                  <th>ACTIVO</th>
                  <th>EDITAR</th>
                </tr>
              </thead>
              <tbody>{bodyContent}</tbody>
            </Table>

            {/* // BOTTOM PAGINATION */}
            {allOrphans.length >= 10 ? (
              <PaginationItem
                arrayWhole={allOrphans}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF BOTTOM PAGINATION */}
          </>
        )}
      </Container>
      <UpgradedPagination
        totalPagesNumber={totalPagesNumber}
        activepage={activePage}
        handleClickPage={handleClickPage}
      />
    </>
  );
};

export default OrphansList;
