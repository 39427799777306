import React from "react";
import { useSelector } from "react-redux";
import apiFeatures from "../../../api/features";
import checkSync from "../../../utilities/checkSync";
import EditValue from "../EditValue/EditValue";

const FeatureValue = (props) => {
  const id = props.match ? Number(props.match.params.id) : props.id;
  const accesToken = useSelector((state) => state.user.access);

  return (
    <EditValue
      title="Valor de característica"
      modal={props.modal}
      close={props.onHide}
      beforeClose={props.beforeClose}
      titleName="Valor de característica: "
      url={"/feature-value/:id"}
      onLoad={async () => {
        const { data } = await apiFeatures.filterValues(accesToken, {
          filtername: "id",
          value: Number(id),
        });

        console.log("DAAATA", data);

        return { name: data[0].value, psId: data[0].prestashop_id };
      }}
      onSync={() => apiFeatures.syncValue(accesToken, { featureValueId: id })}
      onCheckSync={() =>
        checkSync.checkFeatureValueSync(accesToken, {
          featureValueId: id,
        })
      }
      onSave={(value) => apiFeatures.editValue(accesToken, { id, value })}
      loadSyncCheck={async () => {
        const response = await apiFeatures.checkValueSync(accesToken, {
          featureValueId: id,
        });

        return response.data.featureValue[0];
      }}
      onHide={props.onHide}
    />
  );
};

export default FeatureValue;
