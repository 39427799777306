import { useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../utilities/processStates";

import apiAttributes from "../../../../api/attributes";
import apiSync from "../../../../api/sync";

const processAttributeValues = (preprocessedValues: any[]) => {
  return preprocessedValues.map((attributeValue: any) => ({
    ...attributeValue,
    name: attributeValue.value,
  }));
};

const useSyncAttributeValues = () => {
  const [attributeValues, setAttributeValues] = useState<any[]>([]);
  const [totalAttributeValuesNumber, setTotalAttributeValuesNumber] =
    useState(0);
  const [activeAttributeValuePage, setactiveAttributeValuePage] = useState(0);
  const [syncAttributeValuesProcess, setSyncAttributeValuesProcess] =
    useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  const processAttributeValuesDatabaseResponse = (
    preprocessedValues: any[],
    totalValuesNumber: number
  ) => {
    const updatedAttributeValues = processAttributeValues(preprocessedValues);

    setAttributeValues(updatedAttributeValues);
    setTotalAttributeValuesNumber(totalValuesNumber);
  };

  const handleClickSyncAll = async () => {
    let pageToSync = 0;
    let nextPageExists = true;

    setSyncAttributeValuesProcess(PROCESSING);
    while (nextPageExists) {
      const attributeValuesResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "attributeValues",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: pageToSync + 1,
      });

      const attributeValuesToSync = attributeValuesResponse.data.results;

      nextPageExists = !!attributeValuesResponse.data.links.next;

      for (const val of attributeValuesToSync) {
        try {
          await apiAttributes.syncValue(accesToken, {
            attributeValueId: val.id,
          });
        } catch (error) {
          console.log(error);
        }
      }

      pageToSync += 1;
    }

    setSyncAttributeValuesProcess(PROCESSING);
  };

  const handleClickAttributeValuePage = async (value: number) => {
    try {
      const attributeValuesFromPage = await apiSync.getSyncErrors(accesToken, {
        filtername: "attributeValues",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      const processedAttributeValues = processAttributeValues(
        attributeValuesFromPage.data.results
      );
      setAttributeValues(processedAttributeValues);
      setactiveAttributeValuePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const isProcessing = syncAttributeValuesProcess === PROCESSING;
  const pagesNumber = Math.ceil(totalAttributeValuesNumber / 20);

  return {
    attributeValues,
    attributeValuesPagesNumber: pagesNumber,
    totalAttributeValuesCount: totalAttributeValuesNumber,
    setAttributeValues,
    handleClickSyncAll,
    isProcessingAttributeValues: isProcessing,
    activeAttributeValuePage,
    handleClickAttributeValuePage,
    processAttributeValuesDatabaseResponse,
  };
};

export default useSyncAttributeValues;
