import { Modal, Button, Spinner, Alert } from "react-bootstrap";

import UpgradedPagination from "../../../../../components/UpgradedPagination/UpgradedPagination";

import ArticlesList from "./ArticlesList/ArticlesList";
import SelectedArticles from "./SelectedArticlesList/SelectedArticlesList";
import SaveItemsButton from "./SaveItemsButton/SaveItemsButton";
import SearchRow from "./SearchRow/SearchRow";

import Article from "./types/Article";

import useArticles from "./hooks/use-addArticles";

const SelectArticlesModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleClickSave: (items: Article[]) => any;
}> = ({ show, handleClose, handleClickSave }) => {
  const {
    articles,
    selectedArticles,
    handleClickArticle,
    isLoadingArticles,
    isProcessingAddArticles,
    handleClickSaveFinal,
    isArticlesSelected,
    searchQuery,
    handleTypeSearch,
    filterParam,
    handleChangeFilterParam,
    totalPagesNumber,
    currentPage,
    handleClickPage,
    noArticles,
  } = useArticles(handleClickSave, handleClose);

  return (
    <Modal show={show} onHide={handleClose} className="mediumModal">
      <Modal.Header closeButton>
        <Modal.Title>Vincular articulos al producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchRow
          searchQuery={searchQuery}
          handleTypeSearchQuery={handleTypeSearch}
          filterParam={filterParam}
          handleChangeFilterParam={handleChangeFilterParam}
          disableTyping={false}
        />
        <SelectedArticles
          articles={selectedArticles}
          handleClickArticle={handleClickArticle}
          disabled={isProcessingAddArticles}
        />
        {isArticlesSelected && (
          <SaveItemsButton
            handleClick={handleClickSaveFinal}
            processing={isProcessingAddArticles}
          />
        )}
        {isLoadingArticles ? (
          <Spinner animation="border" />
        ) : (
          <>
            {!noArticles ? (
              <>
                <ArticlesList
                  articles={articles}
                  handleClickArticle={handleClickArticle}
                  disabled={isProcessingAddArticles}
                />
                <UpgradedPagination
                  totalPagesNumber={totalPagesNumber}
                  activepage={currentPage}
                  handleClickPage={handleClickPage}
                />
              </>
            ) : (
              <Alert variant="info">
                No hay ningun articulo que cumpla con los parametros de
                busqueda, cambielos o seleccione "Todos" para la lista completa
              </Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isProcessingAddArticles}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectArticlesModal;
