import customAxios from "../customAxios";

const filter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const listFilter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/list-filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const all = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const listAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/list-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const add = (access, { name }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ name }),
  });
};

const edit = (access, params) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(params),
  });
};

const getProblems = (access, { categoryId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/feature-values-none/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ categoryId }),
  });
};

const checkErrors = (access, { categoryId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/check-product-features-category/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ categoryId }),
  });
};

const changeFilterOrder = (access, { category, filtername, valuesList }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/change-filters-order/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      category,
      filtername,
      valuesList,
    }),
  });
};

const sync = (access, { categoryId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/category/sinc/id/${categoryId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { categoryId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      categoryId,
    }),
  });
};

const reorderCategoryProducts = (access, { categoryId, productIds }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/change-products-order/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ categoryId, productIds }),
  });
};

const reorderSubcategories = (access, { categoryId, subcategoriesIds }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/change-subcategories-order/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ categoryId, subcategoriesIds }),
  });
};
// TODO cambiar el endpoint al que me pase maximo
const categoriesPrestashopSync = (
  access,
  { categoryId, subcategoriesIds, position }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/change-subcategories-order/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ categoryId, subcategoriesIds,position }),
  });
};

const addPhoto = (access, data) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/upload/category/images/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: data,
  });
};

export default {
  filter,
  listFilter,
  all,
  listAll,
  add,
  edit,
  getProblems,
  checkErrors,
  changeFilterOrder,
  sync,
  checkSync,
  reorderCategoryProducts,
  reorderSubcategories,
  addPhoto,
  categoriesPrestashopSync,
};
