import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  Card,
  Col,
  Tab,
  Tabs,
  Badge,
  Modal,
  Alert,
} from "react-bootstrap";
import { Prompt } from "react-router-dom";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import AdministerCopies from "../../AdministerCopies/AdministerCopies";
import EditAttributeValue from "../EditAttributeValue/EditAttributeValue";
import EditAttribute from "../EditAttribute/EditAttribute";
import apiAttributes from "../../../api/attributes";
import apiArticles from "../../../api/articles";
import apiProducts from "../../../api/products";
import apiLiterals from "../../../api/literals";
import apiBrands from "../../../api/brands";
import DataItem from "../../../components/DataItem/DataItem";
import TextInput from "../../../components/TextInput/TextInput";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import SaveButton from "../../../components/SaveButton/SaveButton";
import PhotoCard from "../../../components/PhotoCard/PhotoCard";
import EditButton from "../../../components/EditButton/EditButton";
import MainTitle from "../../../components/MainTitle/MainTitle";
import Spinner from "../../../components/Spinner/Spinner";
import TitleContainer from "../../../components/TitleContainer/TitleContainer";
import ValuesListList from "../../../components/ValuesListList/ValuesListList";
import classes from "./Article.module.css";
import displayNotification from "../../../utilities/displayNotification";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import solveErrorMessage from "../../../utilities/notifications/solveErrorMessage";
import checkSync from "../../../utilities/checkSync";
import syncPricesPromise from "../../../utilities/syncPrices";
import { QuestionCircle } from "react-bootstrap-icons";
import Literals from "../../Edit/Literals/Literals";
import ReactTooltip from "react-tooltip";

import DisplayMorePhotosButton from "../../../components/DisplayMorePhotosButton/DisplayMorePhotosButton";

import usePhotos from "../Product/Product/hooks/use-photos";

const LOADING = "LOADING";

const Article = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const [id, setId] = useState(LOADING);
  const [idPs, setIdPs] = useState(LOADING);
  const [productId, setProductId] = useState(LOADING);
  const [productName, setProductName] = useState("");
  const [reference, setReference] = useState(LOADING);
  const [unitsPerPackage, setUnitsPerPackage] = useState(LOADING);
  const [active, setActive] = useState(LOADING);
  const [activeSales, setActiveSales] = useState(LOADING);
  const [weight, setWeight] = useState(LOADING);
  const [weightVolume, setWeightVolume] = useState(LOADING);
  const [brand, setBrand] = useState(LOADING);
  const [description, setDescription] = useState(LOADING);
  // !!!!  estados para mandar a la url de prestashop
  // const [productCategoryReWrite, setProductCategoryReWrite] = useState(LOADING)
  // const [productIdPS, setProductIdPS] = useState(LOADING);

  const [prestashopURL, setPrestashopURL] = useState(LOADING);
  // !!!!
  const {
    photosToDisplay,
    photos,
    setPhotos,
    numberOfPhotos,
    remainingPhotos,
    handleClickMorePhotos,
    showMorePhotosButton,
    originalCoverPhotoId,
    setOriginalCoverPhotoId,
  } = usePhotos();

  const [isSaving, setIsSaving] = useState(false);
  const [key, setKey] = useState(0);

  const [attributes, setAttributes] = useState([]);
  const [infoAttributes, setInfoAttributes] = useState([]);
  const [isProcessingAttribute, setIsProcessingAttribute] = useState(false);

  const articleId = props.id || Number(props.match.params.articleId);

  const [mustSync, setMustSync] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [correctSync, setCorrectSync] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const [errors, setErrors] = useState(false);
  const [showSaveError, setShowSaveError] = useState(true);
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const [showChooseBrand, setShowChangeBrand] = useState(false);
  const [showAddAttribute, setShowAddAttribute] = useState(false);
  const [attributeId, setAttributeId] = useState();
  const [valueAttributeId, setValueAttributeId] = useState();
  const [editValueId, setEditValueId] = useState();
  // * LITERALS
  const [literalId, setLiteralId] = useState(1);
  const [singular, setSingular] = useState("Unidad");
  const [plural, setPlural] = useState("Unidades");
  const [showLiterals, setShowLiterals] = useState();
  const [literalMessage, setLiteralMessage] = useState(false);
  const [showChangeLiterals, setShowChangeLiterals] = useState(false);
  const [literalMessageError, setLiteralMessageError] = useState();
  // const [processForm, setProcessForm] = useState(false);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (hasChanged && description.length > 0) {
        setIsSaving(true);

        await apiArticles.edit(accesToken, {
          id,
          description,
          active,
          activeSales,
        });

        setMustSync(true);
        setHasChanged(false);
        setIsSaving(false);
      }
    }, 10000);

    return () => clearTimeout(saveTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged, description]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => {
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await onClickSave();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await onClickSave();
                await onClickSync();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });

        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  const formatAttributes = (attributes) => {
    return attributes.map((atr) => {
      return {
        id: atr.id,
        name: atr.attribute_name,
        values: atr.values.map((val) => ({
          id: val.id,
          name: val.value,
        })),
      };
    });
  };

  useEffect(() => {
    apiArticles
      .filter(accesToken, { filtername: "id", value: articleId })
      .then(async ({ data }) => {
        const article = data[0];

        // !!!!!!! montar estados para mandar a la url de prestashop
        setPrestashopURL(article.article_url);
        // !!!

        setId(article.id);
        setIdPs(article.prestashopId);
        setActive(article.active);
        setActiveSales(article.active_sales);
        setReference(article.ref);
        setProductId(article.product__id);
        setProductName(article.product__name);
        setWeight(Number(article.weight));
        setWeightVolume(Number(article.weight));
        setUnitsPerPackage(Number(article.package_units));
        setIdPs(Number(article.prestashop_id));
        // * LITERALS SETTING
        setLiteralId(article.unit_literal__id);
        setSingular(article.unit_literal__singular ?? "");
        setPlural(article.unit_literal__plural ?? "");
        // setProcessForm(true)

        if (article.description) {
          setDescription(article.description);
        } else if (article.description_erp) {
          setDescription(article.description_erp.toLowerCase());
        } else {
          setDescription("");
        }
        // article.description ?? article.description_erp.toLowerCase() ?? ""
        setBrand({
          id: article.brand__id,
          name: article.brand__name,
        });

        const processedInfoAttributes = formatAttributes(
          article.attributes_data_info
        );
        setInfoAttributes(processedInfoAttributes);

        const formattedAttributes = formatAttributes(article.attributes_data);
        setAttributes(formattedAttributes);

        const productPhotosresponse = await apiProducts.getPhotos(accesToken, {
          productId: article.product__id,
        });

        if (article.active && article.active_sales) {
          const coverImageId = Number(article.default_image_id);

          const sourceImages =
            productPhotosresponse.data.product.associations.images;

          if (sourceImages.length > 0) {
            const articlePhotosResponse = await apiArticles.getPhotos(
              accesToken,
              {
                articleId: article.id,
              }
            );

            const articlePhotosIds =
              articlePhotosResponse.data.associations.images.map((photo) =>
                Number(photo.id)
              );

            const photos = sourceImages.map((photo) => ({
              id: Number(photo.id),
              src: photo.large_default,
              cover: Number(photo.id) === coverImageId,
              selected: articlePhotosIds.includes(Number(photo.id)),
            }));

            console.log("ORIGINAL COVER", coverImageId);
            setOriginalCoverPhotoId(coverImageId);
            setPhotos(photos);
          } else {
            setPhotos([]);
          }
        } else {
          setPhotos([]);
        }

        (async () => {
          try {
            const firstCheckSync = await apiArticles.checkSync(accesToken, {
              articleId,
            });

            if (firstCheckSync.data.article[0].synchronizing) {
              setIsSyncing(true);
            }

            const checkResponse = await checkSync.checkArticleSync(accesToken, {
              articleId,
            });

            if (checkResponse) {
              setMustSync(false);
              setCorrectSync(true);
            } else {
              setMustSync(true);
            }

            setSyncError(false);
            setIsSyncing(false);
          } catch (error) {
            setSyncError(true);
            setIsSyncing(false);
          }
        })();
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesToken]);

  const unlinkAttributesAlert = () =>
    swal({
      title: `El atributo se borrará también en todos los artículos del producto ${productName}`,
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        save: { text: "Continuar", value: true, closeModal: false },
      },
      className: "customSweat",
    });

  const selectUnselectPhoto = async (photoId) => {
    let isPhotoSelected = false;

    const updatedPhotos = photos.map((photo) => {
      if (photo.id === photoId) {
        if (photo.selected) {
          isPhotoSelected = true;
        }

        return {
          ...photo,
          loading: true,
        };
      }

      return photo;
    });

    setPhotos(updatedPhotos);

    const selectedPhotos = photos.filter((photo) => {
      if (isPhotoSelected) {
        return photo.selected && photo.id !== photoId;
      } else {
        return photo.selected || photo.id === photoId;
      }
    });

    let selectedPhotosIds = selectedPhotos.map((photo) => photo.id);

    // Do not allow to send no cover image IF there is a default cover
    if (selectedPhotosIds.length === 0 && originalCoverPhotoId) {
      selectedPhotosIds = [originalCoverPhotoId];

      displayNotification({
        title: "Al menos una imagen debe estar seleccionada",
        type: "info",
        message: "Se ha seleccionado automaticamente la imagen de portada",
      });
    }

    try {
      await apiArticles.choosePhotos(accesToken, {
        combinationId: id,
        images: selectedPhotosIds,
      });

      // When unselect a cover image, uncoverit
      const targettedPhoto = photos.find((photo) => photo.id === photoId);
      if (
        targettedPhoto.cover &&
        !selectedPhotosIds.includes(targettedPhoto.id)
      ) {
        await apiArticles.edit(accesToken, {
          id,
          default_image_id: null,
        });
      }

      const updatedPhotos = photos.map((photo) => {
        let selected = false;
        let cover = photo.cover;

        if (selectedPhotosIds.includes(photo.id)) {
          selected = true;
        } else {
          cover = false;
        }

        return {
          ...photo,
          loading: false,
          selected: selected,
          cover,
        };
      });

      setPhotos(updatedPhotos);
    } catch (e) {}
  };

  const handleClickPhotoStar = async (photoId) => {
    setPhotos((prevPhotos) => {
      const updatedPhotos = prevPhotos.map((photo) => {
        return {
          ...photo,
          loading: true,
        };
      });

      return updatedPhotos;
    });

    // Uncover if image was cover previously a cover
    const targetPhoto = { ...photos.find((ph) => ph.id === photoId) };
    const isImageCover = targetPhoto.cover;
    let default_image_id = photoId;

    if (isImageCover) {
      default_image_id = null;
    }

    try {
      await apiArticles.edit(accesToken, {
        id,
        default_image_id,
      });

      setPhotos((prevPhotos) => {
        const updatedPhotos = prevPhotos.map((photo) => {
          if (photoId === photo.id) {
            return {
              ...photo,
              cover: !photo.cover,
            };
          }

          return {
            ...photo,
            cover: false,
          };
        });

        return updatedPhotos;
      });
    } catch (error) {
      console.log(error);
    }

    setPhotos((prevPhotos) => {
      const updatedPhotos = prevPhotos.map((photo) => {
        return {
          ...photo,
          loading: false,
        };
      });

      return updatedPhotos;
    });
  };

  const removeAttribute = async (attributeId, type) => {
    const alertResponse = await unlinkAttributesAlert();

    const attributeToUnlink = attributes.find(
      (attr) => attr.id === attributeId
    );

    if (!alertResponse) {
      return;
    }

    swal.close();

    let targetAttributeList = attributes;
    let setTargetAttributes = setAttributes;

    if (type === "INFO") {
      targetAttributeList = infoAttributes;
      setTargetAttributes = setInfoAttributes;
    }

    setIsProcessingAttribute(true);
    const updatedAttributes = targetAttributeList.map((attribute) => {
      if (attribute.id === attributeId) {
        return {
          ...attribute,
          processing: true,
        };
      }

      return {
        ...attribute,
      };
    });
    setTargetAttributes(updatedAttributes);

    try {
      await apiArticles.removeAttributes(accesToken, {
        articleId: Number(articleId),
        attributeIds: [attributeId],
      });
      await apiArticles.syncPrices(accesToken, {
        articleId: Number(articleId),
      });

      const updatedAttributes = targetAttributeList.filter(
        (attribute) => attribute.id !== attributeId
      );
      setTargetAttributes(updatedAttributes);

      setIsProcessingAttribute(false);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        const unlinkAttributeModalResponse = await swal({
          title: `El atributo ${attributeToUnlink.name} aparece en la tabla gama lista completa y será eliminado, está seguro de que desea eliminarlo ?`,
          icon: "warning",
          buttons: {
            cancel: "Cancelar",
            delete: { text: "Eliminar", value: true },
          },
        });

        if (unlinkAttributeModalResponse) {
          await apiArticles.removeAttributes(accesToken, {
            articleId: Number(articleId),
            attributeIds: [attributeId],
            forceRemove: true,
          });

          // quitar del array de objetos de attributes el atributo que acabamos de quitar =attributeId

          const updatedAttributes = targetAttributeList.filter(
            (attribute) => attribute.id !== attributeId
          );
          setTargetAttributes(updatedAttributes);

          // ----------------------------
          props.pruebaAtributos(updatedAttributes);

          // ----------------------------
          setTargetAttributes(updatedAttributes);
          setIsProcessingAttribute(false);
        } else {
          const updatedAttributes = targetAttributeList.map((attribute) => {
            if (attribute.id === attributeId) {
              return {
                ...attribute,
                processing: false,
              };
            }

            return {
              ...attribute,
            };
          });

          setTargetAttributes(updatedAttributes);
          setIsProcessingAttribute(false);
        }
      }
    }
  };

  const selectUnselectAttribute = (id, info) => {
    let targetAttributeList = attributes;
    let setTargetAttributes = setAttributes;

    if (info === "INFO") {
      targetAttributeList = infoAttributes;
      setTargetAttributes = setInfoAttributes;
    }

    const updatedAttributes = targetAttributeList.map((attribute) => {
      if (attribute.id === id) {
        return {
          ...attribute,
          remove: !attribute.remove,
        };
      }

      return attribute;
    });

    setTargetAttributes(updatedAttributes);
  };

  const unlinkMultipleAttributes = async (type) => {
    const alertResponse = await unlinkAttributesAlert();

    if (!alertResponse) {
      return;
    }

    swal.close();

    let targetAttributeList = attributes;
    let setTargetAttributes = setAttributes;

    if (type === "INFO") {
      targetAttributeList = infoAttributes;
      setTargetAttributes = setInfoAttributes;
    }

    const updatedAttributes = targetAttributeList.map((attribute) => {
      if (attribute.remove) {
        return {
          ...attribute,
          processing: true,
        };
      }

      return {
        ...attribute,
      };
    });

    setIsProcessingAttribute(true);
    setTargetAttributes(updatedAttributes);

    const attributesToUnLink = targetAttributeList.filter(
      (attribute) => attribute.remove
    );

    const attributeIdsToUnlink = attributesToUnLink.map((item) => item.id);

    try {
      await apiArticles.removeAttributes(accesToken, {
        articleId: Number(articleId),
        attributeIds: attributeIdsToUnlink,
      });
      await apiArticles.syncPrices(accesToken, {
        articleId: Number(articleId),
      });

      const updatedAttributes = targetAttributeList.filter(
        (attribute) => !attribute.remove
      );

      setTargetAttributes(updatedAttributes);
      setIsProcessingAttribute(false);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        const unlinkAttributeModalResponse = await swal({
          title: `Algunos atributos seleccionados aparecen en la tabla gama lista completa y serán eliminados, está seguro de que desea eliminarlo ?`,
          icon: "warning",
          buttons: {
            cancel: "Cancelar",
            delete: { text: "Eliminar", value: true },
          },
        });

        if (unlinkAttributeModalResponse) {
          await apiArticles.removeAttributes(accesToken, {
            articleId: Number(articleId),
            attributeIds: attributeIdsToUnlink,
            forceRemove: true,
          });

          const updatedAttributes = targetAttributeList.filter(
            (attribute) => !attribute.remove
          );

          setTargetAttributes(updatedAttributes);
          setIsProcessingAttribute(false);
        } else {
          const updatedAttributes = targetAttributeList.map((attribute) => {
            if (attributeIdsToUnlink.includes(attribute.id)) {
              return {
                ...attribute,
                processing: false,
              };
            }

            return {
              ...attribute,
            };
          });
          setTargetAttributes(updatedAttributes);
          setIsProcessingAttribute(false);
        }
      }
    }
  };

  let articleInformation = <Spinner />;

  let nameErrorMessage;
  if (description.length <= 0) {
    nameErrorMessage = "El nombre no puede estar vacío";
  } else if (duplicateNameError) {
    nameErrorMessage = "El nombre está duplicado";
  }

  if (id !== LOADING && description !== LOADING) {
    articleInformation = (
      <>
        <TabContainer fluid>
          <TabMainRow>
            <DataItem md={4}>
              <DataTitle>
                <b>ID: </b>
                {id}
              </DataTitle>
            </DataItem>
            <DataItem md={4}>
              <DataTitle>
                <b>ID PS: </b>
                {idPs}
              </DataTitle>
            </DataItem>
            <DataItem md={4}>
              <CheckSphere
                check={activeSales}
                x={!activeSales}
                // onClick={() => {
                //   setHasChanged(true);
                //   setActiveSales(!activeSales);
                // }}
              >
                Activo en compras
              </CheckSphere>
            </DataItem>
            <DataItem md={4}>
              <DataTitle>
                <b>Referencia: </b>
                {reference}
              </DataTitle>
            </DataItem>
            <DataItem md={4}>
              <DataTitle>
                <b>Unidades por paquete:</b> {unitsPerPackage}
              </DataTitle>
            </DataItem>
            <DataItem md={4}>
              <CheckSphere
                check={active}
                x={!active}
                onClick={() => {
                  setHasChanged(true);
                  setActive(!active);
                }}
              >
                Activo
              </CheckSphere>
            </DataItem>
            <DataItem md={4}>
              <DataTitle>
                <b>Peso: </b>
                {weight}
              </DataTitle>
            </DataItem>
            <DataItem md={4}>
              <DataTitle>
                <b>Peso/Volumen: </b>
                {weightVolume}
              </DataTitle>
            </DataItem>
            {/* <DataItem>
              <CheckSphere check={seePrice} x={!seePrice} onClick={() => {}}>
                <h5>
                  <b>Ver precio </b>
                </h5>
              </CheckSphere>
            </DataItem> */}
            {/* //* Aqui deberia ir el boton de literal  */}
            {/* DARIO */}
            <Col className={classes.hide} md={12}>
              {/* LITERALS*/}
              <ReactTooltip place="top" type="info" />

              <DataTitle>
                Literals:
                <QuestionCircle
                  className="ml-2 mb-1"
                  data-tip="Define el singular y el plurar de la unidad de venta"
                />
              </DataTitle>
              <OptionsButton
                variant={
                  singular === "" || plural === "" ? "danger" : "primary"
                }
                chevronExpand={() => {
                  // * CHANGE LITERAL MODAL
                  setShowChangeLiterals(true);
                  // alert("change");
                }}
                click={() => {}}
                noX
                pencil={() => {
                  // * EDIT LITERAL MODAL
                  setShowLiterals(literalId);
                  // alert("edit");
                }}
                // disabled={processForm} No tengo muy claro si se usa en este caso
              >
                {singular} / {plural}
              </OptionsButton>
              {/* ERROR MESSAGE IN LITERALS BUTTON */}
              {literalMessageError === true ? (
                <span className={classes.errorMessage}>
                  "Necesitas elegir el tipo de unidad (singular y plural) para
                  la venta"
                </span>
              ) : null}
            </Col>

            {/* //*------------------------------------ */}
            <Col md={12}>
              <DataTitle>Nombre:</DataTitle>
              <TextInput
                defaultValue={description}
                onKeyUp={(event) => {
                  const value = event.target.value;

                  if (value !== description) {
                    setHasChanged(true);
                    setDescription(value);
                    setDuplicateNameError(false);
                  }
                }}
                error={
                  (errors && description.length <= 0) || duplicateNameError
                }
                errorMessage={nameErrorMessage}
                disabled={true}
              />
            </Col>
            {!active ? (
              <Col md={12}>
                <Alert variant="info" className={classes.noActiveAlert}>
                  No se pueden sincronizar artículos no activos
                </Alert>
              </Col>
            ) : undefined}
          </TabMainRow>
        </TabContainer>
      </>
    );
  }

  const isProcessingPhotos =
    photos !== LOADING ? photos.find((photo) => photo.loading) : undefined;

  const articlesTabTitle = (
    <>
      {duplicateNameError || (description.length <= 0 && errors) ? (
        <TabErrorIcon />
      ) : undefined}
      Artículo
    </>
  );

  const attributesTabTitle = (
    <>
      Atributos
      <Badge
        className="ml-2"
        pill
        variant={attributes.length === 0 ? "danger" : "primary"}
      >
        {attributes.length === 0 ? 0 : attributes.length}
      </Badge>
    </>
  );

  const attributesTabContent = (
    <TabContainer>
      <TabMainRow>
        {attributes.length !== LOADING ? (
          <>
            <Col md={12}>
              <TitleContainer>
                <h3>Atributos</h3>
                <div>
                  {attributes.find((attribute) => attribute.remove) ? (
                    <OptionsButton
                      variant="danger"
                      x={() => {}}
                      click={unlinkMultipleAttributes}
                      disabled={isProcessingAttribute}
                      loading={isProcessingAttribute}
                    >
                      Desvincular atributos
                    </OptionsButton>
                  ) : undefined}
                  <OptionsButton
                    variant="success"
                    plus={() => {}}
                    click={() => setShowAddAttribute(true)}
                    disabled={isProcessingAttribute}
                    loading={isProcessingAttribute}
                  >
                    Añadir atributos
                  </OptionsButton>
                </div>
              </TitleContainer>

              <ValuesListList
                itemTitle="Atributo"
                valueTitle="Valor de atributo"
                items={attributes}
                processing={isProcessingAttribute}
                removeItem={(item) => removeAttribute(item)}
                keyEdit={(attributeId) => {
                  setAttributeId(attributeId);
                }}
                valueEdit={(attributeId, valueId) => {
                  setEditValueId({ attributeId, valueId });
                }}
                onClickChevronExpand={(attributeId) => {
                  setValueAttributeId(attributeId);
                }}
                onSelectUnselectRow={(id) => selectUnselectAttribute(id)}
              />
            </Col>
            <Col md={12}>
              <TitleContainer>
                <h3>Atributos informativos</h3>
                <div>
                  {infoAttributes.find((attribute) => attribute.remove) ? (
                    <OptionsButton
                      variant="danger"
                      x={() => {}}
                      click={() => unlinkMultipleAttributes("INFO")}
                      disabled={isProcessingAttribute}
                      loading={isProcessingAttribute}
                    >
                      Desvincular atributos
                    </OptionsButton>
                  ) : undefined}
                  <OptionsButton
                    variant="success"
                    plus={() => {}}
                    click={() => setShowAddAttribute("INFO")}
                    disabled={isProcessingAttribute}
                    loading={isProcessingAttribute}
                  >
                    Añadir atributo
                  </OptionsButton>
                </div>
              </TitleContainer>

              <ValuesListList
                itemTitle="Atributo"
                valueTitle="Valor de atributo"
                items={infoAttributes}
                processing={isProcessingAttribute}
                removeItem={(item) => removeAttribute(item, "INFO")}
                keyEdit={(attributeId) => {
                  setAttributeId(attributeId);
                }}
                valueEdit={(attributeId, valueId) => {
                  setEditValueId({ attributeId, valueId });
                }}
                onClickChevronExpand={(attributeId) =>
                  setValueAttributeId(attributeId)
                }
                onSelectUnselectRow={(id) => {
                  selectUnselectAttribute(id, "INFO");
                }}
              />
            </Col>
          </>
        ) : (
          <Spinner />
        )}
      </TabMainRow>
    </TabContainer>
  );

  const isLoadingPhotos = photos === LOADING;

  const fotosTabTitle = (
    <>
      Fotos
      {!isLoadingPhotos ? (
        <Badge
          className="ml-2"
          pill
          variant={numberOfPhotos === 0 ? "danger" : "primary"}
        >
          {numberOfPhotos === 0 ? 0 : numberOfPhotos}
        </Badge>
      ) : undefined}
    </>
  );

  let photosContent = (
    <Col md={12} className="my-0">
      <Alert variant="info">
        Este artículo no tiene imágenes a seleccionar, si quiere añadirlas vaya
        al producto vinculado y súbalas.
      </Alert>
    </Col>
  );

  const isArticleActive = !active || !activeSales;

  if (Array.isArray(photosToDisplay) && photosToDisplay.length > 0) {
    photosContent = (
      <>
        <Col md={12} className="my-0">
          <TitleContainer>
            <h3>Fotos</h3>
          </TitleContainer>
          {isArticleActive ? (
            <Alert variant="danger">
              No se pueden manipular las fotos de un artículo que no esté activo
            </Alert>
          ) : (
            <Col className="d-flex flex-wrap">
              {photosToDisplay.map((photo) => {
                return (
                  <PhotoCard
                    key={photo.id}
                    selected={photo.selected}
                    makeCover={() => handleClickPhotoStar(photo.id)}
                    active={photo.cover}
                    src={photo.src}
                    remove={() => {}}
                    loading={photo.loading}
                    disabled={isProcessingPhotos}
                    selectUnselect={() => selectUnselectPhoto(photo.id)}
                    selectOnly
                  />
                );
              })}
              {showMorePhotosButton ? (
                <DisplayMorePhotosButton
                  handleClickMorePhotos={handleClickMorePhotos}
                  remainingPhotos={remainingPhotos}
                />
              ) : undefined}
            </Col>
          )}
        </Col>
      </>
    );
  }

  const fotosTabContent = (
    <TabContainer>
      <TabMainRow>
        {photos === LOADING ? <Spinner /> : <>{photosContent}</>}
      </TabMainRow>
    </TabContainer>
  );

  const content = (
    <>
      <Accordion
        defaultActiveKey="0"
        activeKey={String(key)}
        onSelect={(k) => setKey(k)}
        className={classes.hideAcordionOnPC}
      >
        <Card id="accordion-card-0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {articlesTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{articleInformation}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-1">
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {attributesTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>{attributesTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-2">
          <Accordion.Toggle as={Card.Header} eventKey="2">
            {fotosTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>{fotosTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {/* Principio de la paginación */}
      <div className={classes.hideTabsWhenMobile}>
        <Tabs
          className={classes.tabs}
          id="productPhases"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey={0} title={articlesTabTitle}>
            {articleInformation}
          </Tab>
          <Tab eventKey={1} title={attributesTabTitle}>
            {attributesTabContent}
          </Tab>
          <Tab eventKey={2} title={fotosTabTitle}>
            {fotosTabContent}
          </Tab>
        </Tabs>
      </div>
    </>
  );

  let subRoutes = (
    <>
      {showChooseBrand ? (
        <AdministerCopies
          addButtonDescription="Añadir nuevo valor de marca"
          buttonDescription="hide"
          title="Cambiar la marca"
          copiesTableTitle="Marcas a juntar"
          close={() => {
            setShowChangeBrand(false);
          }}
          loadItems={() => apiBrands.getAll(accesToken)}
          applySelection={async (brand) => {
            await apiArticles.edit(accesToken, {
              id: articleId,
              brandId: brand.id,
            });

            setHasChanged(true);
            setShowChangeBrand(false);
            setBrand({
              id: brand.id,
              name: brand.name,
            });
          }}
          selectedItems={[brand.id]}
          createItem={async (name) => {
            const response = await apiBrands.add(accesToken, {
              name,
            });

            const brandId = response.data.id;
            (async () => {
              try {
                await apiBrands.sync(accesToken, {
                  brandId,
                });

                await checkSync.checkBrandSync(accesToken, {
                  brandId,
                });

                displayNotification({
                  title: "Se ha sincronizado correctamente",
                  message: "La sync de la marca ha sido exitosa",
                  type: "success",
                });
              } catch (error) {
                displayNotification({
                  title: "Ha habido un error en la sync",
                  message: "La sync de la marca ha fallado",
                  type: "danger",
                });
              }
            })();

            return response;
          }}
          unifyItems={() => {}}
        />
      ) : undefined}
      {showAddAttribute
        ? (() => {
            let targetAttributeList = attributes;
            let setTargetAttributes = setAttributes;

            const isAttributeInfo = showAddAttribute === "INFO";

            if (isAttributeInfo) {
              targetAttributeList = infoAttributes;
              setTargetAttributes = setInfoAttributes;
            }

            return (
              <AdministerCopies
                addButtonDescription="Añadir nuevo valor de atributo"
                buttonDescription="Unificar valor de atributo"
                title="Añadir atributo"
                addTableTitle="Atributos a añadir"
                copiesTableTitle="Atributo original"
                close={() => {
                  setShowAddAttribute(false);
                }}
                loadItems={() => {
                  if (showAddAttribute === "INFO") {
                    return apiAttributes.getAttributesInfo(accesToken);
                  }

                  return apiAttributes.getNoInfoAttributes(accesToken);
                }}
                applySelection={() => {}}
                multiSelect
                onSave={async (attributesToSave) => {
                  const attributesIds = attributesToSave.map((item) => item.id);

                  return Promise.all(
                    attributesIds.map((atribbuteId) => {
                      return apiArticles.addAttribute(
                        accesToken,
                        articleId,
                        atribbuteId
                      );
                    })
                  );
                }}
                onSaved={(savedAttributes) => {
                  const updatedAttributes = [...targetAttributeList].concat(
                    savedAttributes.map((atr) => {
                      return {
                        id: atr.id,
                        name: atr.name,
                        values: [
                          {
                            id: 7201,
                            name: "NONE",
                          },
                        ],
                      };
                    })
                  );

                  setTargetAttributes(updatedAttributes);
                }}
                selectedItems={targetAttributeList.map(
                  (attribute) => attribute.id
                )}
                createItem={async (name) => {
                  const response = await apiAttributes.createAttribute(
                    accesToken,
                    {
                      name,
                      type: isAttributeInfo ? "info" : "selection",
                    }
                  );

                  const attributeId = response.data.attribute[0].id;
                  (async () => {
                    try {
                      await apiAttributes.sync(accesToken, {
                        attributeId,
                      });

                      await checkSync.checkAttributeSync(accesToken, {
                        attributeId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync del atributo ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync del atributo ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return {
                    data: {
                      id: response.data.attribute[0].id,
                      name: response.data.attribute[0].name,
                    },
                  };
                }}
                unifyItems={(fatherId, copiesIds) => {
                  apiAttributes.unify(accesToken, fatherId, copiesIds);
                }}
              />
            );
          })()
        : undefined}
      {attributeId
        ? (() => {
            let targetAttributeList = attributes;
            let setTargetAttributes = setAttributes;

            if (infoAttributes.find((attr) => attr.id === attributeId)) {
              targetAttributeList = infoAttributes;
              setTargetAttributes = setInfoAttributes;
            }

            return (
              <EditAttribute
                id={attributeId}
                modal
                onLoad={() => {
                  return apiAttributes
                    .filter(accesToken, {
                      filtername: "id",
                      value: attributeId,
                    })
                    .then((response) => {
                      return {
                        name: response.data[0].name,
                        description: response.data[0].description,
                        type: response.data[0].attribute_type,
                      };
                    });
                }}
                onSave={async (payload) => {
                  return apiAttributes.edit(accesToken, {
                    id: attributeId,
                    ...payload,
                  });
                }}
                onSync={async () => {
                  return apiAttributes.sync(accesToken, {
                    attributeId,
                  });
                }}
                onCheckSync={async (setIsSyncing) => {
                  return checkSync.checkAttributeSync(accesToken, {
                    attributeId,
                    setIsSyncing,
                  });
                }}
                beforeClose={(updatedAttribute) => {
                  const updatedAttributes = targetAttributeList.map(
                    (attribute) => {
                      if (attribute.id === attributeId) {
                        return {
                          ...attribute,
                          id: attributeId,
                          name: updatedAttribute.name,
                        };
                      }

                      return attribute;
                    }
                  );

                  setTargetAttributes(updatedAttributes);
                }}
                loadSyncCheck={async () => {
                  const response = await apiAttributes.checkSync(accesToken, {
                    attributeId,
                  });

                  return response.data.attribute[0].sync;
                }}
                close={() => {
                  setAttributeId(undefined);
                }}
              />
            );
          })()
        : undefined}
      {valueAttributeId
        ? (() => {
            const attributeId = valueAttributeId;
            let targetAttributeList = attributes;
            let setTargetAttributes = setAttributes;

            if (infoAttributes.find((attr) => attr.id === attributeId)) {
              targetAttributeList = infoAttributes;
              setTargetAttributes = setInfoAttributes;
            }

            let selectedItems = [];

            if (targetAttributeList.length > 0) {
              let attribute = targetAttributeList.find(
                (attribute) => attribute.id === Number(valueAttributeId)
              );

              selectedItems = attribute.values.map((val) => val.id);
            }

            return (
              <AdministerCopies
                addButtonDescription="Añadir nuevo valor de atributo"
                buttonDescription="hide"
                title="Cambiar valor de atributo"
                addTableTitle="Atributo a añadir"
                copiesTableTitle="Categoría original"
                onSave={() => {}}
                loadItems={() =>
                  apiAttributes
                    .getValuesList(accesToken, {
                      filtername: "attributeId",
                      value: attributeId,
                    })
                    .then(({ data }) => {
                      return {
                        data: data.map((item) => ({
                          id: item.id,
                          name: item.value,
                        })),
                      };
                    })
                }
                onSaved={() => {}}
                close={() => setValueAttributeId(undefined)}
                selectedItems={selectedItems}
                applySelection={async (item) => {
                  let currentValueId;

                  const updatedAttributes = targetAttributeList.map((attr) => {
                    if (attr.id === Number(attributeId)) {
                      if (attr.values.length > 0) {
                        currentValueId = attr.values[0].id;
                      }

                      return {
                        ...attr,
                        values: [item],
                      };
                    }

                    return {
                      ...attr,
                      values: attr.values.map((val) => ({ ...val })),
                    };
                  });

                  if (currentValueId) {
                    await apiArticles.removeAttributeValue(accesToken, {
                      articleId,
                      attributeValueId: currentValueId,
                    });
                  }

                  await apiArticles.addAttributeValue(accesToken, {
                    articleId,
                    attributeValueId: item.id,
                  });

                  await apiArticles.syncPrices(accesToken, { articleId });

                  setValueAttributeId(undefined);
                  setTargetAttributes(updatedAttributes);
                  setValueAttributeId(undefined);
                }}
                createItem={async (value) => {
                  const response = await apiAttributes.addValue(accesToken, {
                    attributeId,
                    value,
                  });

                  const attributeValueId = response.data.id;
                  (async () => {
                    try {
                      await apiAttributes.syncValue(accesToken, {
                        attributeValueId,
                      });

                      await checkSync.checkAttributeValueSync(accesToken, {
                        attributeValueId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync del atributo ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync del atributo ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return response;
                }}
              />
            );
          })()
        : undefined}
      {editValueId
        ? (() => {
            const { valueId, attributeId } = editValueId;
            let targetAttributeList = attributes;
            let setTargetAttributes = setAttributes;

            if (infoAttributes.find((attr) => attr.id === attributeId)) {
              targetAttributeList = infoAttributes;
              setTargetAttributes = setInfoAttributes;
            }

            return (
              <EditAttributeValue
                title="Editar valor"
                modal
                onLoad={async () => {
                  const response = await apiAttributes.getValues(accesToken, {
                    filtername: "id",
                    value: valueId,
                  });

                  return response.data;
                }}
                onSave={({ color, value }) => {
                  return apiAttributes.editValue(accesToken, {
                    id: valueId,
                    value,
                    color,
                  });
                }}
                beforeClose={(value) => {
                  const updatedAttributes = targetAttributeList.map(
                    (attribute) => {
                      if (attribute.id !== attributeId) {
                        return attribute;
                      }

                      return {
                        ...attribute,
                        values: attribute.values.map((attrValue) => {
                          if (attrValue.id === Number(valueId)) {
                            return {
                              id: valueId,
                              name: value,
                            };
                          }

                          return attrValue;
                        }),
                      };
                    }
                  );

                  setTargetAttributes(updatedAttributes);
                }}
                onSync={async () => {
                  return apiAttributes.syncValue(accesToken, {
                    attributeValueId: valueId,
                  });
                }}
                onCheckSync={async (setIsSyncing) => {
                  return checkSync.checkAttributeValueSync(accesToken, {
                    attributeValueId: valueId,
                    setIsSyncing,
                  });
                }}
                close={() => setEditValueId(undefined)}
                loadSyncCheck={async () => {
                  const response = await apiAttributes.valueCheckSync(
                    accesToken,
                    {
                      attributeValueId: valueId,
                    }
                  );

                  return response.data.attributeValue[0].sync;
                }}
              />
            );
          })()
        : undefined}

      {/* DARIO */}
      {/* Changing literals modal here*/}
      {showChangeLiterals
        ? (() => {
            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Cambiar valor de literals"
                addTableTitle="Literals a añadir"
                copiesTableTitle="Literals original"
                loadItems={async () => {
                  const response = await apiLiterals.getAll(accesToken);
                  return {
                    data: response.data.map((item) => ({
                      id: item.id,
                      name: `${item.singular} / ${item.plural}`,
                    })),
                  };
                }}
                onSave={() => {}}
                selectedItems={[]}
                close={() => {
                  setShowChangeLiterals(false);
                  setLiteralMessageError(false);
                }}
                applySelection={async (literalsToAdd) => {
                  await apiLiterals
                    .filter(accesToken, {
                      filtername: "id",
                      id: literalsToAdd.id,
                    })
                    .then(({ data }) => {
                      setSingular(data[0].singular);
                      setPlural(data[0].plural);
                      setLiteralId(data[0].id);
                    });

                  setShowChangeLiterals(false);
                  setLiteralMessageError(false);

                  setHasChanged(true);
                }}
                literalsMessage={literalMessage}
                literalTooltip={true}
                // *CREATE ANOTHER LITERAL INSIDE OF THE CHANGELITERAL MODAL
                createItem={async (name) => {
                  if (name.indexOf("//") === -1) {
                    setLiteralMessage(true);
                    displayNotification({
                      title: `Falta la DOBLE barra divisora
                      Introduce los literals en el siguiente formato: XXXXXXXXX // YYYYYYY `,
                      message: `Para una correcta creación introduce los literals en el siguiente formato:  XXXXXXXXX // YYYYY`,

                      type: "danger",
                    });
                    return;
                  } else {
                    // const dividedData = name.split("//");
                    // const newSingular = dividedData[0];
                    // const newPlural = dividedData[1];

                    // * SPLIT THE INPUT WITH //, REMOVE BLANK SPACES WITH TRIM() & AFIRST LETTER UPPERCASE WITH TOUPPERCASE
                    const dividedData = name.split("//");
                    const preSingular = dividedData[0].trim();
                    const newSingular =
                      preSingular[0].toUpperCase() + preSingular.slice(1);
                    const prePlural = dividedData[1].trim();
                    const newPlural =
                      prePlural[0].toUpperCase() + prePlural.slice(1);

                    const response = await apiLiterals.newLiteral(
                      accesToken,
                      newSingular,
                      newPlural
                    );

                    return {
                      data: {
                        id: response.data.id,
                        name: name,
                      },
                    };
                  }
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {/* Editing literals modal here*/}
      {showLiterals ? (
        <Literals
          modal
          id={showLiterals}
          close={() => setShowLiterals(false)}
          beforeClose={({ singular, plural }) => {
            setHasChanged(true);
            // setLiteralMessageError(false);
            setSingular(singular);
            setPlural(plural);
          }}
        />
      ) : undefined}
    </>
  );

  const notificationErrorMessage = ({ title, key }) => {
    solveErrorMessage({
      title,
      onClickSolve: () => {
        setKey(key);
        if (window.screen.width < 500) {
          document.getElementById(`accordion-card-${key}`).scrollIntoView();
        }
      },
    });
  };

  const onClickSave = async () => {
    try {
      if (description.length <= 0) {
        setErrors(true);
        setShowSaveError(true);

        notificationErrorMessage({
          title: "No se puede guardar un artículo sin nombre",
          key: 0,
        });

        throw new Error();
      }

      setErrors(false);
      setShowSaveError(false);
      setIsSaving(true);
      setHasChanged(false);

      await apiArticles.edit(accesToken, {
        id,
        description,
        active,
        literalId,
      });
      console.log(
        "🚀 ~ file: Article.js ~ line 1721 ~ onClickSave ~ literalId",
         literalId
      );

      setHasSaved(true);
      setIsSaving(false);
      setMustSync(true);

      return true;
    } catch (e) {
      if (e === "DUPLICATE NAME") {
        notificationErrorMessage({
          title: "No se puede guardar una categoría sin nombre",
          key: 0,
        });

        setIsSaving(false);
        setShowSaveError(true);
        setDuplicateNameError(true);
        setIsSaving(false);
      }

      return false;
    }
  };

  const onClickSync = async () => {
    if (!activeSales) {
      setIsSyncing(false);
      displayNotification({
        title: "Falo en la sincronización",
        message: `El artículo está inactivo en compras, ponte en contacto con el departamento de compras para solucionarlo`,
        type: "danger",
      });
      return;
    }
    try {
      setIsSyncing(true);

      const hasSavedSuccesfully = await onClickSave();
      if (!hasSavedSuccesfully) {
        setIsSyncing(false);
        return;
      }

      if (!active) {
        throw new Error("NO_ACTIVE_NO_SYNC");
      }

      await apiArticles.sync(accesToken, { articleId: id });
      const checkSyncResponse = await checkSync.checkArticleSync(accesToken, {
        articleId: id,
      });

      if (checkSyncResponse) {
        setSyncError(false);
      } else if (!checkSyncResponse) {
        setSyncError(true);
      }

      await apiArticles.syncPrices(accesToken, { articleId });

      if (props.afterSync) {
        await props.afterSync();
      } else if (productId) {
        await apiProducts.sync(accesToken, {
          productId,
        });

        const checkResponse = await checkSync.checkProductSync(accesToken, {
          productId,
        });

        if (!checkResponse) {
          displayNotification({
            title: "No se ha podido sincronizar el producto asociado",
            message: "Compruebe el producto",
            type: "danger",
          });
        }
      }

      setMustSync(false);
      setIsSyncing(false);
    } catch (e) {
      if (e.message === "NO_ACTIVE_NO_SYNC") {
        displayNotification({
          title: "No se pueden sincronizar artículos no activos",
          message: "Para sincronizar este artículo necesita activarlo.",
          type: "danger",
        });
      } else {
        displayNotification({
          title: "Ha habido un error en la sync",
          message:
            "Ha ocurrido un error en la sincronización, prueba otra vez dentro de unos minutos, si el problema persiste ponte en contacto con el servicio técnico",
          type: "danger",
        });
      }

      setIsSyncing(false);
      setSyncError(true);
    }
  };

  if (!props.modal) {
    return (
      <>
        <Prompt
          when={hasChanged}
          message={({ pathname }) => {
            saveBeforeExitAlert(true).then(async (value) => {
              switch (value) {
                case "save":
                  try {
                    await onClickSave();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "saveSync":
                  try {
                    await onClickSave();
                    await onClickSync();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "exit":
                  swal.close();
                  break;

                default:
                  break;
              }
            });

            return true;
          }}
        />
        <SaveButton
          hidden={
            showChooseBrand ||
            showAddAttribute ||
            attributeId ||
            valueAttributeId ||
            editValueId
          }
          correctSync={correctSync}
          mustSave={hasChanged}
          processingSave={isSaving}
          syncError={syncError}
          onClickSave={async () => {
            try {
              await onClickSave();
            } catch (e) {}
          }}
          processingSync={isSyncing}
          mustSync={mustSync}
          onClickSync={onClickSync}
          error={(errors || duplicateNameError) && showSaveError}
          onClose={() => {
            setShowSaveError(false);
          }}
          errorMessage="Hay errores en el formulario, reviselo y corrija los errores antes de guardar"
          dontSync={!active}
        />
        {description !== LOADING ? (
          <MainTitle>
            <h1>
              Artículo: {description}{" "}
              {productId !== LOADING ? (
                <>
                  {" "}
                  <Button
                    onClick={() => {
                      props.history.push(`/product/${productId}`);
                    }}
                  >
                    Producto
                  </Button>
                  {/* !!!! boton para ir a prestashop */}
                  <Button
                    onClick={() => {
                      window.open(`${prestashopURL}`, "_blank");
                    }}
                    className="ml-5"
                  >
                    Ver en prestashop
                  </Button>
                </>
              ) : undefined}
            </h1>
          </MainTitle>
        ) : undefined}
        {content}
        {subRoutes}
      </>
    );
  }

  const modalClass =
    !showChooseBrand &&
    !showAddAttribute &&
    !attributeId &&
    !valueAttributeId &&
    !editValueId
      ? ""
      : "d-none";

  return (
    <>
      <Modal
        show={true}
        className={`${classes.modalArticle} ${modalClass} ${classes.modifyModal}`}
        size="lg"
        onHide={() => {
          if (hasSaved)
            props.beforeClose({
              id,
              description,
              active,
              activeSales,
              attributes,
            });

          if (props.beforeCloseNoSave) {
            props.beforeCloseNoSave({
              id,
              description,
              active,
              activeSales,
              attributes,
            });
          }

          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editar artículo: {description !== LOADING ? description : undefined}
            {/* !!!! boton para llevar a prestashop */}
            <Button
              onClick={() => {
                window.open(
                  // `https://www.updirecto.es/${reWrite}`,
                  `${prestashopURL}`,
                  "_blank"
                );
              }}
              className="ml-5"
            >
              Ver en prestashop
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <SaveButton
            modal
            correctSync={correctSync}
            mustSave={hasChanged}
            processingSave={isSaving}
            syncError={syncError}
            onClickSave={async () => {
              try {
                await onClickSave();
              } catch (e) {}
            }}
            processingSync={isSyncing}
            mustSync={mustSync}
            onClickSync={onClickSync}
            error={(errors || duplicateNameError) && showSaveError}
            onClose={() => {
              setShowSaveError(false);
            }}
            errorMessage="Hay errores en el formulario, reviselo y corrija los errores antes de guardar"
            dontSync={!active}
          />
        </Modal.Footer>
      </Modal>

      {subRoutes}
    </>
  );
};

export default Article;
