import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

import { PROCESSING, ERROR } from "../../../../../../utilities/processStates";

import apiProducts from "../../../../../../api/products";

import PdfFile from "../models/PdfFile";

import later from "../../../../../../utilities/later";

const useUploadFiles: (productId: number) => any = (productId: number) => {
  const [filesToUpload, setFilesToUpload] = useState<PdfFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [processFirstLoad, setProcessFirstLoad] = useState<string | undefined>(
    PROCESSING
  );
  const [processUploadPdf, setProcessUploadPdf] = useState<string>();
  const [processRemovePdf, setProcessRemovePdf] = useState<string>();
  const [processShowPdf, setProcessShowPdf] = useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiProducts.getPdfs(accesToken, { productId });

        const processedFiles = response.data.map((file: any) => {
          const size = Number(file.size.slice(0, -2));

          return new PdfFile(file.name, size);
        });

        setFilesToUpload(processedFiles);
        setProcessFirstLoad(undefined);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleDrop = useCallback(async (uploadedFiles: File[]) => {
    setProcessUploadPdf(PROCESSING);
    setErrorMessage(undefined);

    try {
      for (const file of uploadedFiles) {
        if (file.type !== "application/pdf") {
          setErrorMessage(
            "Algunos archivos no se han subido pq no son pdfs, asegurese que todos los arhivos que suba lo són"
          );

          continue;
        }

        const data = new FormData();
        data.append("productId", String(productId));
        data.append("pdf", file);

        await apiProducts.uploadPdf(accesToken, { data });
      }

      const processedFiles: PdfFile[] = uploadedFiles.map((file) => {
        return new PdfFile(file.name, file.size);
      });

      setFilesToUpload((prevFiles) => {
        return [...prevFiles, ...processedFiles];
      });
    } catch (error) {
      console.log(error);
    }

    setProcessUploadPdf(undefined);
  }, []);

  const handleClickRemoveFile = async (position: number) => {
    setProcessRemovePdf(PROCESSING);

    try {
      await apiProducts.removePdf(accesToken, {
        productId,
        fileName: filesToUpload[position].name,
      });
    } catch (error) {
      console.log(error);
    }

    setFilesToUpload((prevFiles) => {
      const updatedFiles = [...prevFiles];

      updatedFiles.splice(position, 1);

      return updatedFiles;
    });
    setProcessRemovePdf(undefined);
  };

  const handleOpenPdf = async (position: number) => {
    setProcessShowPdf(PROCESSING);

    try {
      const response = await apiProducts.openPdf(accesToken, {
        productId,
        fileName: filesToUpload[position].name,
      });

      const file = new Blob([response.data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);
    }

    setProcessShowPdf(undefined);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const isUploadingPdf = processUploadPdf === PROCESSING;
  const isRemovingPdf = processRemovePdf === PROCESSING;
  const isOpeningPdf = processShowPdf === PROCESSING;
  const isProcessingFirstLoad = processFirstLoad === PROCESSING;

  const isProcessingSomething = isUploadingPdf || isRemovingPdf || isOpeningPdf;

  const areErrors = errorMessage !== undefined;

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    filesToUpload,
    handleClickRemoveFile,
    isUploadingPdf,
    areErrors,
    errorMessage,
    handleOpenPdf,
    isProcessingSomething,
    isProcessingFirstLoad,
  };
};

export default useUploadFiles;
