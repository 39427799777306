import { useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";

import apiProducts from "../../../../../../api/products";

interface Crop {
  width: number;
  height: number;
  x: number;
  y: number;
}

const extractImageFileExtensionFromBase64 = (base64Data) => {
  // Extract the file extension of imaage uploaded!
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64")
  );
};

const base64StringtoFile = (base64String: string, filename: string) => {
  // function to create the file from raw iamge data, you can upload the generated file onto your server!
  const arr = base64String.split(",");

  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const useCropImage = (
  productId: number,
  handleClose: () => void,
  handleFinish: () => void
) => {
  const [crop, setCrop] = useState<Crop>();

  const [isProcessing, setIsProcessing] = useState(false);

  const accesToken = useSelector((state: any) => state.user.access);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  const handleClickSave = async () => {
    setIsProcessing(true);
    try {
      if (crop.width === 0 || crop.height === 0) {
        const imageSrc = imgRef.current.src;
        const imageExtension = extractImageFileExtensionFromBase64(imageSrc);

        const imageFile = base64StringtoFile(
          imgRef.current.src,
          "image." + imageExtension
        );

        const data = new FormData();
        data.append("image", imageFile);
        data.append("productId", String(productId));

        await apiProducts.addPhoto(accesToken, data);
      } else {
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        const fileExt = extractImageFileExtensionFromBase64(imgRef.current.src);

        const croppedImg = previewCanvasRef.current.toDataURL(
          "image/" + fileExt
        );
        const fileName = "cropped." + fileExt;

        const croppedFile = base64StringtoFile(croppedImg, fileName);

        const data = new FormData();
        data.append("image", croppedFile);
        data.append("productId", String(productId));

        await apiProducts.addPhoto(accesToken, data);
      }

      handleClose();
      handleFinish();
    } catch (error) {
      console.log(error);
    }
    setIsProcessing(false);
  };

  return {
    crop,
    setCrop,
    handleClickSave,
    isProcessing,
    onLoad,
    previewCanvasRef,
  };
};

export default useCropImage;
