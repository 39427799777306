import React, { Fragment } from "react";
import {
  PencilSquare,
  X,
  Globe2,
  CheckSquareFill,
  XSquareFill,
} from "react-bootstrap-icons";
import { Button, Spinner } from "react-bootstrap";
import CheckSphere from "../CheckSphere/CheckSphere";
import SelectRoeToDelete from "../SelectRowToDelete/SelectRowToDelete";
import ButtonSpinner from "../../components/ButtonSpinner/ButtonSpinner";
import classes from "./ProductsTable.module.css";
import ReactTooltip from "react-tooltip";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ProductsTable = (props) => {
  const { disabled, processing } = props;

  return (
    <>
      <ReactTooltip delayShow={1000} place="top" type="info" />
      <div>
        <div className={`${classes.header} hideOnMobile`}>
          <div></div>
          <div>ID</div>
          <div>Name</div>
          <div>Activo</div>
          <div>Sincronizado</div>
          <div>Opciones</div>
        </div>
        <DragDropContext onDragEnd={props.handleDragEnd}>
          <Droppable droppableId="productsTable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.products.map((product, index) => {
                  let optionsContent;

                  if (
                    props.processing &&
                    (product.processing || product.remove)
                  ) {
                    optionsContent = (
                      <div className={classes.spinnerContainer}>
                        <ButtonSpinner />
                      </div>
                    );
                  } else {
                    optionsContent = (
                      <Fragment>
                        <Button
                          variant="light"
                          onClick={() => props.onEdit(product.id)}
                          disabled={props.processing}
                          data-tip="Editar producto"
                        >
                          <PencilSquare />
                        </Button>
                        <Button
                          variant="light"
                          disabled={props.processing}
                          data-tip="Ver producto en la web"
                          onClick={() => {
                            window.open(`${product.url}`, "_blank");
                          }}
                        >
                          <Globe2 />
                        </Button>
                        <Button
                          variant="light"
                          onClick={() => props.x(product.id)}
                          disabled={props.processing}
                          data-tip="Desvincular artículo"
                        >
                          <X />
                        </Button>
                      </Fragment>
                    );
                  }

                  let syncButton;
                  if (product.sync) {
                    syncButton = (
                      <button
                        className={classes.syncStatus}
                        onClick={() => props.onClickSync(product.id)}
                      >
                        <CheckSquareFill className={classes.success} />
                      </button>
                    );
                  } else if (product.sync === undefined) {
                    syncButton = (
                      <button className={classes.syncSpinner}>
                        <Spinner
                          as="svg"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    );
                  } else if (product.sync === false) {
                    syncButton = (
                      <button
                        className={classes.syncStatus}
                        onClick={() => props.onClickSync(product.id)}
                      >
                        <XSquareFill className={classes.error} />
                      </button>
                    );
                  }

                  return (
                    <Draggable
                      key={product.id}
                      draggableId={String(product.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={product.id}
                          className={`
                          ${classes.tableRow}
                          ${product.remove ? "table-danger" : ""}
                          ${disabled ? classes.disabled : ""}
                        `}
                        >
                          <div>
                            <SelectRoeToDelete
                              onClick={
                                !disabled && !processing
                                  ? () =>
                                      props.selectUnselectToRemove(product.id)
                                  : () => {}
                              }
                              active={product.remove}
                            />
                          </div>
                          <div className="hideOnMobile">{product.id}</div>
                          <div>
                            <p>{product.name}</p>
                          </div>
                          <div className={classes.badgeContainer}>
                            <CheckSphere
                              check={product.active}
                              x={!product.active}
                            />
                          </div>
                          <div>{syncButton}</div>
                          <div className={classes.optionsContainer}>
                            {optionsContent}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default ProductsTable;
