import { useState, useEffect } from "react";

const useAttributesConflictAccordeon = () => {
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    setActiveKey("");
  }, []);

  const handleClickTab = (tabKey: string) => {
    if (activeKey === tabKey) {
      setActiveKey("");
    } else {
      setActiveKey(tabKey);
    }
  };

  return { activeKey, handleClickTab };
};

export default useAttributesConflictAccordeon;
