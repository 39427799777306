import React, { useState, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import SaveButton from "../../../components/SaveButton/SaveButton";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextInput from "../../../components/TextInput/TextInput";
import displayNotification from "../../../utilities/displayNotification";
import classes from "./EditValue.module.css";

const LOADING = "LOADING";
// const ERROR = "ERROR";
const PROCESSING = "PROCESSING";

const EditValue = (props) => {
  const [name, setName] = useState(LOADING);
  const [psId, setPsId] = useState();
  const [formProcessPhase, setFormProcessPhase] = useState();

  const [mustSync, setMustSync] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const [errors, setErrors] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [correctSync, setCorrectSync] = useState(false);

  const [exitPath, setExitPath] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
  }, [exitPath]);

  useEffect(() => {
    props.onLoad().then(async ({ name, psId }) => {
      setName(name);
      setPsId(psId);
    });

    (async () => {
      try {
        const firstCheckSync = await props.loadSyncCheck();

        if (firstCheckSync.synchronizing) {
          setIsSyncing(true);
        }

        const checkResponse = await props.onCheckSync();

        console.log("CHECK RESPONSE", checkResponse);

        if (checkResponse) {
          setMustSync(false);
          setCorrectSync(true);
        } else {
          setMustSync(true);
        }

        setSyncError(false);
        setIsSyncing(false);
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => {
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await onClickSave();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await onClickSave();
                await onClickSync();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });

        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  }, [hasChanged]);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (name && hasChanged) {
        setIsSaving(true);

        await props.onSave(name);

        setHasSaved(true);
        setMustSync(true);
        setHasChanged(false);
        setIsSaving(false);
      }
    }, 5000);

    return () => clearTimeout(saveTimer);
  }, [hasChanged, name]);

  let content = <Spinner />;

  let nameInputErrorMessage =
    "Debe especificar un nombre para poder cambiar el valor";

  if (duplicateNameError) {
    nameInputErrorMessage =
      "Este nombre de valor ya existe, no puede haber 2 iguales";
  }

  if (name !== LOADING) {
    content = (
      <>
        {!props.modal ? <h2 className="mt-4">{name}</h2> : undefined}
        <Alert variant="warning">
          Cambiar este valor afectará todos los items vinculados
        </Alert>
        <span className={classes.prestashopId}>
          PS ID: {psId}
        </span>
        <DataTitle>Nombre:</DataTitle>
        <TextInput
          defaultValue={name}
          onKeyUp={(event) => {
            setName(event.target.value);
            setHasChanged(true);
            setDuplicateNameError(false);
          }}
          error={name.length <= 0 || duplicateNameError}
          errorMessage={nameInputErrorMessage}
          disabled={formProcessPhase === PROCESSING}
        />
      </>
    );
  }

  const onClickSync = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await onClickSave();
      if (!hasSavedSuccesFully) {
        setIsSyncing(false);
        return;
      }

      const checkResponse = await props.onSync();
      await props.onCheckSync();

      if (checkResponse) {
        setMustSync(false);
      } else {
        setMustSync(true);
      }

      setIsSyncing(false);
      setSyncError(false);
      setMustSync(false);
    } catch (e) {
      displayNotification({
        title: "Ha habido un error en la sync",
        message:
          "Vuelve a intentarlo y si el error persiste ponte en contacto con el departamento técnico",
        type: "danger",
      });

      setSyncError(true);
      setIsSyncing(false);
    }
  };

  const onClickSave = async () => {
    try {
      if (name.length <= 0) {
        setErrors(true);
        setShowErrorMessage(true);
        throw new Error();
      }

      setIsSaving(true);
      setErrors(false);

      await props.onSave(name);

      setHasChanged(false);
      setHasSaved(true);
      setMustSync(true);
      setIsSaving(false);
      return true;
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setDuplicateNameError(true);
        setShowErrorMessage(true);
        setIsSaving(false);
      }
      setErrors(true);
      return false;
    }
  };

  if (props.modal) {
    return (
      <Modal
        className={classes.modifyModal}
        show={true}
        size="lg"
        onHide={() => {
          if (hasSaved) props.beforeClose(name);
          props.close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title}: {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <SaveButton
            modal
            correctSync={correctSync}
            path={props.path}
            mustSave={hasChanged}
            processingSave={isSaving}
            onClickSave={onClickSave}
            processingSync={isSyncing}
            syncError={syncError}
            mustSync={mustSync}
            onClickSync={onClickSync}
            error={(errors || duplicateNameError) && showErrorMessage}
            onClose={() => setShowErrorMessage(false)}
            errorMessage="El formulario tiene campos incorrectos, reviselos y vuelva a guardar"
          />
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <>
        <Prompt
          when={hasChanged && !exitPath}
          message={({ pathname }) => {
            saveBeforeExitAlert(true).then(async (value) => {
              switch (value) {
                case "save":
                  try {
                    await onClickSave();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "saveSync":
                  try {
                    await onClickSave();
                    await onClickSync();
                    swal.close();
                    props.history.push(pathname);
                  } catch (e) {
                    swal.close();
                  }
                  break;

                case "exit":
                  setExitPath(pathname);
                  swal.close();
                  break;

                default:
                  break;
              }
            });

            return false;
          }}
        />
        <SaveButton
          correctSync={correctSync}
          path={props.path}
          mustSave={hasChanged}
          processingSave={isSaving}
          onClickSave={onClickSave}
          processingSync={isSyncing}
          syncError={syncError}
          mustSync={mustSync}
          onClickSync={onClickSync}
          error={(errors || duplicateNameError) && showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
          errorMessage="El formulario tiene campos incorrectos, reviselos y vuelva a guardar"
        />
        {content}
      </>
    );
  }
};

export default EditValue;
