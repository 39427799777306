import apiProducts from "../api/products";
import displayNotification from "./displayNotification";

const syncPricesPromise = async (accesToken, { productId }) => {
  try {
    await apiProducts.syncPrices(accesToken, { productId });
  } catch (error) {
    if (error.response && error.response.status === 408) {
      displayNotification({
        title: "Ha habido un error en prestashop",
        message:
          "No se ha podido sincronizar el precio en la base de datos por culpa de un error de prestashop",
        type: "error",
        dismiss: true,
      });
    } else {
      displayNotification({
        title: "Ha habido un error al sincronizar los precios",
        message:
          "Intenelo de nuevo mas tarde y conacte con asistencia tecnica si el problema persiste",
        type: "error",
      });
    }
  }
};

export default syncPricesPromise;
