import React from "react";

import CategoriesList from "../CategoriesList/CategoriesList";
import Category from "../Category";

import useSubCategories from "./hooks/use-useSubcategories";

import classes from "./CategorySubcategories.module.scss";
import { Button } from "react-bootstrap";

const CategorySubcategories: React.FC<{
  categories: any[];
  handleDropCategory: (result: any) => void;
  handleUpdateEditedCategory: (editecCategory: any) => void;
  handleClickSyncCategory: (index: number) => void;
  // TODO
  handleCategoriesPrestashopSync
  syncProccess:boolean
}> = ({
  categories,
  handleDropCategory,
  handleUpdateEditedCategory,
  handleClickSyncCategory,
  // TODO
  handleCategoriesPrestashopSync,
  syncProccess
}) => {
  const {
    categoryIdToEdit,
    showEditCategoryModal,
    handleClickEditCategory,
    handleCloseEditCategoryModal,
  } = useSubCategories(categories);

  return (
    <>
    {/* Categorias: */}
      {/* <div className={classes.categoriesHeader}>
        <h3>Categorías:</h3>
        <Button
        variant="primary"> Ordenar en Prestashop</Button>
      </div> */}
      <CategoriesList
        categories={categories}
        handleDropCategory={handleDropCategory}
        handleClickEditCategory={handleClickEditCategory}
        handleClickSyncCategory={handleClickSyncCategory}
        handleCategoriesPrestashopSync={handleCategoriesPrestashopSync}
        syncProccess={syncProccess}
      />
      {showEditCategoryModal ? (
        <Category
          modal
          id={categoryIdToEdit}
          onHide={handleCloseEditCategoryModal}
          beforeClose={handleUpdateEditedCategory}
        />
      ) : undefined}
    </>
  );
};

export default CategorySubcategories;
