import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// BOOTSTRAP
import { Button, Container, Table } from "react-bootstrap";
import { PencilSquare, ArrowUpSquareFill } from "react-bootstrap-icons";
// STYLES
import classes from "./ArticlesList.module.css";
// API
import apiArticles from "../../../api/articles";
// UTILITIES
import handleApiErrors from "../../../utilities/handleApiErrors";
// COMPONENTS
import TextInput from "../../../components/TextInput/TextInput";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import Spinner from "../../../components/Spinner/Spinner";
import PseudoTable from "../../../components/PseudoTable/PseudoTable";

import PaginationItem from "../../../components/PaginationItem/PaginationItem";
import stringSimilarity from "string-similarity";
import useInfiniteScroll from "../../../utilities/useInfiniteScroll";

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};
const resultsNumber = 20;
const ArticlesList = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // INPUT
  const [inputValue, setInputValue] = useState("");
  // ARRAYS
  const [allArticles, setAllArticles] = useState([]);
  const [allArticlesUntouched, setAllArticlesUntouched] = useState([]);
  const [articlesFiltered, setArticlesFiltered] = useState([]);
  // PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  // INFINITE SCROLL
  const [results, setResults] = useState(20);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  // BRING DATA FROM API
  useEffect(() => {
    apiArticles
      .listAll(accesToken)
      .then(({ data }) => {
        const items = data.map((item) => ({
          id: item.id,
          ref: item.ref,
          name: item.description ?? "",
          active: item.active,
          activeSales: item.active_sales,
        }));

        setAllArticles(items);
        setAllArticlesUntouched(items);
        setProcess(states.LOADED);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, []);
  // * FUNCTION FOR THE SCROLL INFINITE
  function fetchMoreListItems() {
    setTimeout(() => {
      if (results + 20 > allArticles.length) {
        setResults(allArticles.length);
      } else {
        setResults(results + 20);
      }
      setIsFetching(false);
    }, 500);
  }
  // EDIT ARTICLE
  const editArticle = (pId) => {
    props.history.push(`/article/${pId}`);
  };

  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };
  // * LOGIC FROM SIMILARITY ALGORITHMN
  useEffect(() => {
    // setAllArticles(allArticlesUntouched);

    // Only works from the second letter on
    const queryedItems = allArticles.map((item) => {
      const scoredItem = {
        ...item,
        score: stringSimilarity.compareTwoStrings(
          inputValue.toLocaleLowerCase(),
          item.name.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });
    const arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (
        product.name
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase()) ||
        product.ref.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
      ) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });

    // When there are 3 letters or more then it shows the flitered/ordered array, if not the general array
    inputValue.length > 2
      ? setAllArticles([
          ...exactItems,
          ...similarItems.filter((item) => item.score > 0.1),
        ])
      : setAllArticles(allArticlesUntouched);
  }, [inputValue]);

  //  * LOGIC FOR RESPONSIVE, IF MATCH WITH THE MOBILE SCREEN THE RESULTS PER PAGE ADD 20 RESULTS AVERY TIME SCROLL TO THE END OF THE VIEWPORT
  let breakPointResponsive = window.matchMedia("(max-width: 65em)");
  let bodyContent;
  function responsiveBreakMatch(breakPointResponsive) {
    // If media query matches, the type of results change to the state
    bodyContent =
      splitArrays(
        allArticles,
        breakPointResponsive.matches ? results : resultsNumber
      )[pageNumber] === undefined
        ? null
        : splitArrays(
            allArticles,
            breakPointResponsive.matches ? results : resultsNumber
          )[pageNumber].map((item, index) => {
            return (
              <>
                {/* <ReactTooltip place="left" type="info" /> */}

                <PseudoTable.Row key={item.id} className={classes.row}>
                  <div>{item.id}</div>
                  <div>{item.ref}</div>
                  <div>
                    <p>{item.name}</p>
                  </div>
                  <div>
                    <CheckSphere
                      check={item.active && item.activeSales}
                      x={!item.active || !item.activeSales}
                    />
                  </div>
                  <div>
                    <Button
                      variant="light"
                      onClick={() => editArticle(item.id)}
                    >
                      <PencilSquare />
                    </Button>
                  </div>
                </PseudoTable.Row>
                {breakPointResponsive.matches && allArticles.length > 20 ? (
                  results <= 20 ? null : (
                    <div className={classes.arrowContainer}>
                      <ArrowUpSquareFill
                        // data-tip="Volver al inicio"
                        className={classes.arrowUp}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )
                ) : null}
              </>
            );
          });
  }
  responsiveBreakMatch(breakPointResponsive);

  return (
    <>
      {/* TITLE */}
      <Container className={classes.title}>
        <h1>Lista de artículos</h1>
      </Container>

      <Container className={classes.input}>
        <TextInput
          placeholder="Introduce el nombre del artículo"
          onChange={(event) => {
            setInputValue(event.target.value);
            setPageNumber(0);
          }}
          value={inputValue}
        />
      </Container>

      {/* TABLE */}
      <Container>
        {process === "LOADING" ? (
          <Spinner
            className="mt-5"
            as="svg"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {/* // PAGINATION */}
            {allArticles.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la pcion ni de error
                arrayWhole={allArticles}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF PAGINATION */}

            <PseudoTable.Table>
              <PseudoTable.Header className={classes.header}>
                <div>ID</div>
                <div>REF</div>
                <div>NOMBRE</div>
                <div>ACTIVO</div>
                <div>EDITAR</div>
              </PseudoTable.Header>
              {bodyContent}
            </PseudoTable.Table>
            {/* //* SPINNER IN MOBILE WHEN LOADING MORE ITEMS */}
            {isFetching ? (
              <div className={classes.hideSpinnerDesktop}>
                <Spinner
                  as="svg"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null}

            {/* // BOTTOM PAGINATION */}
            {allArticles.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allArticles}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF  BOTTOM PAGINATION */}
          </>
        )}
      </Container>
    </>
  );
};

export default ArticlesList;
