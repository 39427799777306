import React, { useState } from "react";
import checkSync from "../../../../utilities/checkSync";
import { useSelector } from "react-redux";
import apiProducts from "../../../../api/products";
import apiFeatures from "../../../../api/features";
import apiBrands from "../../../../api/brands";
import apiLiterals from "../../../../api/literals";
import apiCategories from "../../../../api/categories";
import { Col, Spinner, Alert, Form } from "react-bootstrap";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import DataItem from "../../../../components/DataItem/DataItem";
import DataTitle from "../../../../components/DataTitle/DataTitle";
import TextInput from "../../../../components/TextInput/TextInput";
import AddButton from "../../../../components/AddButton/AddButton";
import CheckSphere from "../../../../components/CheckSphere/CheckSphere";
import ValuesListList from "../../../../components/ValuesListList/ValuesListList";
import OptionsButton from "../../../../components/OptionsButton/OptionsButton";
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import TabContainer from "../../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../../components/TabMainRow/TabMainRow";
import AdministerCopies from "../../../AdministerCopies/AdministerCopies";
import Category from "../../Category/Category";
import Feature from "../../Feature/Feature";
import FeatureValue from "../../FeatureValue/FeatureValue";
import { QuestionCircle } from "react-bootstrap-icons";
import displayNotification from "../../../../utilities/displayNotification";
import handleApiErrors from "../../../../utilities/handleApiErrors";
import classes from "./ProductMainDetails.module.css";

import swal from "sweetalert";

import AdministerPdfsMenu from "./AdministerPdfsMenu/AdministerPdfsMenu";
import AdministerCertificates from "../Product/AdministerCertificates/AdministerCertificates";

const LOADING = "LOADING";
const PROCESSING = "PROCESSING";

const Product = (props) => {
  const {
    id,
    productId,
    idErp,
    setHasChanged,
    setHideFloatingButtons,
    saveProductProcessPhase,
    setName,
    name,
    active,
    setActive,
    duplicateName,
    setDuplicateName,
    showPrice,
    onRequest,
    singular,
    setSingular,
    plural,
    setPlural,
    setShowLiterals,
    setShowBrand,
    brand,
    setBrand,
    literalId,
    setLiteralId,
    description,
    setDescription,
    shortDescription,
    setShortDescription,
    mainCategory,
    characteristics,
    setCharacteristics,
    categories,
    setCategories,
    specialCharactersName,
    setSpecialCharactersName,
    removeFeatureProcessing,
    setRemoveFeatureProcessing,
    literalMessage,
    setLiteralMessage,
    showChangeBrand,
    setShowChangeBrand,
    setShowCategoryInfo,
    showAddFeatures,
    setShowAddFeatures,
    showFeatureValueEdit,
    setShowFeatureValueEdit,
    showEditFeature,
    setShowEditFeature,
    showChangeLiterals,
    setShowChangeLiterals,
    featuresValuesMenu,
    setFeaturesValuesMenu,
    showChangeMainCategory,
    setShowChangeMainCategory,
    showAddCategories,
    setShowAddCategories,
    showChangeSoloFeatureValue,
    setShowChangeSoloFeatureValue,
    handleChangeMainCategory,
    handleClickShowPrice,
    handleClickUnderRequest,
    validation,
    errorMessages,
    handleOpenCreateBrand,
    saveProduct,
    psId,
  } = props;

  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;

  const accesToken = useSelector((state) => state.user.access);

  const removeFeature = async (featureId) => {
    const updatedFeatures = characteristics.map((feature) => {
      if (feature.id === id) {
        return {
          ...feature,
          selected: true,
        };
      }

      return {
        ...feature,
      };
    });

    setCharacteristics(updatedFeatures);

    setRemoveFeatureProcessing(true);

    try {
      await apiProducts.removeFeatures(accesToken, {
        productIds: [Number(productId)],
        featureIds: [featureId],
      });

      const updatedFeatures = characteristics.filter(
        (feature) => feature.id !== featureId
      );

      setCharacteristics(updatedFeatures);
      setRemoveFeatureProcessing(false);
    } catch (error) {
      setCharacteristics((features) =>
        features.map((ft) => ({ ...ft, processing: false, selected: false }))
      );
      setRemoveFeatureProcessing(false);

      if (error.response && error.response.status === 409) {
        swal({
          title: "No se puede desvincular esta característica",
          text: "Esta característica esta en los filtros de las categorías vinculadas a este producto, para poder eliminarla tienes que quitarla de los filtros de dicha categoría primero",
          icon: "warning",
          buttons: {
            cerrar: {
              text: "Entendido",
            },
          },
        });
      }
    }
  };

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateName) {
    nameErrorMessage =
      "El nombre está duplicado, introduzca uno que no exista ya";
  }

  const removeCategory = (id) => {
    apiProducts
      .removeCategories(accesToken, {
        productIds: [Number(productId)],
        categoryIds: [id],
      })
      .then((response) => {
        const updatedCategories = categories.filter(
          (category) => category.id !== id
        );

        setCategories(updatedCategories);
      })
      .catch((e) => {
        handleApiErrors(e);
      });

    const updatedCategories = categories.map((category) => {
      if (category.id === id) {
        category.loading = true;
      }

      return category;
    });

    setCategories(updatedCategories);
  };

  let categoriesContent;
  if (categories === LOADING) {
    categoriesContent = <Spinner />;
  } else if (categories.length > 0) {
    const isProcessing = categories.find((cat) => cat.loading);

    categoriesContent = categories.map((category) => {
      return (
        <OptionsButton
          key={category.id}
          pencil={() => {
            setShowCategoryInfo(category.id);
            setHideFloatingButtons(true);
          }}
          variant={!category.active ? "light" : null}
          disabled={
            (isProcessing && !category.loading) ||
            saveProductProcessPhase === PROCESSING
          }
          loading={category.loading}
          x={() => {
            setHasChanged(true);
            removeCategory(category.id);
          }}
          noX={mainCategory.id === category.id}
        >
          {category.name}
        </OptionsButton>
      );
    });
  } else {
    categoriesContent = (
      <Alert variant="danger">
        No hay ninguna categoría, recuerde elegir una antes de guardar
      </Alert>
    );
  }

  let characteristicsContent;
  if (characteristics === LOADING) {
    characteristicsContent = <Spinner />;
  } else {
    characteristicsContent = (
      <div className={classes.marginBottom}>
        <TitleContainer>
          <h3>Características</h3>

          <div>
            {characteristics.find((item) => item.remove) ? (
              <OptionsButton
                variant="danger"
                x={() => {}}
                click={() => {
                  setRemoveFeatureProcessing(true);

                  const featuresToRemove = characteristics.filter(
                    (item) => item.remove
                  );

                  apiProducts
                    .removeFeatures(accesToken, {
                      productIds: [Number(productId)],
                      featureIds: featuresToRemove.map((item) => item.id),
                    })
                    .then(() => {
                      const updatedFeatures = characteristics.filter(
                        (feature) => !feature.remove
                      );

                      setCharacteristics(updatedFeatures);
                      setRemoveFeatureProcessing(false);
                    });
                }}
                disabled={
                  saveProductProcessPhase === PROCESSING ||
                  removeFeatureProcessing
                }
                loading={
                  saveProductProcessPhase === PROCESSING ||
                  removeFeatureProcessing
                }
              >
                Desvincular características
              </OptionsButton>
            ) : undefined}

            <OptionsButton
              variant="success"
              plus={() => {}}
              click={() => {
                setShowAddFeatures(true);
                setHideFloatingButtons(true);
              }}
              disabled={
                saveProductProcessPhase === PROCESSING ||
                removeFeatureProcessing
              }
              loading={
                saveProductProcessPhase === PROCESSING ||
                removeFeatureProcessing
              }
            >
              Añadir características
            </OptionsButton>
          </div>
        </TitleContainer>
        {characteristics.length > 0 ? (
          <ValuesListList
            itemTitle="Característica"
            valueTitle="Valores de característica"
            onClickAddValues={(valueId) => {
              setFeaturesValuesMenu(valueId);
              setHideFloatingButtons(true);
            }}
            onClickChevronExpand={(featureId) => {
              setShowChangeSoloFeatureValue(featureId);
              setHideFloatingButtons(true);
            }}
            onSelectUnselectRow={(id) => {
              const updatedCharacteristics = characteristics.map(
                (characteristic) => {
                  if (characteristic.id === id) {
                    return {
                      ...characteristic,
                      remove: !characteristic.remove,
                    };
                  }

                  return characteristic;
                }
              );

              setCharacteristics(updatedCharacteristics);
            }}
            onClickUnlinkValue={async (featureId, valueId) => {
              setRemoveFeatureProcessing(true);

              await apiProducts.removeFeatureValue(accesToken, {
                productId,
                valueId: valueId,
              });

              const updatedFeatures = characteristics.map((feature) => {
                if (feature.id === featureId) {
                  feature.values = feature.values.filter(
                    (value) => value.id !== valueId
                  );
                }

                return feature;
              });
              setCharacteristics(updatedFeatures);
              setRemoveFeatureProcessing(false);
            }}
            items={characteristics}
            keyEdit={(featureId) => {
              setShowEditFeature(featureId);
              setHideFloatingButtons(true);
            }}
            valueClickExpand={(featureValueId) => {
              props.history.push(
                `/product/${productId}/feature-value/${featureValueId}`
              );
            }}
            valueEdit={(_, id) => {
              setShowFeatureValueEdit(id);
              setHideFloatingButtons(true);
            }}
            processing={removeFeatureProcessing}
            removeItem={removeFeature}
          />
        ) : (
          <Alert variant="info">No hay características</Alert>
        )}
      </div>
    );
  }

  return (
    <>
      <TabContainer>
        {/* Descripción */}
        <TabMainRow>
          {!validation.showPriceUnderRequest ? (
            <Col md={12}>
              <Form.Control.Feedback
                type="invalid"
                className={classes.secondErrorMessage}
              >
                {errorMessages.showPriceUnderRequest}
              </Form.Control.Feedback>
            </Col>
          ) : undefined}
          <DataItem md={2}>
            <b>ID:</b> {id}
          </DataItem>
          <DataItem md={6}>
            <b>ID PS:</b> {psId}
          </DataItem>
          <DataItem md={2}>
            <CheckSphere
              check={active}
              x={!active}
              onClick={() => {
                setHasChanged(true);
                setActive(!active);
              }}
              disabled={saveProductProcessPhase === PROCESSING}
            >
              Activo
            </CheckSphere>
          </DataItem>
          <Col md={8}>
            <DataTitle>Nombre:</DataTitle>
            <TextInput
              defaultValue={name}
              onKeyUp={(event) => {
                if (event.target.value !== name) {
                  setSpecialCharactersName(regExpURL2.test(event.target.value)); // RETURNS FALSE IF IT MATCHES ANY OF THE CHARACTERS IN THE REGEX
                  setName(event.target.value);
                  setHasChanged(true);
                  setDuplicateName(false);
                }
              }}
              disabled={saveProductProcessPhase === PROCESSING}
              error={
                name.length <= 0 || duplicateName || !specialCharactersName
              }
              errorMessage={nameErrorMessage}
            />
          </Col>
          <DataItem>
            <CheckSphere
              check={showPrice}
              x={!showPrice}
              onClick={handleClickShowPrice}
              disabled={saveProductProcessPhase === PROCESSING}
            >
              Ver precio
            </CheckSphere>
          </DataItem>

          <Col md={8} className="d-flex justify-content-between">
            {/* //! Le pongo la clase .none para que no se vea en el frontend */}
            <div className={classes.none}>
              <DataTitle className={classes.title}>
                Literals:
                <QuestionCircle
                  className="ml-2 mb-1"
                  data-tip="Define el singular y el plurar de la unidad de venta"
                />
              </DataTitle>
              <OptionsButton
                variant={!singular || !plural ? "danger" : "primary"}
                chevronExpand={() => {
                  // * CHANGING LITERALS MODAL
                  setShowChangeLiterals(true);
                  setHideFloatingButtons(true);
                }}
                click={() => {}}
                noX
                pencil={() => {
                  // * EDIT LITERALS MODAL
                  setShowLiterals(literalId);
                }}
                disabled={saveProductProcessPhase === PROCESSING}
              >
                {singular} / {plural}
              </OptionsButton>
            </div>
            {/* //!------------------------------------------------------------------- */}

            <AdministerPdfsMenu productId={productId} />
            <AdministerCertificates productId={productId} />

          </Col>

          <DataItem>
            <CheckSphere
              check={onRequest}
              x={!onRequest}
              onClick={handleClickUnderRequest}
              disabled={saveProductProcessPhase === PROCESSING}
            >
              Bajo pedido
            </CheckSphere>
          </DataItem>
          {/* //! Lo mismo que arriba, display none para que no aparezca en el frontend */}
          <Col className={classes.none} md={8}>
            {/* Literals button error message*/}
            {!singular || !plural ? (
              <span className={classes.errorMessage}>
                {/* "Necesita introducir un literal para guardar el producto" */}
                "Elige el tipo de unidad (singular y plural) para la venta"
              </span>
            ) : null}
          </Col>
          {/* //! --------------------------------------------------------------------------*/}
          <Col md={8} className="d-flex justify-content-between">
            <div>
              <DataTitle className={classes.title}>Marca:</DataTitle>
              <OptionsButton
                variant={!brand.name ? "danger" : "primary"}
                chevronExpand={() => {
                  setHasChanged(true);
                  setShowChangeBrand(true);
                  setHideFloatingButtons(true);
                }}
                click={() => {}}
                noX
                pencil={() => {
                  setShowBrand(brand.id);
                }}
                disabled={saveProductProcessPhase === PROCESSING}
              >
                {brand.name}
              </OptionsButton>
              <OptionsButton
                variant="primary"
                plus={handleOpenCreateBrand}
                click={handleOpenCreateBrand}
                disabled={saveProductProcessPhase === PROCESSING}
              >
                Crear marca
              </OptionsButton>
              {/* Brand button error message*/}
              {!brand.name ? (
                <span className={classes.errorMessage}>
                  "Necesitas elegir una marca para guardar el producto"
                </span>
              ) : null}
            </div>
            {/* <AdministerCertificates productId={productId} /> */}
          </Col>
          <Col md={12}>
            <DataTitle className={classes.title}>Descripción:</DataTitle>
            <TextEditor
              // initialValue={description}
              // onKeyUp={(event) => {
              //   const value = event.target.innerHTML;

              //   if (value !== description) {
              //                  setHasChanged(true);
              //     setDescription(value);
              //   }
              // }}
              value={description}
              onEditorChange={(content) => {
                setDescription(content);
              }}
              onKeyUp={() => {
                setHasChanged(true);
              }}
              disabled={saveProductProcessPhase === PROCESSING}
              error={description.length <= 0 || description.length > 50000}
              errorMessage="La descripción es necesaria y debe tener menos de 50000 caracteres"
            />
          </Col>
          <Col md={12}>
            <DataTitle className={classes.title}>Descripción corta:</DataTitle>
            <TextEditor
              // initialValue={shortDescription}
              // onKeyUp={(event) => {
              //   const value = event.target.textContent;

              //   if (value !== shortDescription) {
              //   setHasChanged(true);
              //     setShortDescription(value);
              //   }
              // }}
              value={shortDescription}
              onEditorChange={(content) => {
                setShortDescription(content);
              }}
              onKeyUp={() => {
                setHasChanged(true);
              }}
              disabled={saveProductProcessPhase === PROCESSING}
              error={
                shortDescription.length <= 0 || shortDescription.length > 400
              }
              errorMessage={(() => {
                if (shortDescription.length <= 0) {
                  return "Necesita introducir una descripción para guardar el producto";
                } else if (shortDescription.length > 400) {
                  return "La descripción corta no puede tener más de 400 caracteres";
                }

                return "";
              })()}
            />
          </Col>
          <Col md={12}>
            <DataTitle className={classes.title}>
              Categoría principal:
            </DataTitle>
            <OptionsButton
              chevronExpand={() => {
                console.log("OPEN!!!!!");
                setShowChangeMainCategory(true);
                setShowAddCategories(false);
              }}
              click={() => {}}
              noX
              pencil={() => {
                setShowCategoryInfo(mainCategory.id);
              }}
              disabled={saveProductProcessPhase === PROCESSING}
            >
              {mainCategory.name}
            </OptionsButton>
            <OptionsButton
              plus={props.handleOpenCreateCategory}
              click={props.handleOpenCreateCategory}
            >
              Crear categoría
            </OptionsButton>
          </Col>
          <Col md={12}>
            <DataTitle className={classes.title}>Categorías:</DataTitle>
            {categoriesContent}
            <AddButton
              onClick={() => {
                setShowAddCategories(true);
                setHideFloatingButtons(true);
              }}
              disabled={saveProductProcessPhase === PROCESSING}
            ></AddButton>

            {/* CARACTERISTICAS */}
            {characteristicsContent}
          </Col>
        </TabMainRow>
      </TabContainer>
      {/* //TODO aqui es donde se muestra pero peta */}
      {showChangeSoloFeatureValue
        ? (() => {
            const featureId = showChangeSoloFeatureValue;

            let selectedItems = [];
            if (characteristics !== LOADING && characteristics.length > 0) {
              const feature = characteristics.find((ft) => {
                return ft.id === featureId;
              });

              if (feature.values.length >= 1) {
                selectedItems = feature.values.map((item) => item.id);
              }
            }

            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Cambiar valor de característica"
                addTableTitle="Categorías a añadir"
                copiesTableTitle="Categoría original"
                loadItems={() =>
                  apiFeatures
                    .filterValues(accesToken, {
                      filtername: "featureId",
                      value: featureId,
                    })
                    .then(({ data }) => {
                      const updatedData = data.map((item) => ({
                        id: item.id,
                        name: item.value,
                      }));

                      return { data: updatedData };
                    })
                }
                onSave={() => {}}
                selectedItems={selectedItems}
                close={() => {
                  setShowChangeSoloFeatureValue(undefined);
                  setHideFloatingButtons(false);
                }}
                applySelection={async (valueToAdd) => {
                  try {
                    await apiProducts.addFeatureValue(accesToken, {
                      productId,
                      valueId: valueToAdd.id,
                    });

                    let valueToUnlinkId;

                    const updatedCharacteristics = characteristics.map(
                      (feature) => {
                        if (feature.id === featureId) {
                          valueToUnlinkId = feature.values[0].id;

                          return {
                            ...feature,
                            values: [valueToAdd],
                          };
                        }

                        return feature;
                      }
                    );

                    await apiProducts.removeFeatureValue(accesToken, {
                      productId,
                      valueId: valueToUnlinkId,
                    });

                    setHideFloatingButtons(false);
                    setShowChangeSoloFeatureValue(false);
                    setCharacteristics(updatedCharacteristics);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                createItem={async (value) => {
                  const createdResponse = await apiFeatures.addValue(
                    accesToken,
                    {
                      featureId,
                      value,
                    }
                  );

                  (async () => {
                    try {
                      await apiFeatures.sync(accesToken, {
                        featureId,
                      });

                      await checkSync.checkFeatureSync(accesToken, {
                        featureId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync de la característica ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync de la característica ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return createdResponse;
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {showChangeLiterals
        ? (() => {
            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Cambiar valor de literals"
                addTableTitle="Literals a añadir"
                copiesTableTitle="Literals original"
                loadItems={async () => {
                  const response = await apiLiterals.getAll(accesToken);
                  return {
                    data: response.data.map((item) => ({
                      id: item.id,
                      name: `${item.singular} / ${item.plural}`,
                    })),
                  };
                }}
                onSave={() => {}}
                selectedItems={[]}
                close={() => {
                  setShowChangeLiterals(false);
                  setHideFloatingButtons(false);
                }}
                applySelection={async (literalsToAdd) => {
                  await apiLiterals
                    .filter(accesToken, {
                      filtername: "id",
                      id: literalsToAdd.id,
                    })
                    .then(({ data }) => {
                      setSingular(data[0].singular);
                      setPlural(data[0].plural);
                      setLiteralId(data[0].id);
                    });

                  setShowChangeLiterals(false);
                  setHideFloatingButtons(false);

                  setHasChanged(true);
                }}
                literalsMessage={literalMessage}
                literalTooltip={true}
                // *CREATE ANOTHER LITERAL INSIDE OF THE CHANGELITERAL MODAL
                createItem={async (name) => {
                  if (name.indexOf("//") === -1) {
                    setLiteralMessage(true);
                    displayNotification({
                      title: "Falta la DOBLE barra divisora",
                      message: `Para una correcta creación introduce los literals en el siguiente formato:     XXXXXXXXX //  YYYYYYY`,

                      type: "danger",
                    });
                    return;
                  } else {
                    // * SPLIT THE INPUT WITH //, REMOVE BLANK SPACES WITH TRIM() & AFIRST LETTER UPPERCASE WITH TOUPPERCASE
                    const dividedData = name.split("//");
                    const preSingular = dividedData[0].trim();
                    const newSingular =
                      preSingular[0].toUpperCase() + preSingular.slice(1);
                    const prePlural = dividedData[1].trim();
                    const newPlural =
                      prePlural[0].toUpperCase() + prePlural.slice(1);

                    const response = await apiLiterals.newLiteral(
                      accesToken,
                      newSingular,
                      newPlural
                    );

                    return {
                      data: {
                        id: response.data.id,
                        name: name,
                      },
                    };
                  }
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {showChangeBrand
        ? (() => {
            return (
              <AdministerCopies
                addButtonDescription="Añadir marca"
                buttonDescription="hide"
                title="Cambiar marca del producto"
                addTableTitle="Marcas a añadir"
                copiesTableTitle="Marca original"
                loadItems={() => apiBrands.getAll(accesToken)}
                onSave={() => {}}
                selectedItems={[]}
                close={() => {
                  setShowChangeBrand(false);
                  setHideFloatingButtons(false);
                }}
                applySelection={(brandToAdd) => {
                  setBrand({
                    id: brandToAdd.id,
                    name: brandToAdd.name,
                  });
                  setShowChangeBrand(false);
                  setHideFloatingButtons(false);
                  setHasChanged(true);
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {showAddFeatures
        ? (() => {
            const selectedItems = characteristics.map(
              (characteristic) => characteristic.id
            );

            return (
              <AdministerCopies
                addButtonDescription="Añadir nuevo valor de categoría"
                buttonDescription="hide"
                title="Añadir nuevas características"
                addTableTitle="Categorías a añadir"
                copiesTableTitle="Categoría original"
                loadItems={() =>
                  apiFeatures.listAll(accesToken).then(({ data }) => {
                    const items = data.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }));

                    return { data: items };
                  })
                }
                applySelection={() => {}}
                selectedItems={selectedItems}
                close={() => {
                  setShowAddFeatures(false);
                  setHideFloatingButtons(false);
                }}
                onSave={async (items) => {
                  const featureIds = items.map((item) => item.id);

                  await apiProducts.addFeatures(accesToken, {
                    productId: Number(productId),
                    featureIds,
                  });
                }}
                onSaved={async (items) => {
                  const updatedCharacteristics = characteristics.concat(
                    items.map((item) => ({
                      id: item.id,
                      name: item.name,
                      values: [],
                    }))
                  );

                  setCharacteristics(updatedCharacteristics);
                }}
                createItem={async (name) => {
                  const createdResponse = await apiFeatures.add(accesToken, {
                    name,
                  });

                  const featureId = createdResponse.data.id;

                  (async () => {
                    try {
                      await apiFeatures.sync(accesToken, {
                        featureId,
                      });

                      await checkSync.checkFeatureSync(accesToken, {
                        featureId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync de la característica ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync de la característica ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return createdResponse;
                }}
                multiSelect
              />
            );
          })()
        : undefined}
      {showEditFeature
        ? ((props) => {
            const featureId = showEditFeature;

            return (
              <Feature
                modal
                id={featureId}
                beforeClose={(name) => {
                  const updatedCharacteristics = characteristics.map(
                    (characteristic) => {
                      if (characteristic.id === Number(featureId)) {
                        return { ...characteristic, name };
                      }

                      return characteristic;
                    }
                  );
                  setCharacteristics(updatedCharacteristics);
                }}
                close={() => {
                  setShowEditFeature(false);
                  setHideFloatingButtons(false);
                }}
              />
            );
          })()
        : undefined}
      {featuresValuesMenu
        ? (() => {
            const featureId = featuresValuesMenu;

            let selectedItems = [];
            if (characteristics !== LOADING && characteristics.length > 0) {
              const feature = characteristics.find((ft) => {
                return ft.id === featureId;
              });

              if (feature.values.length >= 1) {
                selectedItems = feature.values.map((item) => item.id);
              }
            }

            return (
              <AdministerCopies
                multiSelect
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Añadir valor de característica"
                addTableTitle="Categorías a añadir"
                copiesTableTitle="Categoría original"
                loadItems={() =>
                  apiFeatures
                    .filterValues(accesToken, {
                      filtername: "featureId",
                      value: featureId,
                    })
                    .then(({ data }) => {
                      const updatedData = data.map((item) => ({
                        id: item.id,
                        name: item.value,
                      }));

                      return { data: updatedData };
                    })
                }
                applySelection={() => {}}
                selectedItems={selectedItems}
                close={() => {
                  setFeaturesValuesMenu(undefined);
                  setHideFloatingButtons(false);
                }}
                // createItem={async (value) => {
                //   const createdResponse = await apiFeatures.addValue(
                //     accesToken,
                //     {
                //       featureId,
                //       value,
                //     }
                //   );

                //   (async () => {
                //     try {
                //       await apiFeatures.sync(accesToken, {
                //         featureId,
                //       });

                //       await checkSync.checkFeatureSync(accesToken, {
                //         featureId,
                //       });

                //       displayNotification({
                //         title: "Se ha sincronizado correctamente",
                //         message: "La sync de la característica ha sido exitosa",
                //         type: "success",
                //       });
                //     } catch (error) {
                //       displayNotification({
                //         title: "Ha habido un error en la sync",
                //         message: "La sync de la característica ha fallado",
                //         type: "danger",
                //       });
                //     }
                //   })();

                //   return createdResponse;
                // }}
                onSave={async (valuesToAdd) => {
                  for (let index = 0; index < valuesToAdd.length; index++) {
                    const featureValueId = valuesToAdd[index].id;

                    await apiProducts.addFeatureValue(accesToken, {
                      productId,
                      valueId: featureValueId,
                    });

                    try {
                      await checkSync.checkFeatureValueSync(accesToken, {
                        featureValueId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync de la característica ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync de la característica ha fallado",
                        type: "danger",
                      });
                    }
                  }

                  const updatedCharacteristics = characteristics.map(
                    (feature) => {
                      if (feature.id === Number(featureId)) {
                        const valuesWhithoutNONE = feature.values.filter(
                          (value) => value.name !== "NONE"
                        );

                        return {
                          ...feature,
                          values: [...valuesWhithoutNONE, ...valuesToAdd],
                        };
                      }

                      return feature;
                    }
                  );

                  setFeaturesValuesMenu(false);
                  setHideFloatingButtons(false);
                  setCharacteristics(updatedCharacteristics);
                }}
                createItem={async (value) => {
                  const createdResponse = await apiFeatures.addValue(
                    accesToken,
                    {
                      featureId,
                      value,
                    }
                  );

                  return createdResponse;
                }}
                onSaved={async (items) => {}}
              />
            );
          })()
        : undefined}
      {/* Category modal here*/}
      {showAddCategories ? (
        <AdministerCopies
          addButtonDescription="Añadir nuevo valor de categoría"
          buttonDescription="hide"
          title="Añadir nuevas categorías"
          addTableTitle="Categorías a añadir"
          copiesTableTitle="Categoría original"
          // createItem={async (name) => {
          //   const response = apiCategories.add(accesToken, { name });

          //   const categoryId = response.data.id;

          //   (async () => {
          //     try {
          //       await apiCategories.sync(accesToken, {
          //         categoryId,
          //       });

          //       await checkSync.checkCategorySync(accesToken, {
          //         categoryId,
          //       });

          //       displayNotification({
          //         title: "Se ha sincronizado correctamente",
          //         message: "La sync de la categoría ha sido exitosa",
          //         type: "success",
          //       });
          //     } catch (error) {
          //       displayNotification({
          //         title: "Ha habido un error en la sync",
          //         message: "La sync de la categoría ha fallado",
          //         type: "danger",
          //       });
          //     }
          //   })();

          //   return response;
          // }}
          loadItems={() =>
            apiCategories.listAll(accesToken).then(({ data }) => {
              const items = data.map((item) => ({
                id: item.id,
                name: item.name,
              }));

              return { data: items };
            })
          }
          applySelection={() => {}}
          selectedItems={
            categories !== LOADING
              ? categories.map((category) => category.id)
              : []
          }
          close={() => {
            setShowAddCategories(false);
            setHideFloatingButtons(false);
          }}
          onSave={async (newCategoryes) => {
            const categoryIds = newCategoryes.map((item) => item.id);

            await apiProducts.addCategories(accesToken, {
              productId: Number(productId),
              categoryIds,
            });

            const updatedCategories = [];

            for (const category of newCategoryes) {
              const { data } = await apiCategories.listFilter(accesToken, {
                filtername: "id",
                value: category.id,
              });

              updatedCategories.push({
                id: category.id,
                name: category.name,
                active: data[0].active,
              });
            }

            setCategories([...categories, ...updatedCategories]);
            setHasChanged(true);
          }}
          onSaved={async () => {}}
          createItem={async (name) => {
            const responseCreateCategory = await apiCategories.add(accesToken, {
              name,
            });

            (async () => {
              try {
                await apiCategories.sync(accesToken, {
                  categoryId: id,
                });

                await checkSync.checkCategorySync(accesToken, {
                  categoryId: id,
                });

                displayNotification({
                  title: "Se ha sincronizado correctamente",
                  message: "La sync de la categoría ha sido exitosa",
                  type: "success",
                });
              } catch (error) {
                displayNotification({
                  title: "Ha habido un error en la sync",
                  message: "La sync de la categoría ha fallado",
                  type: "danger",
                });
              }
            })();

            return responseCreateCategory;
          }}
          multiSelect
        />
      ) : undefined}
      {showChangeMainCategory ? (
        <AdministerCopies
          addButtonDescription="Añadir nuevo valor de categoría"
          buttonDescription="hide"
          title="Cambiar categoría principal del producto"
          addTableTitle="Categoría a añadir"
          copiesTableTitle="Categoría original"
          loadItems={() =>
            apiCategories.listAll(accesToken).then(({ data }) => {
              const items = data.map((item) => ({
                id: item.id,
                name: item.name,
                active: item.active,
              }));

              return { data: items };
            })
          }
          // createItem={handleCreateCategory}
          applySelection={(category) => {
            handleChangeMainCategory(category);
            setHasChanged(true);
            setShowChangeMainCategory(false);

            apiProducts.edit(accesToken, {
              id,
              mainCategoryId: category.id,
            });
          }}
          selectedItems={[mainCategory.id]}
          close={() => {
            setShowChangeMainCategory(false);
            setShowAddCategories(false);
          }}
        />
      ) : undefined}
    </>
  );
};

export default Product;
