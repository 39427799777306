import apiCategories from "../api/categories";
import apiProducts from "../api/products";
import apiFeatures from "../api/features";
import apiAttributes from "../api/attributes";
import apiBrands from "../api/brands";
import apiArticles from "../api/articles";
import later from "../utilities/later";

/**
 * @author Xavier Carrera Gimbert <UpTeImpulsaDario@gmx.es>
 * @function checkCategorySync
 * @description Recursive function to chek if category is sync
 * @param {Object} accesToken, { categoryId, setIsSyncing }
 * @returns {Object} Sets the sync processing state to false or returns error
 */

const checkCategorySync = async (accesToken, { categoryId }) => {
  try {
    const checkSyncResponse = await apiCategories.checkSync(accesToken, {
      categoryId,
    });

    const syncStatus = checkSyncResponse.data.category[0];

    if (syncStatus.synchronizing === true) {
      await later(4000);
      return checkCategorySync(accesToken, { categoryId });
    } else if (syncStatus.sync_fail === true) {
      throw new Error("SYNC ERROR");
    } else if (syncStatus.sync === false) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    // TODO
    console.log("Estoy aki");
    throw new Error("SYNC ERROR");
  }
};

const checkProductSync = async (accesToken, { productId }) => {
  try {
    const checkSyncResponse = await apiProducts.checkSync(accesToken, {
      productId,
    });

    const syncStatus = checkSyncResponse.data.product[0];

    if (syncStatus.synchronizing === true) {
      await later(4000);
      return checkProductSync(accesToken, { productId });
    } else if (syncStatus.sync_fail === true) {
      throw new Error("SYNC ERROR");
    } else if (syncStatus.sync === false) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    throw new Error("SYNC ERROR");
  }
};

const checkFeatureSync = async (accesToken, { featureId }) => {
  const checkSyncResponse = await apiFeatures.checkSync(accesToken, {
    featureId,
  });

  const syncStatus = checkSyncResponse.data.feature[0];

  if (syncStatus.synchronizing === true) {
    await later(4000);
    return checkFeatureSync(accesToken, { featureId });
  } else if (syncStatus.sync_fail === true) {
    throw new Error("SYNC ERROR");
  } else if (syncStatus.sync === false) {
    return false;
  } else {
    return true;
  }
};

const checkAttributeSync = async (accesToken, { attributeId }, count = 0) => {
  const checkSyncResponse = await apiAttributes.checkSync(accesToken, {
    attributeId,
  });

  if (count > 2) {
    return false;
  }

  const syncStatus = checkSyncResponse.data.attribute[0];

  if (syncStatus.synchronizing === true) {
    await later(4000);
    return checkAttributeSync(accesToken, { attributeId }, count + 1);
  } else if (syncStatus.sync_fail === true) {
    throw new Error("SYNC ERROR");
  } else if (syncStatus.sync === false) {
    return false;
  } else {
    return true;
  }
};

const checkAttributeValueSync = async (accesToken, { attributeValueId }) => {
  const checkSyncResponse = await apiAttributes.valueCheckSync(accesToken, {
    attributeValueId,
  });

  const syncStatus = checkSyncResponse.data.attributeValue[0];

  if (syncStatus.synchronizing === true) {
    await later(4000);
    return checkAttributeValueSync(accesToken, {
      attributeValueId,
    });
  } else if (syncStatus.sync_fail === true) {
    throw new Error("SYNC ERROR");
  } else if (syncStatus.sync === false) {
    return false;
  } else {
    return true;
  }
};

const checkFeatureValueSync = async (accesToken, { featureValueId }) => {
  const checkSyncResponse = await apiFeatures.checkValueSync(accesToken, {
    featureValueId,
  });

  const syncStatus = checkSyncResponse.data.featureValue[0];

  if (syncStatus.synchronizing === true) {
    await later(4000);
    return checkFeatureValueSync(accesToken, { featureValueId });
  } else if (syncStatus.sync_fail === true) {
    throw new Error("SYNC ERROR");
  } else if (syncStatus.sync === false) {
    return false;
  } else {
    return true;
  }
};

const checkBrandSync = async (accesToken, { brandId }) => {
  try {
    const checkSyncResponse = await apiBrands.checkSync(accesToken, {
      brandId,
    });

    const syncStatus = checkSyncResponse.data.brand[0];

    if (syncStatus.synchronizing === true) {
      await later(4000);
      return checkBrandSync(accesToken, { brandId });
    } else if (syncStatus.sync_fail === true) {
      throw new Error("SYNC ERROR");
    } else if (syncStatus.sync === false) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    throw new Error("SYNC ERROR");
  }
};

const checkArticleSync = async (accesToken, { articleId }) => {
  const checkSyncResponse = await apiArticles.checkSync(accesToken, {
    articleId,
  });

  const syncStatus = checkSyncResponse.data.article[0];

  if (syncStatus.synchronizing === true) {
    await later(4000);
    return checkArticleSync(accesToken, { articleId });
  } else if (syncStatus.sync_fail === true) {
    throw new Error("SYNC ERROR");
  } else if (syncStatus.sync === false) {
    return false;
  } else {
    return true;
  }
};

export default {
  checkCategorySync,
  checkProductSync,
  checkFeatureSync,
  checkAttributeSync,
  checkAttributeValueSync,
  checkFeatureValueSync,
  checkBrandSync,
  checkArticleSync,
};
