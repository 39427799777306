import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// BOOTSTRAP
import { Button, Container, Table } from "react-bootstrap";
// STYLES
import classes from "./LiteralsList.module.css";
// API
import apiLiterals from "../../../api/literals";
// UTILITIES
import handleApiErrors from "../../../utilities/handleApiErrors";
import useInfiniteScroll from "../../../utilities/useInfiniteScroll";
// COMPONENTS
import TextInput from "../../../components/TextInput/TextInput";
import Spinner from "../../../components/Spinner/Spinner";
import PseudoTable from "../../../components/PseudoTable/PseudoTable";
import PaginationItem from "../../../components/PaginationItem/PaginationItem";
import stringSimilarity from "string-similarity";
import { ArrowUpSquareFill, PencilSquare } from "react-bootstrap-icons";
// CONTAINERS

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};
const resultsNumber = 20;

const LiteralsList = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // INPUT
  const [inputValue, setInputValue] = useState("");
  // ARRAYS
  const [allLiterals, setAllLiterals] = useState([]);
  const [allLiteralsUntouched, setAllLiteralsUntouched] = useState([]);
  const [literalsFiltered, setLiteralsFiltered] = useState([]);
  // PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  // INFINITE SCROLL
  const [results, setResults] = useState(20);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  // BRING DATA FROM API
  useEffect(() => {
    apiLiterals
      .getAll(accesToken)
      .then(({ data }) => {
        const items = data.map((item) => ({
          id: item.id,
          singular: item.singular,
          plural: item.plural,
        }));

        setAllLiterals(items);
        setAllLiteralsUntouched(items);
        setProcess(states.LOADED);
        // return { data: items };
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, []);
  // * FUNCTION FOR THE SCROLL INFINITE
  function fetchMoreListItems() {
    setTimeout(() => {
      if (results + 20 > allLiterals.length) {
        setResults(allLiterals.length);
      } else {
        setResults(results + 20);
      }
      setIsFetching(false);
    }, 500);
  }
  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };

  // * LOGIC FROM SIMILARITY ALGORITHMN
  useEffect(() => {
    const queryedItems = allLiterals.map((item) => {
      const scoredItem = {
        ...item,
        //Hago la similitud solamente con el campo singular
        score: stringSimilarity.compareTwoStrings(
          inputValue.toLocaleLowerCase(),
          item.singular.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });
    const arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (product.singular.toLocaleLowerCase().includes(inputValue)) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });
    // When there are 3 letters or more then it shows the flitered/ordered array, if not the general array
    inputValue.length > 2
      ? setAllLiterals([
          ...exactItems,
          ...similarItems.filter((item) => item.score > 0.2),
        ])
      : setAllLiterals(allLiteralsUntouched);
  }, [inputValue]);

  //  * LOGIC FOR RESPONSIVE, IF MATCH WITH THE MOBILE SCREEN THE RESULTS PER PAGE ADD 20 RESULTS AVERY TIME SCROLL TO THE END OF THE VIEWPORT
  let breakPointResponsive = window.matchMedia("(max-width: 65em)");
  let bodyContent;
  function responsiveBreakMatch(breakPointResponsive) {
    // If media query matches, the type of results change to the state
    bodyContent =
      splitArrays(
        allLiterals,
        breakPointResponsive.matches ? results : resultsNumber
      )[pageNumber] === undefined
        ? null
        : splitArrays(
            allLiterals,
            breakPointResponsive.matches ? results : resultsNumber
          )[pageNumber].map((item, index) => {
            //   console.log("🚀 ~ file: LiteralsList.js ~ line 82 ~ LiteralsList ~ item", item)

            return (
              <>
                {/* <ReactTooltip place="left" type="info" /> */}
                <PseudoTable.Row className={classes.row} key={item.id}>
                  <div>{item.id}</div>
                  <div>{item.singular}</div>
                  <div>
                    <p>{item.plural}</p>
                  </div>
                  <div>
                    {/* //! Se ha comentado el boton que manda a la lista de productos asociados al literal  */}
                    {/* <Button
                      variant="light"
                      onClick={() =>
                        item.plural === []
                          ? props.history.push(`/literalsList`)
                          : props.history.push(
                              `/literalsProductList/${item.id}`
                            )
                      }
                    >
                      Ver lista de productos
                    </Button> */}
                    <Button
                      variant="light"
                      className="ml-2"
                      onClick={() => props.history.push(`/literal/${item.id}`)}
                    >
                      <PencilSquare />
                    </Button>
                  </div>
                </PseudoTable.Row>
                {breakPointResponsive.matches && allLiterals.length > 20 ? (
                  results <= 20 ? null : (
                    <div className={classes.arrowContainer}>
                      <ArrowUpSquareFill
                        data-tip="Volver al inicio"
                        className={classes.arrowUp}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )
                ) : null}
              </>
            );
          });
  }
  responsiveBreakMatch(breakPointResponsive);

  return (
    <>
      {/* TITLE */}
      <Container className={classes.title}>
        <h1>Lista de literals</h1>
      </Container>

      <Container className={classes.input}>
        <TextInput
          placeholder="Introduce el nombre de la característica"
          onChange={(event) => {
            setInputValue(event.target.value);
            setPageNumber(0);
          }}
          value={inputValue}
        />
      </Container>

      {/* TABLE */}
      <Container>
        {process === "LOADING" ? (
          <Spinner
            className="mt-5"
            as="svg"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {/* // PAGINATION */}
            {allLiterals.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allLiterals}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF PAGINATION */}

            <PseudoTable.Table>
              <PseudoTable.Header className={classes.header}>
                <div>ID</div>
                <div>SINGULAR</div>
                <div>PLURAL</div>
                <div>OPCIONES</div>
              </PseudoTable.Header>
              {bodyContent}
            </PseudoTable.Table>
            {/* //* SPINNER IN MOBILE WHEN LOADING MORE ITEMS */}
            {isFetching ? (
              <div className={classes.hideSpinnerDesktop}>
                <Spinner
                  as="svg"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null}
            {/* //BOTTOM PAGINATION */}
            {allLiterals.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allLiterals}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF BOTTOM PAGINATION */}
          </>
        )}
      </Container>
    </>
  );
};

export default LiteralsList;
