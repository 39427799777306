import { useState } from "react";
import { useSelector } from "react-redux";

import apiCategories from "../../../../api/categories";
import checkSync from "../../../../utilities/checkSync";
import displayNotification from "../../../../utilities/displayNotification";

import reorder from "../../../../utilities/reorder";

interface Category {
  id: number;
  name: string;
  active: boolean;
  sync: boolean;
}

const useCategories = (categoryId: number) => {
  const [categories, setCategories] = useState<Category[]>([]);
  // TODO estado para el proceso de sync de prestashop
  const [syncProccess, setSyncProccess] = useState<boolean>(false);

  const accesToken = useSelector((state: any) => state.user.access);

  const processDbCategories = (unprocessedCategories: any[]) => {
    const processedCategories = unprocessedCategories.map((cat) => ({
      id: cat.id,
      name: cat.name,
      active: cat.active,
      sync: !cat.need_sync && !cat.sync_fail,
      url: "https://www.updirecto.es/" + cat.url_rewrite,
    }));

    setCategories(processedCategories);
  };

  const handleDropCategory = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedCategories = reorder(
      categories,
      result.source.index,
      result.destination.index
    );

    setCategories(reorderedCategories);

    try {
      await apiCategories.reorderSubcategories(accesToken, {
        categoryId,
        subcategoriesIds: reorderedCategories.map((cat) => cat.id),
      });
    } catch (error) {
      console.log(error);
    }
  };
  // TODO prueba de otro hook para ver si lo puedo meter en el boton de sincronizar en prestashop
  const handleCategoriesPrestashopSync = async () => {
    setSyncProccess(true);
    try {
      console.log(
        "🚀 ~ file: use-categories.ts ~ line 59 ~ handleCategoriesPrestashopSync ~ categories",
        categories
      );
      await apiCategories.categoriesPrestashopSync(accesToken, {
        categoryId,
        subcategoriesIds: categories.map((cat) => cat.id),
        position: true,
      });
      // Prueba con timeout para ver si hace lo del boton disabled
      setSyncProccess(false);
      displayNotification({
        title: "Se ha sincronizado correctamente",
        message: "La sync en prestashop ha sido exitosa",
        type: "success",
        dismiss: undefined,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateEditedCategory = (editedCategory: any) => {
    setCategories((prevCategories) => {
      return prevCategories.map((cat) => {
        if (cat.id === editedCategory.id) {
          return {
            ...cat,
            name: editedCategory.name,
            active: editedCategory.active,
          };
        }

        return { ...cat };
      });
    });
  };

  const startSyncingCategory = (id: number) => {
    setCategories((prevCategories) => {
      return prevCategories.map((cat) => {
        if (cat.id === id) {
          return { ...cat, syncing: true };
        }

        return cat;
      });
    });
  };

  const setCategorySynced = (id: number) => {
    setCategories((prevCategories) => {
      return prevCategories.map((cat) => {
        if (cat.id === id) {
          return { ...cat, syncing: false, sync: true };
        }

        return cat;
      });
    });
  };

  const setCategoryNotSynced = (id: number) => {
    setCategories((prevCategories) => {
      return prevCategories.map((cat) => {
        if (cat.id === id) {
          return { ...cat, syncing: false, sync: true };
        }

        return cat;
      });
    });
  };

  const handleClickSync = async (id: number) => {
    startSyncingCategory(id);

    try {
      await apiCategories.sync(accesToken, { categoryId: id });
      await checkSync.checkCategorySync(accesToken, {
        categoryId: id,
      });

      setCategorySynced(id);
    } catch (error) {
      setCategoryNotSynced(id);
    }
  };

  return {
    categories,
    setCategories,
    processDbCategories,
    handleDropCategory,
    handleUpdateEditedCategory,
    handleClickSyncCategory: handleClickSync,
    // TODO
    handleCategoriesPrestashopSync,
    syncProccess,
  };
};

export default useCategories;
