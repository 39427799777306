import { useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING, ERROR } from "../../../../../../utilities/processStates";

import apiProducts from "../../../../../../api/products";
import displayNotification from "../../../../../../utilities/displayNotification";

const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;

function getId(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useUploadVideos = (productId: number) => {
  const [videosList, setVideosList] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  const [removeVideoIndex, setRemoveVideoIndex] = useState<number>();

  const [savingVideoProcess, setSavingVideoProcess] = useState<string>();
  const [removingVideoProcess, setRemoveProcess] = useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  let isValid = true;
  let errorMessage = "";

  if (videoUrl.length === 0) {
    isValid = false;
    errorMessage =
      "Necesita especificar una url de Youtube para poder añadir un video";
  } else if (!youtubeRegex.test(videoUrl)) {
    isValid = false;
    errorMessage = "La url introducida no se corresponde a un video de Youtube";
  } else if (videosList.includes(videoUrl)) {
    isValid = false;
    errorMessage = "Este video ya existe no se puede volver a guardar";
  }

  const handleTypeUrl = (val: string) => {
    setVideoUrl(val);
  };

  const handleTouchInput = () => {
    setHasBeenTouched(true);
  };

  const getVideosFromProduct = async () => {
    try {
      const response = await apiProducts.listAllProductVideos(accesToken, {
        productId,
      });

      if (Array.isArray(response.data.videos)) {
        setVideosList(response.data.videos);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 408) {
        displayNotification({
          title: "Ha habido un error en prestashop",
          message:
            "No se han podido extraer los videos de la base de datos por culpa de un error de prestashop",
          type: "error",
          dismiss: true,
        });
      }
    }
  };

  const handleSubmitForm = async () => {
    setHasBeenSubmitted(true);

    if (isValid) {
      setSavingVideoProcess(PROCESSING);

      try {
        await apiProducts.registerProductVideos(accesToken, {
          productId,
          videos: [...videosList, videoUrl],
        });

        setVideoUrl("");
        setHasBeenSubmitted(false);
        setHasBeenTouched(false);
        setVideosList((prevVideos) => {
          const updatedVideos = [...prevVideos, videoUrl];

          return updatedVideos;
        });
      } catch (error: any) {
        if (error.response && error.response.status === 408) {
          displayNotification({
            title: "Ha habido un error en prestashop",
            message:
              "No se ha podido subir el video de la base de datos por culpa de un error de prestashop",
            type: "error",
            dismiss: true,
          });
        }
      }

      setSavingVideoProcess(undefined);
    }
  };

  const handleRemoveVideo = async (videoIndex: number) => {
    setRemoveProcess(PROCESSING);
    setRemoveVideoIndex(videoIndex);

    try {
      const updatedVideosList = videosList.filter(
        (_, index) => index !== videoIndex
      );

      await apiProducts.registerProductVideos(accesToken, {
        productId,
        videos: updatedVideosList,
      });

      setVideosList(updatedVideosList);
    } catch (error: any) {
      if (error.response && error.response.status === 408) {
        displayNotification({
          title: "Ha habido un error en prestashop",
          message:
            "No se ha podido eliminar el video de la base de datos por culpa de un error de prestashop",
          type: "error",
          dismiss: true,
        });
      }
    }

    setRemoveVideoIndex(undefined);
    setRemoveProcess(undefined);
  };

  const videoEmbeddedUrls = videosList.map((video) => {
    return "//www.youtube.com/embed/" + getId(video);
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedVideosList = reorder(
      videosList,
      result.source.index,
      result.destination.index
    );

    setVideosList(updatedVideosList);

    // TODO: Add a processins state when reordering video
    (async () => {
      try {
        await apiProducts.registerProductVideos(accesToken, {
          productId,
          videos: updatedVideosList,
        });
      } catch (error: any) {
        if (error.response && error.response.status === 408) {
          displayNotification({
            title: "Ha habido un error en prestashop",
            message:
              "No se ha podido reordenar el video en la base de datos por culpa de un error de prestashop",
            type: "error",
            dismiss: true,
          });
        }
      }
    })();
  };

  const isProcessingUploadVideo = savingVideoProcess === PROCESSING;
  const isRemovingVideo = removingVideoProcess === PROCESSING;

  return {
    videoUrl,
    handleTypeUrl,
    isValid,
    errorMessage,
    hasBeenSubmitted,
    hasBeenTouched,
    handleTouchInput,
    videosList,
    getVideosFromProduct,
    videoEmbeddedUrls,
    handleSubmitForm,
    handleRemoveVideo,
    removeVideoIndex,
    isProcessingUploadVideo,
    isRemovingVideo,
    onDragEnd,
  };
};

export default useUploadVideos;
