import { Link } from "react-router-dom";

import { Alert, Accordion, Card, ListGroup } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";

import {
  GroupAttribute,
  GroupArticle,
  Group,
} from "../../Edit/Product/Product/types/Group";

import useAttributesConflictAccordeon from "./hooks/use-attributesConflictAccordeon";

import classes from "./AttributesConflictAccordeon.module.scss";

const AttributesConflictAccordeon: React.FC<{
  attributesConflictValues: Group[];
}> = ({ attributesConflictValues }) => {
  const { activeKey, handleClickTab } = useAttributesConflictAccordeon();

  return (
    <div className={classes.conflictsContainer}>
      <Alert variant="danger">
        No se puede borrar el atributo ya que después de borrarlo generará
        artículos con las mimas combinaciones de attributos y valores, podrá ver
        los grupos de artículos idénticos a continuación
      </Alert>
      <Accordion defaultActiveKey="0" activeKey={activeKey}>
        {attributesConflictValues.map((group, index) => {
          const key = String(index);

          const accordeonHeaderClasses = [classes.accordeonHeader];

          if (activeKey === key) {
            accordeonHeaderClasses.push(classes.open);
          }

          return (
            <Card key={index}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={key}
                onClick={() => handleClickTab(key)}
                className={classes.accordeonToggle}
              >
                <div className={accordeonHeaderClasses.join(" ")}>
                  <span>Grupo {index}</span>
                  <ChevronLeft />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={key}>
                <Card.Body>
                  <div className={classes.pseudoTable}>
                    <div>
                      <span className={classes.listTitle}>Attributos:</span>
                      <ListGroup>
                        {group.attributes.map((attr: GroupAttribute) => (
                          <ListGroup.Item key={attr.attribute__id}>
                            {attr.attribute__name} - {attr.value}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                    <div>
                      <span className={classes.listTitle}>Articulos:</span>
                      <ListGroup>
                        {group.articles.map((article: GroupArticle) => (
                          <ListGroup.Item key={article.id}>
                            <Link
                              key={article.id}
                              to={`/article/${article.id}`}
                              target="_blank"
                            >
                              {article.description}: {article.ref},
                            </Link>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </div>
  );
};

export default AttributesConflictAccordeon;
