import { useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../utilities/processStates";

import apiArticles from "../../../../api/articles";

import later from "../../../../utilities/later";

interface Orphan {
  id: number;
  ref: string;
  name: string;
  active: boolean;
}

const useOrphans = () => {
  const [allOrphans, setAllOrphans] = useState<Orphan[]>([]);
  const [allOrphansUntouched, setAllOrphansUntouched] = useState<Orphan[]>([]);

  // const accesToken = useSelector((state: any) => state.user.access);

  return {
    allOrphans,
    setAllOrphans,
    allOrphansUntouched,
    setAllOrphansUntouched,
  };
};

export default useOrphans;
