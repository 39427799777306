import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useDropzone } from "react-dropzone";

import { PROCESSING } from "../../../../../utilities/processStates";

import apiCategories from "../../../../../api/categories";

function checkIfImageExists(url: string, callback: any) {
  const img = new Image();

  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    img.onerror = () => {
      callback(false);
    };
  }
}

const usePhotos = ({
  categoryId,
  idPrestashop,
}: {
  categoryId: number;
  idPrestashop: number;
}) => {
  const [categoryImageUrl, setCategoryImageUrl] = useState<string>();
  const [processUploadPhoto, setProcessUploadPhoto] = useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    const posibleCategoryImageUrl = `https://www.updirecto.es/c/${idPrestashop}-small_default/ffffffffffffff.jpg`;

    checkIfImageExists(posibleCategoryImageUrl, (exists: boolean) => {
      if (exists) {
        setCategoryImageUrl(posibleCategoryImageUrl);
      } else {
        setCategoryImageUrl(undefined);
      }
    });
  }, []);

  const handleUploadImages = async (images: any) => {
    try {
      setProcessUploadPhoto(PROCESSING);

      const imageToUpload = images[0];

      const data = new FormData();
      data.append("image", imageToUpload);
      data.append("categoryId", String(categoryId));

      await apiCategories.addPhoto(accesToken, data);
    } catch (error) {
      console.log(error);
    }

    const now = new Date();

    const posibleCategoryImageUrl = `https://www.updirecto.es/c/${idPrestashop}-small_default/${now.toString()}.jpg`;

    checkIfImageExists(posibleCategoryImageUrl, (exists: boolean) => {
      if (exists) {
        setCategoryImageUrl(posibleCategoryImageUrl);
      } else {
        setCategoryImageUrl(undefined);
      }
    });

    setProcessUploadPhoto(undefined);
  };

  const imageDropZone = useDropzone({ onDrop: handleUploadImages });

  const doesImageExist = categoryImageUrl !== undefined;
  const isUploadingImage = processUploadPhoto === PROCESSING;

  return {
    categoryImageUrl,
    doesImageExist,
    imageDropZone,
    isUploadingImage,
  };
};

export default usePhotos;
