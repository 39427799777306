import React from "react";

import { Button, Spinner } from "react-bootstrap";
import {
  CheckSquareFill,
  XSquareFill,
  PencilSquare,
  Globe2,
  X,
} from "react-bootstrap-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import apiCategories from "../../../../api/categories";
import Spinner2 from "../../../../components/Spinner/Spinner";

import CheckSphere from "../../../../components/CheckSphere/CheckSphere";

import classes from "./CategoriesList.module.scss";

const CategoriesList: React.FC<{
  categories: any[];
  handleDropCategory: (result: any) => void;
  handleClickEditCategory: (id: number) => void;
  handleClickSyncCategory: (id: number) => void;
  // TODO
  handleCategoriesPrestashopSync;
  syncProccess: boolean;
}> = ({
  categories,
  handleDropCategory,
  handleClickEditCategory,
  handleClickSyncCategory,
  // TODO
  handleCategoriesPrestashopSync,
  syncProccess,
}) => {
  // logica para la sync de las categorias en prestashop
  const categoriesPrestashopSync = () => {
    // console.log(
    //   "🚀 ~ file: CategoriesList.tsx ~ line 138 ~ categories",
    //   categories
    // );

    return;
  };
  return (
    <>
      {/* Boton para ordenar y sincronizar las categorias en prestashop y que se vea en la web */}
      <div className={classes.categoriesHeader}>
        <h3>Categorías:</h3>

        {syncProccess ? (
          <Spinner2 />
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              // categoriesPrestashopSync();
              handleCategoriesPrestashopSync();
            }}
            disabled={syncProccess}
          >
            Sincronizar el menú
          </Button>
        )}
      </div>
      <ul className={classes.table}>
        <li className={[classes.row, classes.header].join(" ")}>
          {/* <div></div> */}
          <div>ID</div>
          <div>Nombre</div>
          <div>Activo</div>
          <div>Sincronizado</div>
          <div>Opciones</div>
        </li>
        <DragDropContext onDragEnd={handleDropCategory}>
          <Droppable droppableId="categoriesTable">
            {(provided: any, snapshot: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {categories.map((cat, index) => {
                  let syncButtonContent: any;

                  if (cat.syncing) {
                    syncButtonContent = (
                      <Spinner animation="border" size="sm" />
                    );
                  } else if (cat.sync) {
                    syncButtonContent = (
                      <CheckSquareFill className={classes.success} />
                    );
                  } else {
                    syncButtonContent = (
                      <XSquareFill className={classes.error} />
                    );
                  }

                  return (
                    <Draggable
                      key={cat.id}
                      draggableId={String(cat.id)}
                      index={index}
                    >
                      {(provided: any, snapshot: any) => (
                        <li
                          className={classes.row}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {/* <div>
                            <Circle />
                          </div> */}
                          <div>{cat.id}</div>
                          <div>{cat.name}</div>
                          <div>
                            <CheckSphere check={cat.active} x={!cat.active} />
                          </div>
                          <div>
                            <button
                              className={classes.syncStatus}
                              onClick={() => handleClickSyncCategory(cat.id)}
                              disabled={cat.syncing}
                            >
                              {syncButtonContent}
                            </button>
                          </div>
                          <div>
                            <Button
                              variant="light"
                              onClick={() => handleClickEditCategory(cat.id)}
                            >
                              <PencilSquare />
                            </Button>
                            <Button
                              variant="light"
                              onClick={() => {
                                window.open(`${cat.url}`, "_blank");
                              }}
                            >
                              <Globe2 />
                            </Button>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
    </>
  );
};

export default CategoriesList;
