import { Button } from "react-bootstrap";

import { X } from "react-bootstrap-icons";

import Article from "../types/Article";

import classes from "./SelectedArticlesList.module.scss";

const SelectedArticlesList: React.FC<{
  articles: Article[];
  handleClickArticle: (clickedArticle: Article) => void;
  disabled: boolean;
}> = ({ articles, handleClickArticle, disabled }) => {
  return (
    <div className={classes.articlesContainer}>
      {articles.map((art) => (
        <Button
          key={art.id}
          variant="success"
          onClick={() => handleClickArticle(art)}
          disabled={disabled}
        >
          <span>{art.ref}</span>
          <X />
        </Button>
      ))}
    </div>
  );
};

export default SelectedArticlesList;
