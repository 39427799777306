import { Button, Spinner } from "react-bootstrap";

import classes from "./SaveItemsButton.module.scss";

const SaveItemsButton: React.FC<{
  handleClick: () => void;
  processing: boolean;
}> = ({ handleClick, processing }) => {
  let buttonContent = <>Guardar</>;

  if (processing) {
    buttonContent = (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  return (
    <div className={classes.buttonContainer}>
      <Button onClick={handleClick} disabled={processing}>
        {buttonContent}
      </Button>
    </div>
  );
};

export default SaveItemsButton;
