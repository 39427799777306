import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Modal,
  Alert,
  Form,
  Button,
} from "react-bootstrap";
import Spinner from "../../../components/Spinner/Spinner";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextInput from "../../../components/TextInput/TextInput";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import SaveButton from "../../../components/SaveButton/SaveButton";
import ButtonSpinner from "../../../components/ButtonSpinner/ButtonSpinner";

import displayNotification from "../../../utilities/displayNotification";

import { ChevronLeft } from "react-bootstrap-icons";

import AttributeValueList from "../../Lists/AttributesValueList/AttributesValueList";
import AttributeValue from "../AttributeValue/AttributeValue";

import classes from "./EditAttribute.module.css";

const LOADING = "LOADING";
const ERROR = "ERROR";
const PROCESSING = "PROCESSING";

const EditAttribute = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const [psId, setPsId] = useState();
  const [name, setName] = useState(LOADING);
  const [description, setDescription] = useState(LOADING);
  const [type, setType] = useState(LOADING);

  const [mustSync, setMustSync] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [correctSync, setCorrectSync] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const [selectedValueId, setSelectedValueId] = useState();

  const [errors, setErrors] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const isInfo = type === "info";
  const showValue = selectedValueId !== undefined;

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (name && hasChanged) {
        onClickSave();
      }
    }, 5000);

    return () => clearTimeout(saveTimer);
  }, [hasChanged, name, description, type]);

  useEffect(() => {
    (async () => {
      try {
        const data = await props.onLoad();
        setName(data.name);
        setDescription(data.description);
        setType(data.type);
        setPsId(data.prestashopId);

        if (data.type !== "info") {
          const firstCheckSync = await props.loadSyncCheck();

          if (firstCheckSync.synchronizing) {
            setIsSyncing(true);
          }

          const checkResponse = await props.onCheckSync();

          if (checkResponse) {
            setMustSync(false);
            setCorrectSync(true);
            setSyncError(false);
          } else {
            setMustSync(true);
            setSyncError(true);
          }

          setIsSyncing(false);
        }
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();
  }, [accesToken]);

  let content = <Spinner />;

  let buttonContent = "Guardar y sincronizar";
  let buttonVariant = "success";

  if (isSaving || isSyncing) {
    buttonContent = <ButtonSpinner />;
    buttonVariant = "warning";
  } else if (syncError && !isInfo) {
    buttonContent = "Ha ocurrido un error, intentelo de nuevo";
    buttonVariant = "danger";
  } else if (isInfo) {
    buttonContent = "Guardar";
  }

  if (hasChanged) {
    buttonVariant = "danger";
  }

  const SaveAndSyncButton = () => (
    <Button
      variant={buttonVariant}
      onClick={onClickSync}
      disabled={isSaving || isSyncing}
    >
      {buttonContent}
    </Button>
  );

  const nameErrorMessage =
    name.length <= 0
      ? "Debe especificar un nombre para poder cambiar el valor"
      : "No puede haber 2 atributos con el mismo tipo y nombre";

  if (name !== LOADING || description !== LOADING || type !== LOADING) {
    if (showValue) {
      content = (
        <>
          <Button
            className={classes.goBackButton}
            onClick={() => setSelectedValueId(undefined)}
            variant="link"
          >
            <ChevronLeft /> <span>Volver al atributo</span>
          </Button>
          <AttributeValue id={selectedValueId} />
        </>
      );
    } else {
      content = (
        <>
          <Container fluid>
            <Row>
              <Col md={12} className={classes.headerCol}>
                {!props.modal ? (
                  <h2 className="mt-4 mb-3">Atributo: {name}</h2>
                ) : undefined}
                <Alert variant="warning">
                  Cambiar este valor afectará todos los items vinculados
                </Alert>
                <span className={classes.psId}>
                  <b>PS ID:</b> {psId}
                </span>
                <DataTitle>Nombre:</DataTitle>
                <TextInput
                  defaultValue={name}
                  onKeyUp={(event) => {
                    setName(event.target.value);
                    setDuplicateNameError(false);
                    setHasChanged(true);
                  }}
                  error={name.length <= 0 || duplicateNameError}
                  errorMessage={nameErrorMessage}
                />
              </Col>
              <Col md={6}>
                <CheckSphere
                  x={!description}
                  check={description}
                  onClick={() => {
                    setDescription(!description);
                    setHasChanged(true);
                  }}
                >
                  Descripción
                </CheckSphere>
              </Col>
              {type !== "info" ? (
                <Col md={6}>
                  <Form.Control
                    as="select"
                    custom
                    value={type}
                    onChange={(event) => {
                      setType(event.target.value);
                      setHasChanged(true);
                    }}
                  >
                    <option value="selection">Selection</option>
                    <option value="radio">Radio</option>
                    <option value="color">Color</option>
                  </Form.Control>
                </Col>
              ) : undefined}
              <Col md={12}></Col>
            </Row>
          </Container>
          <div className="d-flex justify-content-end mt-3">
            <SaveAndSyncButton />
          </div>
          <div className="mt-4">
            <AttributeValueList
              attributeId={props.id}
              handleClickValue={(id) => setSelectedValueId(id)}
            />
          </div>
        </>
      );
    }
  }

  const onClickSync = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await onClickSave();

      if (!hasSavedSuccesFully) {
        setIsSyncing(false);
        return;
      }

      let hasSyncedCorrectlly = true;

      if (!isInfo) {
        await props.onSync();

        hasSyncedCorrectlly = await props.onCheckSync();
      }

      if (hasSyncedCorrectlly || isInfo) {
        setSyncError(false);
      } else {
        setSyncError(true);
      }

      setIsSyncing(false);

      // setMustSync(false);
    } catch (e) {
      setSyncError(true);
      setIsSyncing(false);
      console.log(e);
    }
  };

  const onClickSave = async () => {
    setIsSaving(true);

    try {
      if (name.length <= 0) {
        throw "ERROR";
      }

      await props.onSave({ name, description, type });

      setHasChanged(false);
      setMustSync(true);
      setIsSaving(false);
      setHasSaved(true);
      return true;
    } catch (e) {
      if (e.response.status === 409) {
        displayNotification({
          title: "Nombre del atributo duplicado",
          message:
            "El nombre del atributo especificado ya existe, cambielo si desea guardarlo",
          type: "danger",
        });

        setDuplicateNameError(true);
      }
      setIsSaving(false);
      setShowErrorMessage(true);
      setErrors(true);
    }
  };

  if (props.modal) {
    return (
      <>
        <Modal
          className={classes.modifyModal}
          show={true}
          size="lg"
          onHide={() => {
            if (hasSaved) props.beforeClose({ name });
            props.close();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar atributo: {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{content}</Modal.Body>
          <Modal.Footer className={classes.modifyBottomModal}>
            <SaveAndSyncButton />
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return <>{content}</>;
};

export default EditAttribute;
